import React from 'react';
import DefaultLayout from "layouts/DefaultLayout";

export default function Page404() {

    return (
        <DefaultLayout updateLastVisit={false} title="Erreur">
            <h1>Erreur</h1>
            <p>Cette page n'existe pas.</p>
        </DefaultLayout>
    );
}
