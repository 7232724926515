import React, { useEffect, useState } from 'react';
import BarLoader from "react-spinners/BarLoader";
import { toast } from 'react-toastify';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import { useForm } from 'shared/hooks/Form';
import SecurityApi from 'shared/api/Security';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import { compileErrorsFromResponse } from 'shared/services/Utils';

export default function UserValidation() {
    
    const [errorMessage, setErrorMessage] = useState();
    const [invitationError, setInvitationError] = useState("");
    const [needPassword, setNeedPassword] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [data, errors, setErrors, setValue] = useForm({
        password: "",
        confirmation: "",
        acceptcgu: false
    });
    const hash = /users\/validation\/([a-z0-9]*)/g.exec(document.location.pathname)[1];

    function load() {
        SecurityApi
            .getInvitationDetail(hash)
            .then(res => {
                if (res.data.expired) {
                    setInvitationError("Invitation expiré.");
                } else if (res.data.validated) {
                    setInvitationError("Invitation déjà validé.");
                } else {
                    setNeedPassword(res.data.need_password);
                }                
                setLoading(false);
            })
            .catch(() => {
                setInvitationError("Invitation non valide.");
                setLoading(false)
            });
    }

    function submit(event) { 
        event.preventDefault();

        setSaving(true);
        
        SecurityApi
            .validation({
                hash: hash,
                password: data.password,
                confirmation: data.confirmation,
                acceptcgu: data.acceptcgu ? 1 : 0
            })
            .then(response => {
                setSaving(false);
                setErrors([]);
                if (response.data.validated) {
                    toast.success("Accès validé. Vous allez être redirigé.");
                    setTimeout(() => {
                        document.location.href = "/login";
                    }, 2000);
                } else {
                    toast.error("Impossible de valider l'accès.");
                }
            })
            .catch(error => {
                setSaving(false);
                if (error.response.data.message) {
                    setErrorMessage("Impossible de valider l'accès : " + error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            });
    }

    useEffect(load, [hash]);

    return (
        <section className="login">
            <form className="form" onSubmit={submit}>
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />&nbsp;&nbsp;Validation de votre accès
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Validation de votre accès</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            {loading && <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />}
                            {!loading && <>
                                {errorMessage && <div className="alert alert-error" role="alert">{errorMessage}</div>}
                                {invitationError !== "" && <>
                                    <div className="alert alert-error" role="alert">{invitationError}</div>
                                </>}
                                {invitationError === "" && <>
                                    {needPassword && <>
                                        <FieldComponent type="password" name="password" label="Mot de passe *" error={errors.password} value={data.password} onChange={value => setValue("password", value)} />
                                        <FieldComponent type="password" name="confirmation" label="Confirmation *" error={errors.confirmation} value={data.confirmation} onChange={value => setValue("confirmation", value)} />
                                    </>}
                                    <FieldComponent
                                        type="checksingle"
                                        name="acceptcgu"
                                        checksingleLabel="oui"
                                        checked={data.acceptcgu}
                                        onChange={value => setValue("acceptcgu", value)}    
                                        error={errors.acceptcgu}
                                        wrapper={([, input, error]) => <div className="text-right">
                                            {input}
                                            <label for="acceptcgu">J'accepte les <a href="/cgvu.pdf" target="_blank">conditions générales d'utilisations</a> *</label>
                                            {error}
                                        </div>}        
                                    />
                                    <div className="row">
                                        <div className="col-md-12 text-right order-2">
                                            <LoadButton 
                                                loading={saving} 
                                                label="Valider"
                                                name="validate"
                                                id="validate"
                                            />
                                        </div>
                                    </div>
                                </>}
                            </>}
                        </div>
                    </section>
                </div>
            </form>
        </section>
    )
}