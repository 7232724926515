import { React, Link, useEffect, useState, BarLoader, Modal } from 'commons';
import DocumentarySpaceApi from 'shared/api/DocumentarySpace';
import { nl2br, crop, formatDate } from 'shared/services';
import DocumentarySpaceDetailNews from 'views/documentaryspace/Detail/News';
import { mediumModalCustomStyles } from 'shared/services/ModalStyle';
import { prefixLinkTo } from 'shared/services';

export default function DocumentarySpace() {

	const [news, setNews] = useState([]);
	const [detail, setDetail] = useState(null);
	const [openingDetail, setOpeningDetail] = useState(false);
	const [loading, setLoading] = useState(true);
	const [reducedDisplay, toggle] = useState(true);

	function load() {
		DocumentarySpaceApi
			.search(
				{},
                0,
                10,
                "date",
                "DESC",
				{type: "news"}
			)
			.then(([results]) => setNews(results))
			.finally(() => setLoading(false));
	}

	function openDetail(event, item) {
		event.preventDefault();
		setDetail(item);
		setOpeningDetail(true);
	}

	useEffect(load, []);

    return (
        <div className="contenu padding documentaryspace">
			<h2><i className="icon-documentaire-actualites-documentaires"></i>Dernières actualités</h2>
			<BarLoader loading={loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
			{!loading && <ul className="item-list latest striped">
                {news.map((item, index) => {
                    if (index <= 1 || reducedDisplay === false) {
                        return <li key={item.id}>
							<div className="contenu">
								{item.image && <div className="image">
									<img width="80" src={`data:image/jpeg;base64,${item.image.image}`} alt="" />
								</div>}
								<div className="infos">
									<Link onClick={(e) => openDetail(e,item)} to={prefixLinkTo() + `/documentary-space/news/${item.id}`} title="Voir l'actualité">
										<strong>{formatDate(item.date)} : {item.title}</strong>
										{item.description && <p>{nl2br(crop(item.description, 200))}</p>}
									</Link>
								</div>
							</div>
                            <div className="detail">
                                <Link onClick={(e) => openDetail(e, item)} to={prefixLinkTo() + `/documentary-space/news/${item.id}`} title="Voir l'actualité"><i className="icon-boutons-lien-vers" aria-hidden="true"></i></Link>
                            </div>
                        </li>;
                    } else {
                        return <React.Fragment key={item.id}></React.Fragment>;
                    }                    
                })}
			</ul>}
			<div className="row">
				<div className="offset-md-3 col-md-6 text-center">
					<Link to={prefixLinkTo() + "/documentary-space/news"} className="btn btn-primary icon h35"><i className="icon-boutons-lien-vers" aria-hidden="true"></i>  Voir toutes les actualités</Link>
				</div>
				{!loading && news.length > 2 && <div className="col-md-3 text-right">
                    {reducedDisplay && <button onClick={() => toggle(false)} className="toggler actif after" type="button">Voir plus</button>}
                    {!reducedDisplay && <button onClick={() => toggle(true)} className="toggler after" type="button">Voir moins</button>}
				</div>}
			</div>
			<Modal isOpen={openingDetail} onRequestClose={() => setOpeningDetail(false)} style={mediumModalCustomStyles}>
				<DocumentarySpaceDetailNews onRequestClose={() => setOpeningDetail(false)} document={detail} />
			</Modal>
		</div>
    );
}
