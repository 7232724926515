import React from 'react';
import { ToastContainer } from 'react-toastify';
import Dispatcher from "./Dispatcher";
import { UserProvider } from 'shared/stores/User';
import { ReferentialProvider } from 'shared/stores/Referential';

import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-datepicker/dist/react-datepicker.css";
import "./shared/styles/index.scss";

export default function App() {

    return (<>
        <UserProvider>
            <ReferentialProvider>
                <Dispatcher />
            </ReferentialProvider>
        </UserProvider>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
        />
        <div style={{display:"none"}}>1677761026</div>
    </>);
}