import React, { useEffect, useState, useContext, } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Prompt } from "react-router-dom";
import DefaultLayout from "layouts/DefaultLayout";
import { useList } from 'shared/hooks/List';
import { usePosition } from 'shared/hooks/Position';
import { useAccount } from 'hooks/Account';
import { useToggler } from "shared/hooks/Toggler";
import { useQuery } from 'shared/hooks/Query';
import { ReferentialContext } from 'shared/stores/Referential';
import { convertCriteriesToSearchQuery } from 'shared/services/ApiRequest';
import RequirementAffectedApi from 'api/RequirementAffected';
import ExternalUserApi from 'shared/api/ExternalUser';
import LoadButton from 'shared/components/LoadButton';
import ListComponent from 'shared/components/ListComponent';
import FilterComponent from 'shared/components/FilterComponent';
import RequirementItemList from 'views/requirementaffected/List/ItemList';
import RequirementAffectedListBatch from 'views/requirementaffected/List/ListBatch';
import { getConformities, getConformityModes } from 'data/SheetAffected';
import { getApplicabilities, APPLICABILITY_INCOMING, APPLICABILITY_CURRENT } from 'data/ApplicabilityState';
import { getPriorities } from 'data/Priority';
import { REFERENTIALS } from 'shared/data/ReferentialType';
import { compileDataToSelectOptions, toggleArrayValue } from 'shared/services/Utils';
import confirm from 'shared/services/Confirmation';
import AccountUtils from 'shared/services/AccountUtils';
import { canPerform, denyIfCantPerform } from "shared/services/Can";
import { prefixLinkTo } from "shared/services";
import { getDegree } from 'shared/data/Degree';
import RequirementApi from 'shared/api/Requirement';
import AccessApi from 'shared/api/Access';
import AccountApi from 'shared/api/Account';
import TreeUtils from 'shared/services/TreeUtils';
import ImportModal from './List/ImportModal';
import FileUtils from 'shared/services/FileUtils';

export default function RequirementList(props) {

    const [initializePosition, setPosition] = usePosition("requirement-affected-list");
    const [selectedRequirements, setSelectedRequirements] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedAffectations, setSelectedAffectations] = useState([]);
    const [, toggleRequirement,,,,, isToggled] = useToggler({});
    const [affectationChanges, setAffectationChanges] = useState({});
    const [errors, setErrors] = useState([]);
    const [account] = useAccount();
    const [saving, setSaving] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [displayFilter, setDisplayFilter] = useState(true);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [tree, setTree] = useState([]);
    const [accountTree, setAccountTree] = useState({'nodes': [], 'watchsites': []});
    const [pilots, setPilots] = useState([]);
    const [sectionTree, setSectionTree] = useState(null);
    const [referentialContext] = useContext(ReferentialContext);
    const [pilotsForWatchsites, setPilotsForWatchsites] = useState({});

    denyIfCantPerform(props, "requirementaffected:list", {account: account});

    // referential
    const textTypes = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_TYPE], 'id', 'value');
    const textImpacts = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_IMPACT], 'id', 'value');
    const sources = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_SOURCE], 'id', 'value');
    const transmitters = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TRANSMITTER], 'id', 'value');
    const involvedActors = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR], 'id', 'value');
    const involvedFunctions = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION], 'id', 'value');
    const descriptors = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_DESCRIPTORS], 'id', 'value');
    const domainTree = referentialContext["tree"];
    const categoryTree = referentialContext[REFERENTIALS.REFERENTIAL_CATEGORY];

    // account access
    const hasTechnicalLevelAccess = canPerform("account:technical_level", {account: account});
    const hasComplianceManagementAccess = canPerform("account:compliance_management", {account: account});
    const hasCustomerCommentAccess = canPerform("account:customer_comment", {account: account});
    const hasConsultantCommentAccess = canPerform("account:consultant_level", {account: account});
    const hasHeadquarterCommentAccess = canPerform("account:headquarter_comment", {account: account});
    const hasActionAccess = canPerform("account:actionplan", {account: account});
    const hasCategoriesAccess = canPerform("account:categories", {account: account});

    const query = useQuery();
    const initialCriterias = {
        hidden: "0",
        requirement_applicability_state: [
            APPLICABILITY_INCOMING,
            APPLICABILITY_CURRENT
        ],
        keyword_fields: ["requirement.sheet.reglementary_text"]
    };
    const contextCriterias = {};
    if (query.has("watchsite")) {
        contextCriterias.sheet_affected_tree = ["watchsite:" + query.get("watchsite")];
    }
    if (query.has("sheet")) {
        contextCriterias.sheet_id = query.get("sheet");
    }

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh,
        activeCriterias,
    ] = useList(
        "requirement-affected-list",
        RequirementAffectedApi,
        preFetch,
        "requirement.position",
        initialCriterias,
        "asc",
        20,
        null,
        contextCriterias
    );

    let availableKeywordFields = [
        {value: 'requirement.sheet.reglementary_text', label: 'Texte réglementaire'},
        {value: 'requirement.sheet.attachments', label: 'Fichiers APAVE'},
        {value: 'attachments', label: 'Documents associés'},
    ];
    if (hasConsultantCommentAccess) {
        availableKeywordFields.push({value: 'sheet_affected.comment_consultant', label: 'Commentaire consultant'});
    }
    if (hasCustomerCommentAccess) {
        availableKeywordFields.push({value: 'comments', label: 'Commentaire client'});
    }
    if (hasHeadquarterCommentAccess) {
        availableKeywordFields.push({value: 'sheet_affected.comment_headquarters', label: 'Commentaire siège'});
    }

    let filterType = {
        keyword: {type: "match", fields: criterias.keyword_fields},

        // -- Requirement affected
        hidden: {type: "match", fields: ["hidden"]},

        // -- Requirement
        requirement_id: {type: "match", fields: ["requirement.id"]},
        requirement_applicability_state: {type: "terms", fields: ["requirement.applicability_state"]},
        requirement_sections_all: {type: "terms", fields: ["requirement.section"]},

        // -- Query field
        sheet_affected_tree: {type: "terms", fields: ["sheet_affected.tree"]},
        sheet_id: {type: "match", fields: ["requirement.sheet.id"]},
    };
    if (hasComplianceManagementAccess) {
        filterType.conformity = {type: "terms", fields: ["conformity"]};
        filterType.priority = {type: "match", fields: ["priority"]};
        filterType.review_date = {type: "range", fields: ["review_date"]};
        filterType.evaluation_date = {type: "range", fields: ["evaluation_date"]};
        filterType.pilot = {type: "terms", fields: ["pilot"]};        
    }

    let filterTypeAdvanced = {...filterType, ...{
        // -- Sheet
        sheet_text_date: {type: "range", fields: ["requirement.sheet.text_date"]},
        sheet_publication_date: {type: "range", fields: ["requirement.sheet.publication_date"]},
        sheet_text_type: {type: "contains", fields: ["requirement.sheet.text_types"]},
        sheet_text_number: {type: "match", fields: ["requirement.sheet.text_number"]},
        sheet_source: {type: "terms", fields: ["requirement.sheet.source"]},
        sheet_transmitter: {type: "terms", fields: ["requirement.sheet.transmitter"]},
        sheet_nor: {type: "match", fields: ["requirement.sheet.nor"]},
        sheet_repealed_text: {type: "match", fields: ["requirement.sheet.repealed_text"], cast: "int"},
        sheet_descriptors: {type: "contains", fields: ["requirement.sheet.descriptors"]},
        sheet_hidden_date: {type: "range", fields: ["requirement.sheet.hidden_date"]},
        sheet_affected_affectation_date: {type: "range", fields: ["sheet_affected.affectation_date"]},
        sheet_major_text: {type: "match", fields: ["requirement.sheet.major_text"], cast: "int"},
        sheet_hidden: {type: "match", fields: ["requirement.sheet.hidden"], cast: "int"},
        sheet_private: {type: "match", fields: ["requirement.sheet.is_private"], cast: "int"},
        //Responsable de la dernière évaluation (???)

        // -- Sheet affected
        sheet_affected_responsable_evaluation: {type: "match", fields: ["sheet_affected.responsable_evaluation"]},

        sheet_domains: {type: "contains", fields: ["requirement.sheet.categories"]},
    }};
    if (hasTechnicalLevelAccess) {
        filterTypeAdvanced.sheet_text_impacts = {type: "contains", fields: ["requirement.sheet.text_impacts"]};
        filterTypeAdvanced.sheet_corporal_impact_degree = {type: "terms", fields: ["requirement.sheet.corporal_impact_degree"]};
        filterTypeAdvanced.sheet_legal_impact_degree = {type: "terms", fields: ["requirement.sheet.legal_impact_degree"]};
        filterTypeAdvanced.sheet_media_impact_degree = {type: "terms", fields: ["requirement.sheet.media_impact_degree"]};
        filterTypeAdvanced.sheet_financial_impact_degree = {type: "terms", fields: ["requirement.sheet.financial_impact_degree"]};
        filterTypeAdvanced.sheet_involved_actors = {type: "contains", fields: ["requirement.sheet.involved_actors"]};
        filterTypeAdvanced.sheet_involved_functions = {type: "contains", fields: ["requirement.sheet.involved_functions"]};        
    }
    if (hasComplianceManagementAccess) {
        filterTypeAdvanced.sheet_affected_conformity_mode = {type: "terms", fields: ["sheet_affected.conformity_mode"]};
        filterTypeAdvanced.sheet_affected_conformity = {type: "terms", fields: ["sheet_affected.conformity"]};
        filterTypeAdvanced.sheet_affected_priority = {type: "match", fields: ["sheet_affected.priority"]};
        filterTypeAdvanced.sheet_affected_review_date = {type: "range", fields: ["sheet_affected.review_date"]};
        filterTypeAdvanced.sheet_affected_evaluation_date = {type: "range", fields: ["sheet_affected.evaluation_date"]};
    }
    if (hasActionAccess) {
        filterTypeAdvanced.has_action = {type: "gte", fields: ["nb_actions"], forcevalue: 1};
    }
    if (hasCategoriesAccess) {
        filterTypeAdvanced.sheet_affected_categories = {type: "contains", fields: ["sheet_affected.categories"]};
    }

    const sortOptions = [
        {value: 'requirement.position', label: 'Position'},
        {value: 'conformity', label: 'Conformité'},
        {value: 'priority', label: 'Priorité'},
        {value: 'review_date', label: 'Date de revue'},
        {value: 'evaluation_date', label: 'Date d\'évaluation'},        
    ];

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {
        const allSections = [];
        if (sectionTree && newCriterias.requirement_sections) {
            const mapper = (section, parentSelect = false) => {
                if (newCriterias.requirement_sections.includes(section.id) || parentSelect) {
                    allSections.push(section.id);
                }
                
                if (section.children) {
                    section.children.map(s => mapper(s, newCriterias.requirement_sections.includes(section.id) || parentSelect));
                }
                return section;
            }
            sectionTree.map(s => mapper(s, false));
            newCriterias.requirement_sections_all = allSections;
        }

        if (newCriterias.sheet_has_action === "0") {
            newFilters.sheet_has_action = {type: "match", fields: ["sheet_affected.nb_actions"], cast: "int"};
        }
        if (newCriterias.requirement_sections && !newCriterias.sheet_id) {
            delete newCriterias.requirement_sections;
            delete newCriterias.requirement_sections_all;
        }
        return [newSorting, newDirection, newCriterias, newLimit, newPage, newFilters];
    }

    function getSectionTree() {
        const mapper = (section) => {
            return {
                "label": section.title,
                "value": section.id,
                "children": section.children ? section.children.map(mapper) : [],
                "checked": criterias.requirement_sections ? criterias.requirement_sections.includes(section.id) : false,
            }
        };
        return sectionTree.map(mapper);
    }

    useEffect(() => {
        // -- Reset selections after each list update
        setSelectedAffectations([]);
        setSelectedRequirements([]);
        setAffectationChanges({});
        setAllSelected(false);

        if (criterias.sheet_id) {
            RequirementApi
                .getSections(criterias.sheet_id)
                .then(newSectionTree => setSectionTree(newSectionTree));
        } else if (sectionTree !== null) { 
            setSectionTree(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows])

    useEffect(() => {
        toggleAllRequirements(allSelected);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allSelected]);

    useEffect(() => {
        submitSearch(advancedSearch ? filterTypeAdvanced : filterType, () => initializePosition(), false);

        AccountApi.getUserTree(true).then(t => setAccountTree(t));
        ExternalUserApi.list({active: 1}).then(([r]) => setPilots(compileDataToSelectOptions(r, 'user_id', ['firstname', 'lastname'])));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTree(TreeUtils.accountTreeFilterMapper(accountTree, true, criterias.sheet_affected_tree))
    }, [criterias.sheet_affected_tree, accountTree]);

    useEffect(() => {
        const watchsitesIds = [];
        rows.forEach(row => {
            row.data.forEach(rd => {
                watchsitesIds.push(rd.watchsite_id);
            });
        })

        const requests = [];
        _.uniq(watchsitesIds).forEach(watchsiteId => {
            requests.push(
                AccessApi
                    .getUsersWithWriteAccess(watchsiteId)
                    .then(users => {
                        return {
                            watchsite: watchsiteId,
                            pilots: compileDataToSelectOptions(
                                users,
                                'id',
                                [
                                    'firstname',
                                    'lastname',
                                    {
                                        field:'email',
                                        render:(v) => '(' + v + ')'
                                    }
                                ]
                            )
                        };                        
                    })
            );
        });
        axios.all(requests).then(([...responses]) => {
            const newPilots = {};
            responses.forEach(response => {
                newPilots[response.watchsite] = response.pilots;
            })
            setPilotsForWatchsites(newPilots);
        });

    }, [rows]);

    function onAffectationChange(data)  {
        let newAffectationChanges = {...affectationChanges};
        newAffectationChanges[data.id] = data;
        setAffectationChanges(newAffectationChanges);
    }

    function toggleAffectationSelect(affectation, requirement, checked) {
        let newSelectedAffectations = [...selectedAffectations];
        let newSelectedData = [...selectedData];

        const checkedAffectations = requirement.data.filter(a => selectedAffectations.includes(a.id));
        const checkedAmount = checkedAffectations.length + (checked ? 1 : -1);
        const checkedAmountRequired = requirement.data.length;

        if (checked) {
            if (!newSelectedAffectations.includes(affectation.id)) {
                newSelectedAffectations.push(affectation.id);
                newSelectedData.push({...affectation, ...{requirement_id: requirement.id}});

                // -- Select requirement if all his affectations is checked
                if (checkedAmount === checkedAmountRequired) {
                    let newSelectedRequirements = [...selectedRequirements];
                    if (!newSelectedRequirements.includes(requirement.id)) {
                        newSelectedRequirements.push(requirement.id);
                    }
                    setSelectedRequirements(newSelectedRequirements);  
                }
            }
        } else {
            newSelectedAffectations = newSelectedAffectations.filter(e => e !== affectation.id);

            // -- Remove requirement if all his affectations is not checked
            if (checkedAmount < checkedAmountRequired) {
                let newSelectedRequirements = [...selectedRequirements];
                newSelectedRequirements = newSelectedRequirements.filter(e => e !== requirement.id);
                newSelectedData = newSelectedData.filter(e => e.id !== requirement.id);
                setSelectedRequirements(newSelectedRequirements);
            }
        }

        setSelectedAffectations(newSelectedAffectations);
        setSelectedData(newSelectedData);
    }

    function toggleRequirementSelect(requirement, checked) {
        let newSelectedRequirements = [...selectedRequirements];
        let newSelectedData = [...selectedData];

        if (checked) {
            if (!newSelectedRequirements.includes(requirement.id)) {
                newSelectedRequirements.push(requirement.id);

                // -- Select linked affectations if not
                let newSelectedAffectations = [...selectedAffectations];
                const affectations = requirement.data;
                for(let a in affectations) {
                    if (!newSelectedAffectations.includes(affectations[a].id)) {
                        newSelectedAffectations.push(affectations[a].id);
                        newSelectedData.push({...affectations[a], ...{requirement_id: requirement.id}});
                    }
                }
                setSelectedAffectations(newSelectedAffectations); 
                setSelectedData(newSelectedData);
            }
        } else {
            newSelectedRequirements = newSelectedRequirements.filter(e => e !== requirement.id);

            // -- Remove all linked affectations if there is
            let newSelectedAffectations = [...selectedAffectations];
            const affectations = requirement.data;
            for(let a in affectations) {
                newSelectedAffectations = newSelectedAffectations.filter(e => e !== affectations[a].id);
                newSelectedData = newSelectedData.filter(e => e.id !== affectations[a].id);
            }
            setSelectedAffectations(newSelectedAffectations);
            setSelectedData(newSelectedData); 
        }

        setSelectedRequirements(newSelectedRequirements);
    }

    function toggleAllRequirements(checked) {
        let newSelectedRequirements = [];
        let newSelectedAffectations = [];

        if (checked === true) {
            _.forEach(rows, function(requirement) {
                newSelectedRequirements.push(requirement.id);
                
                _.forEach(requirement.data, function(requirementAffected) {
                    newSelectedAffectations.push(requirementAffected.id);
                });
            });
        }

        setSelectedRequirements(newSelectedRequirements);
        setSelectedAffectations(newSelectedAffectations);
    }

    function saveAffectationChanges() {
        if (_.size(affectationChanges) > 0) {
            setSaving(true);
            const affectations = Object.values(affectationChanges);
            RequirementAffectedApi
                .inlineUpdate(affectations)
                .then((message) => {
                    if (message) {
                        setAffectationChanges({});
                        setSaving(false);
                        setErrors([]);
                        toast.success(message);
                    }
                })                
                .catch(error => {
                    setSaving(false);
                    const data = error.response.data;

                    if (data.message) {
                        toast.error(data.message);
                    }

                    if(data.errors) {
                        setErrors(data.errors);
                    }
                });
        }
    }

    const exitChangesMessage = "Vous avez des modifications non enregistrées, "; 

    function resetFilters(event) {
        event.preventDefault();
        if (_.size(affectationChanges) === 0) {
            props.history.push(prefixLinkTo() + "/requirements-affected"); 
            refresh(true, true, {
                hidden: "0",
                requirement_applicability_state: [
                    APPLICABILITY_INCOMING,
                    APPLICABILITY_CURRENT
                ],
                keyword_fields: ["requirement.sheet.reglementary_text"]
            });
        } else {
            confirm(exitChangesMessage + "êtes-vous sur de vouloir réinitialiser la recherche ?", function() {
                props.history.push(prefixLinkTo() + "/requirements-affected"); 
                refresh(true, true, {
                    hidden: "0",
                    requirement_applicability_state: [
                        APPLICABILITY_INCOMING,
                        APPLICABILITY_CURRENT
                    ],
                    keyword_fields: ["requirement.sheet.reglementary_text"]
                });
            });
        }
    }

    function sendSearch(event) {
        event.preventDefault();
        if (_.size(affectationChanges) > 0) {
            confirm(
                exitChangesMessage + "êtes-vous sur de vouloir de rafraichir la liste ?", 
                () => { submitSearch(advancedSearch ? filterTypeAdvanced : filterType); }
            );            
        } else {
            submitSearch(advancedSearch ? filterTypeAdvanced : filterType);
        }
    }

    function exportRequirementAffecteds() {
        setExporting(true);
        const exportQuery = convertCriteriesToSearchQuery(criterias, (advancedSearch ? filterTypeAdvanced : filterType));
        const fileName = FileUtils.getFilePrefix() + "-Requirements.xlsx";
        RequirementAffectedApi.export(exportQuery, fileName, sorting, direction)
            .then(() => toast.success("L'export a été initié, vous serez notifié lorsqu'il sera disponible."))
            .catch(() => toast.error("Erreur durant la création de l'export."))
            .finally(() => setExporting(false));
    }

    return (     
        <DefaultLayout screen="E38" title="Liste des exigences" onScroll={(position) => setPosition(position)}>
            <Prompt
                when={_.size(affectationChanges) > 0}
                message={(location) => location.pathname === "/requirements-affected" ? true : exitChangesMessage + "voulez-vous vraiment quitter ?"}
            />
            <h1>Liste des exigences</h1>
            <div className="bloc">
                <form className="form" onSubmit={sendSearch} >
                    <section className="filters">
                        <header>
                            <div className="row">
                                <div className="col-md-2"><h2><button className={displayFilter ? "toggler" : "toggler actif"} type="button" onClick={() => setDisplayFilter(!displayFilter)}>Recherche</button></h2></div>
                                <div className="col-md-2"><h2><button className={advancedSearch ? "toggler" : "toggler actif"} type="button" onClick={() => setAdvancedSearch(!advancedSearch)}>Recherche avancée</button></h2></div>
                            </div>
                        </header>
                        <div className={displayFilter ? "bg-gris-25" : "d-none"}>
                            <div className="row">
                                <FilterComponent 
                                    type="keyword"
                                    name="keyword"
                                    label="Recherche libre" 
                                    fields={availableKeywordFields}
                                    selectedFields={criterias.keyword_fields}
                                    onChange={value => addCriteria("keyword", value)}
                                    onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(criterias.keyword_fields, value))}
                                    value={criterias.keyword || ""} 
                                />
                                <FilterComponent type="text" name="requirement_id" onChange={value => addCriteria("requirement_id", value)} value={criterias.requirement_id} label="Numéro d'exigence" />
                                <FilterComponent type="select" clearable options={getApplicabilities()} name="requirement_applicability_state" onChange={value => addCriteria("requirement_applicability_state", value)} value={criterias.requirement_applicability_state} label="Etat d'applicabilité" multiple />
                                {hasComplianceManagementAccess && <>
                                    <FilterComponent type="buttons" name="conformity" onChange={value => addCriteria("conformity", value)} value={criterias.conformity} label="Conformité" clearable options={getConformities()} multiple />
                                    <FilterComponent type="select" name="priority" onChange={value => addCriteria("priority", value)} value={criterias.priority} label="Priorité" clearable options={getPriorities()} />
                                    <FilterComponent type="daterange" name="evaluation_date" onChange={value => addCriteria("evaluation_date", value)} value={criterias.evaluation_date} label="Date d'évaluation" />
                                    <FilterComponent type="daterange" name="review_date" onChange={value => addCriteria("review_date", value)} value={criterias.review_date} label="Date de revue" />
                                </>}
                                {sectionTree && 
                                    <FilterComponent
                                        type="dropdown-tree-select"
                                        name="requirement_sections"
                                        clearable
                                        onChange={value => addCriteria("requirement_sections", value)}
                                        value={criterias.requirement_sections} 
                                        label="Sommaire"
                                        data={getSectionTree()}
                                        mode="multiSelect"
                                    />
                                }
                                
                                <FilterComponent type="radio" name="hidden" onChange={value => addCriteria("hidden", value)} value={criterias.hidden} label="Exigence masquée" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                {hasComplianceManagementAccess && <FilterComponent type="select" name="pilot" onChange={value => addCriteria("pilot", value)} value={criterias.pilot} label="Pilote" clearable options={pilots} />}
                                
                                <FilterComponent type="number" name="sheet_id" onChange={value => addCriteria("sheet_id", value)} value={criterias.sheet_id} label="Numéro de fiche" />
                                <FilterComponent type="dropdown-tree-select" name="sheet_affected_tree" onChange={value => addCriteria("sheet_affected_tree", value)} useMapper={false} value={criterias.sheet_affected_tree} label="Noeud/Point de veille" data={tree} mode="multiSelect" />
                            </div>
                        </div>

                        <section className={displayFilter && advancedSearch ? "" : "d-none"}>
                            <header className="bg-gris-40">
                                <h3><button className="toggler actif" type="button" onClick={() => setAdvancedSearch(!advancedSearch)}>Recherche avancée</button></h3>
                            </header>
                            <div className="bg-gris-25">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="alert alert-warning" role="alert">
                                            L'utilisation des critères de recherche avancée peut augmenter de manière conséquente les temps de recherche
                                        </div>
                                    </div>                                    
                                    <FilterComponent type="daterange" name="sheet_text_date" onChange={value => addCriteria("sheet_text_date", value)} value={criterias.sheet_text_date} label="Date du texte" />   
                                    <FilterComponent type="daterange" name="sheet_publication_date" onChange={value => addCriteria("sheet_publication_date", value)} value={criterias.sheet_publication_date} label="Date de publication" />
                                    <FilterComponent type="select" name="sheet_text_type" onChange={value => addCriteria("sheet_text_type", value)} value={criterias.sheet_text_type} label="Type de texte" clearable options={textTypes} multiple />
                                    <FilterComponent type="text" name="sheet_text_number" onChange={value => addCriteria("sheet_text_number", value)} value={criterias.sheet_text_number} label="Numéro de texte" />
                                    <FilterComponent type="select" name="sheet_source" onChange={value => addCriteria("sheet_source", value)} value={criterias.sheet_source} label="Source" multiple={true} options={sources} />
                                    <FilterComponent type="select" name="sheet_transmitter" onChange={value => addCriteria("sheet_transmitter", value)} value={criterias.sheet_transmitter} label="Emetteur" multiple={true} options={transmitters} />
                                    <FilterComponent type="select" name="sheet_descriptors" onChange={value => addCriteria("sheet_descriptors", value)} value={criterias.sheet_descriptors} label="Descripteurs" multiple={true} options={descriptors} />
                                    <FilterComponent type="text" name="sheet_nor" onChange={value => addCriteria("sheet_nor", value)} value={criterias.sheet_nor} label="Numéro NOR" />
                                    <FilterComponent type="radio" name="sheet_repealed_text" onChange={value => addCriteria("sheet_repealed_text", value)} value={criterias.sheet_repealed_text} label="Texte abrogé" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                    <FilterComponent type="dropdown-tree-select" name="sheet_domains" onChange={value => addCriteria("sheet_domains", value)} value={criterias.sheet_domains}  label="Domaines / Sous domaines / Thèmes" data={domainTree} mode="multiSelect" />
                                    {hasTechnicalLevelAccess && <>
                                        <FilterComponent type="select" name="sheet_text_impacts" onChange={value => addCriteria("sheet_text_impacts", value)} value={criterias.sheet_text_impacts} label="Impact du texte" multiple={true} options={textImpacts} />
                                        <FilterComponent type="select" name="sheet_involved_actors" onChange={value => addCriteria("sheet_involved_actors", value)} value={criterias.sheet_involved_actors} label="Acteurs concernés" multiple={true} options={involvedActors} />
                                        <FilterComponent type="select" name="sheet_involved_functions" onChange={value => addCriteria("sheet_involved_functions", value)} value={criterias.sheet_involved_functions} label="Fonctions concernées" multiple={true} options={involvedFunctions} />
                                    </>}
                                    <div className="col-md-3"></div>
                                    {hasTechnicalLevelAccess && <>
                                        <FilterComponent type="degree-checkbox" name="sheet_corporal_impact_degree" onChange={value => addCriteria("sheet_corporal_impact_degree", value)} value={criterias.sheet_corporal_impact_degree} label="Degré d'impact corporel" options={getDegree()} />
                                        <FilterComponent type="degree-checkbox" name="sheet_legal_impact_degree" onChange={value => addCriteria("sheet_legal_impact_degree", value)} value={criterias.sheet_legal_impact_degree} label="Degré d'impact juridique" options={getDegree()} />
                                        <FilterComponent type="degree-checkbox" name="sheet_media_impact_degree" onChange={value => addCriteria("sheet_media_impact_degree", value)} value={criterias.sheet_media_impact_degree} label="Degré d'impact médiatique" options={getDegree()} />
                                        <FilterComponent type="degree-checkbox" name="sheet_financial_impact_degree" onChange={value => addCriteria("sheet_financial_impact_degree", value)} value={criterias.sheet_financial_impact_degree} label="Degré d'impact financier" options={getDegree()} />     
                                    </>}                                    
                                    <FilterComponent type="daterange" name="sheet_affected_affectation_date" onChange={value => addCriteria("sheet_affected_affectation_date", value)} value={criterias.sheet_affected_affectation_date} label="Date de mise en ligne" />
                                    <FilterComponent type="daterange" name="sheet_hidden_date" onChange={value => addCriteria("sheet_hidden_date", value)} value={criterias.sheet_hidden_date} label="Date de masquage" />
                                    <FilterComponent type="radio" name="sheet_major_text" onChange={value => addCriteria("sheet_major_text", value)} value={criterias.sheet_major_text} label="Texte majeur" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                    <FilterComponent type="radio" name="sheet_hidden" onChange={value => addCriteria("sheet_hidden", value)} value={criterias.sheet_hidden} label="Fiche masquée" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                    <FilterComponent type="radio" name="sheet_private" onChange={value => addCriteria("sheet_private", value)} value={criterias.sheet_private} label="Fiche privée" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                    {hasActionAccess && <FilterComponent type="radio" name="has_action" onChange={value => addCriteria("has_action", value)} value={criterias.has_action} label="Exigence avec action" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />}

                                    <FilterComponent type="select" name="sheet_affected_responsable_evaluation" onChange={value => addCriteria("sheet_affected_responsable_evaluation", value)} value={criterias.sheet_affected_responsable_evaluation} label="Responsable de la dernière évaluation" clearable options={pilots} />
                                    {hasComplianceManagementAccess && <>
                                        <FilterComponent type="select" name="sheet_affected_conformity_mode" onChange={value => addCriteria("sheet_affected_conformity_mode", value)} value={criterias.sheet_affected_conformity_mode} label="Mode gestion" options={getConformityModes()} clearable />
                                        <FilterComponent type="buttons" name="sheet_affected_conformity" onChange={value => addCriteria("sheet_affected_conformity", value)} value={criterias.sheet_affected_conformity} label="Conformité de la fiche" clearable options={getConformities()} multiple />
                                        <FilterComponent type="select" name="sheet_affected_priority" onChange={value => addCriteria("sheet_affected_priority", value)} value={criterias.sheet_affected_priority} label="Priorité de la fiche" clearable options={getPriorities()} />
                                        <FilterComponent type="daterange" name="sheet_affected_review_date" onChange={value => addCriteria("sheet_affected_review_date", value)} value={criterias.sheet_affected_review_date} label="Date de revue de la fiche" />
                                        <FilterComponent type="daterange" name="sheet_affected_evaluation_date" onChange={value => addCriteria("sheet_affected_evaluation_date", value)} value={criterias.sheet_affected_evaluation_date} label="Date d'évaluation de la fiche" />
                                    </>}
                                    {hasCategoriesAccess && <FilterComponent type="dropdown-tree-select" name="sheet_affected_categories" onChange={value => addCriteria("sheet_affected_categories", value)} value={criterias.sheet_affected_categories} label={AccountUtils.getCategoriesLabel(account)} data={categoryTree} mode="multiSelect" />}                                    
                                </div>
                            </div>
                        </section>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-requirement-affected" onClick={(e) => resetFilters(e)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <LoadButton id="search-requirement-affected" type="submit" label="Rechercher" loading={loading} />
                                </div>
                            </div>
                        </div>
                    </section>
                </form>

                <form className="form">
                    <ListComponent 
                        id="requirement-affected"
                        className="table table-bordered"
                        displayHeader={false}
                        loading={loading}
                        rows={rows}
                        sortOptions={sortOptions}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        paginationConfirm={_.size(affectationChanges) > 0 ? exitChangesMessage + "voulez-vous vraiment quitter ?" : null}
                        renderRow={(row) => 
                            <RequirementItemList 
                                key={row.id}
                                item={row}
                                selected={selectedRequirements.includes(row.id)}
                                onSelect={toggleRequirementSelect}
                                selectedAffectations={selectedAffectations}
                                onAffectationSelect={toggleAffectationSelect}
                                onAffectationChange={onAffectationChange}
                                toggled={isToggled(row.id)}
                                toggleRequirement={toggleRequirement}
                                errors={errors}
                                pilotsForWatchsites={pilotsForWatchsites}
                            />
                        }
                        globalActions={<>
                            <LoadButton
                                loading={false}
                                onClick={() => setImportModal(true)}
                                label="Importer"
                                id="import-requirementaffected"
                                className="btn btn-primary h25"
                                iconClass="icon-boutons-enregistrer"
                            />
                            {activeCriterias.sheet_id && totalrows > 0 &&
                                <LoadButton
                                    loading={exporting}
                                    onClick={exportRequirementAffecteds}
                                    label="Exporter"
                                    id="export-requirementaffected"
                                    className="btn btn-primary h25"
                                    iconClass="icon-file-excel"
                                />
                            }
                        </>}
                        batchActions={<>
                            <input onChange={(e) => setAllSelected(e.currentTarget.checked)} type="checkbox" name="all-items" id="all-items" checked={allSelected} />
							<label htmlFor="all-items">&nbsp;</label>

                            <RequirementAffectedListBatch
                                selection={selectedAffectations} 
                                selectionData={selectedData}
                                refresh={() => {
                                    setSelectedAffectations([]);
                                    setSelectedRequirements([]);
                                    refresh()
                                }}
                                history={props.history}
                            />
                        </>}
                    />
                </form>

                <section className="row">
                    <div className="col-md-12 text-right">
                        <LoadButton
                            loading={saving} 
                            label="Enregistrer les modifications"
                            name="save"
                            id="save"
                            disabled={_.size(affectationChanges) === 0}
                            onClick={saveAffectationChanges}
                        />
                    </div>
                </section>

                <ImportModal 
                    show={importModal}
                    onClose={(forceRefresh) => {
                        setImportModal(false);
                        if (forceRefresh) refresh();
                    }}
                />
            </div>
        </DefaultLayout>
    );
}