import FieldComponent from 'shared/components/FieldComponent';
import { useState } from 'react';
import { toast } from 'react-toastify';
import FileListComponent from './FileListComponent';

export default function FileComponent(props) {

    const { label, name, extensions, multiple, onChange, value, onUploadStart, onUploadDone, showSize, showDate, showRequirements, withMetadata, upload, uploadAdditionalParams, error, disabled } = props;
    const maxSize = props.maxSize || "2MO";

    const [uploads, setUploads] = useState([]);
    const [fileError, setFileError] = useState(error);

    function uploadDone(filesUploaded) {
        if (filesUploaded) {
            if (multiple) {
                let newValue = [...value];
                filesUploaded.forEach(fileUploaded => {
                    newValue.push(fileUploaded);
                });
                onChange(newValue);
            } else {
                onChange(filesUploaded[0]);
            }
        }

        setUploads([]);
        if(onUploadDone) onUploadDone();
    }

    function preUpload(files) {
        if(upload) {
            upload(files, uploadAdditionalParams)
                .then(filesUploaded => {
                    uploadDone(filesUploaded);
                })
                .catch(e => {
                    toast.warning("Une erreur est survenue lors du téléversement");
                    if (e.response.data.message) {
                        setFileError(e.response.data.message);
                    } else {
                        setFileError("Une erreur est survenue lors du téléversement");
                    }
                    setUploads([]);
                    if(onUploadDone) onUploadDone();
                });
        }
    }

    function preOnChange(files, event) {
        let newUploads = [...uploads];
        let finalFiles = [];

        if(files instanceof FileList) {
            for(var i = 0; i < files.length; i++) {
                let file = files[i];
                finalFiles.push(file);
                newUploads.push(file.name);
            }
        } else {
            if(onChange) onChange(null);
            finalFiles.push(files);
            newUploads.push(files.name);
        }

        setFileError(null);
        preUpload(finalFiles);
        setUploads(newUploads);

        // -- On vide le champ
        event.target.value = '';

        if(onUploadStart) onUploadStart();
    }

    let values = value;
    if(!values) {
        values = [];
    } else {
        if (Array.isArray(values) === false) {
            values = [value];
        }
    }

    function removeFile(hash) {
        if(onChange) {
            if(multiple) {
                onChange(
                    value.filter(file => file.hash !== hash)
                );
            } else {
                onChange(null);
            }
        }
    }

    function onMetadataUpdate(field, value, file) {
        if (props.onMetadataUpdate) {
            props.onMetadataUpdate(field, value, file);
        }
    }

    return (
        <FieldComponent
            label={label}
            type="file"
            accept={extensions}
            className={props.inputClassName}
            multiple={multiple}
            name={name}
            onChange={preOnChange}
            error={error || fileError}
            disabled={disabled}
        >
            {showRequirements && (
                <div style={{'color': 'grey', 'fontSize': 10, 'flex': '0 0 100%', 'paddingLeft': 240, 'marginBottom': 12}}>
                    <strong>Taille maximale par fichier :</strong> {maxSize}
                    {extensions && (<>, <strong>extensions autorisées :</strong> {extensions.join(', ').replaceAll('.', '')}</>)}
                </div>
            )}
            <FileListComponent
                values={values}
                uploads={uploads}
                onRemoveFile={removeFile}
                showDate={showDate}
                showSize={showSize}
                withMetadata={withMetadata}
                onMetadataUpdate={onMetadataUpdate}
            />
        </FieldComponent>
    );
}

FileComponent.defaultProps = {
    showDate: false,
    showSize: false,
    showRequirements: true,
    uploadAdditionalParams: {},
    withMetadata: false,
    onMetadataUpdate: null,
    inputClassName: "field medium",
};
