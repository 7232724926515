import React from 'react';
import { Link } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { prefixLinkTo } from 'shared/services';

export default function Breadcrumbs(props) {
    const breadcrumbs = useBreadcrumbs(props.routes, { disableDefaults: true });

    return (
        <div className="ariane">
            <p>
                {breadcrumbs.map(({match, breadcrumb}) => {
                    if (match.url === document.location.pathname) {
                        return (<span key={match.url}>{breadcrumb.props.children}</span>);
                    } else {
                       return (<React.Fragment key={match.url}><Link to={prefixLinkTo() + match.url}>{breadcrumb.props.children}</Link> &gt; </React.Fragment>)
                    }
                })}
            </p>
        </div>
    );
}