import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import DefaultLayout from "layouts/DefaultLayout";
import BarLoader from "react-spinners/BarLoader";
import DocumentarySpaceLinkApi from 'api/DocumentarySpaceLink';

export default function DocumentarySpaceLink(props) {

    function redirect() {
        DocumentarySpaceLinkApi
            .link()
            .then(response => window.open(response.link))
            .catch(() => toast.error("Erreur durant la redirection"));
    }

    useEffect(redirect, []);

    return (
        <DefaultLayout title="Espace documentaire">
            <h1>Espace documentaire</h1>
            <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
            <p className="text-center">Redirection en cours</p>            
        </DefaultLayout>
    );
}