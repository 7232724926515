import { React,  DefaultLayout } from 'commons';
import { NavLink } from "react-router-dom";
import { Can } from 'shared/services';
import { useAccount } from 'hooks/Account';

export default function DocumentarySpaceHome() {

    const [account] = useAccount();

    return (
        <DefaultLayout title="Accueil espace documentaire" contentWrapperClassName="content-wrapper bg-gris-10">
            <h1 className="main-title">Espace documentaire</h1>
            <div className="row grid">
            <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-search" to="/documentary-space/search" title="Recherche">
                            <i className="icon-nav-recherche-texte" aria-hidden="true"></i>
                            Recherche
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-news" to="/documentary-space/news" title="Actualité documentaire">
                            <i className="icon-documentaire-actualites-documentaires" aria-hidden="true"></i>
                            Actualité documentaire
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-form" to="/documentary-space/form" title="Formulaires et déclaration">
                            <i className="icon-documentaire-formulatires-declaration" aria-hidden="true"></i>
                            Formulaires et déclaration
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-caselaw" to="/documentary-space/caselaw" title="Jurisprudences">
                            <i className="icon-documentaire-jurisprudence" aria-hidden="true"></i>
                            Jurisprudences
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-thematicfile" to="/documentary-space/thematicfile" title="Dossiers et fiches thématiques">
                            <i className="icon-documentaire-dossiers-thematiques" aria-hidden="true"></i>
                            Dossiers et fiches thématiques
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-regulatorycontrol" to="/documentary-space/regulatorycontrol" title="Contrôles réglementaires">
                            <i className="icon-documentaire-controles-reglementaires" aria-hidden="true"></i>
                            Contrôles réglementaires
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-drafttext" to="/documentary-space/drafttext" title="Projet de texte">
                            <i className="icon-documentaire-projet-texte" aria-hidden="true"></i>
                            Projet de texte
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-newsflash" to="/documentary-space/newsflash" title="Flash d'information">
                            <i className="icon-documentaire-flash-informations" aria-hidden="true"></i>
                            Flash d'information
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-documentaryspace-newsletter" to="/documentary-space/newsletter" title="Newsletter">
                            <i className="icon-documentaire-newsletter" aria-hidden="true"></i>
                            Newsletter
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <Can perform="account:private_documentary_space" data={{account: account}} yes={() => (<>
                    <div className="col-md-3">
                        <div className="contenu">
                            <NavLink className="padding" id="home-documentaryspace-clientspecificmodule" to="/documentary-space/clientspecificmodule" title="Document privé">
                                <i className="icon-documentaire-module-prive-client" aria-hidden="true"></i>
                                Document privé
                                &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                            </NavLink>
                        </div>
                    </div>
                </>)} />
            </div>
        </DefaultLayout>
    );
}