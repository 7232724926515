import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import AccountApi from 'shared/api/Account';
import SecurityApi from 'shared/api/Security';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import { useAccount } from 'hooks/Account';

export default function SelectAccount(props) {

    const [,,,,,, setTempAccount] = useAccount();
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState(true);

    useEffect(() => {
        AccountApi
            .list(10000)
            .then(([results]) => {
                setAccounts(results.map(a => { return {value: a.id, label: a.name}}))
                setAccountsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function submit(event) {
        event.preventDefault();
        if (selectedAccount) {
            setLoading(true);

            SecurityApi
                .selectAccount(selectedAccount)
                .then(response => {
                    setTempAccount(selectedAccount);
                    if (response.data.identitychecking) {
                        document.location.href = process.env.REACT_APP_BACKEND_URL + 'identitychecking';                        
                    } else {
                        props.history.push("/loader");
                    }
                })
                .catch(() => {
                    setLoading(false);
                    toast.error("Impossible de sélectionner le compte")
                });

        } else {
            toast.error("Veuillez sélectionner un compte");
        }
    }

    return (
        <section className="login">
            <form className="form" onSubmit={submit}>
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Choisir votre compte</h2>
                        </header>
                        {accounts.length > 0 && <div className="bg-blanc border-rlb border-gris-40">
                            <p>Vous êtes associés à plusieurs comptes. Veuillez sélectionner celui à utiliser.</p>
                            <FieldComponent 
                                label="Compte"
                                name="account"
                                type="select"
                                value={selectedAccount}
                                options={accounts}
                                onChange={(value) => setSelectedAccount(value)}
                            />
                            <div className="row">
                                <div className="col-md-12 text-right order-1">
                                    <LoadButton loading={loading} loaderWidth={25} label="Choisir" name="select-account" id="select-account" displayIcon={false} />
                                </div>
                            </div>
                        </div>}
                        {accountsLoading && <div className="bg-blanc border-rlb border-gris-40">
                            <p>Veuillez patienter, chargement de la liste des comptes accessibles en cours.</p>
                        </div>}
                        {!accountsLoading && accounts.length === 0 && <div className="bg-blanc border-rlb border-gris-40">
                            <p>Votre utilisateur n'est associé à aucun compte valide. Veuillez contacter votre conseiller Apave.</p>
                        </div>}
                    </section>
                </div>
            </form>
        </section>
    );
}