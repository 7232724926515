export default function ChecksingleReadOnlyComponent(props) {

    const { value } = props;

    if (value) {
        return <>
            <i className="icon-ok-squared color-vert-apave" aria-hidden="true"></i>
            <span className="sr-only">Oui</span>        
        </>;
    }

    return <>
        <i className="icon-check-empty" aria-hidden="true"></i>
        <span className="sr-only">Non</span>
    </>;
}