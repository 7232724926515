import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BarLoader from "react-spinners/BarLoader";
import ActionApi from 'api/Action';
import { formatDate } from 'shared/services/Utils';
import { useSecurity } from 'shared/hooks/Security';
import { ACTION_STATUS_PLANNED, ACTION_STATUS_COMPLETED, ACTION_STATUS_EVALUATED } from 'data/Action';
import { prefixLinkTo } from 'shared/services';

export default function LastActions() {

    const [user] = useSecurity();
    const [actions, setActions] = useState([]);
    const [nbActions, setNbActions] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reducedDisplay, toggle] = useState(true);

    function load() {
        ActionApi
            .search(                
                {"bool": {"must": [
                    {"match": {"pilot": user.id}},
                    {"terms": {"status": [ACTION_STATUS_PLANNED, ACTION_STATUS_COMPLETED, ACTION_STATUS_EVALUATED]}},
                    {"match": {"hidden": 0}}
                ]}},
                0,
                10,
                "created_at",
                "DESC"
            )
            .then(([results,, total]) => {
                setLoading(false);
                setActions(results);
                setNbActions(total);
            })
            .catch(() => setLoading(false));
    }

    useEffect(load, [user]);

    return (
        <div className="contenu padding lastactions">
			<h2><i className="icon-accueil-dernieres-actions"></i>Mes actions en cours</h2>
            <BarLoader loading={loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
			{!loading && <ul className="item-list latest striped">
                {actions.map((action, index) => {
                    if (index <= 1 || reducedDisplay === false) {
                        return <li key={action.id}>
                            <div className="infos">
                                <Link to={prefixLinkTo() + `/actions/${action.id}`} title="Voir l'action">
                                    <strong>{action.id} : </strong>
                                    {action.label}
                                </Link>
                                <div className="row">
                                    <div className="col-md-6">
                                        <strong className="small color-gris-50">Date de création</strong>
                                        <br/>
                                        <strong>{formatDate(action.created_at)}</strong>
                                    </div>
                                    <div className="col-md-6">
                                        <strong className="small color-gris-50">Date limite de réalisation</strong>
                                        <br/>
                                        <strong>{formatDate(action.deadline_date)}</strong>
                                    </div>
                                </div>
                            </div>
                            <div className="detail">
                                <Link to={prefixLinkTo() + `/actions/${action.id}`} title="Voir l'action"><i className="icon-boutons-lien-vers" aria-hidden="true"></i></Link>
                            </div>
                        </li>;
                    } else {
                        return <React.Fragment key={action.id}></React.Fragment>;
                    }                    
                })}
			</ul>}
			<div className="row">
				<div className="offset-md-3 col-md-6 text-center">
					<Link to={prefixLinkTo() + "/actions"} className="btn btn-primary icon h35"><i className="icon-boutons-lien-vers" aria-hidden="true"></i> Voir tout le plan d'actions ({nbActions})</Link>
				</div>
				{!loading && actions.length > 2 && <div className="col-md-3 text-right">
                    {reducedDisplay && <button onClick={() => toggle(false)} className="toggler actif after" type="button">Voir plus</button>}
                    {!reducedDisplay && <button onClick={() => toggle(true)} className="toggler after" type="button">Voir moins</button>}
				</div>}
			</div>
		</div>
    );
}
