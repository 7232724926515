import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useAccount } from 'hooks/Account';
import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";
import { denyIfCantPerform, canPerform } from "shared/services/Can";
import DefaultLayout from "layouts/DefaultLayout";
import AccountApi from "shared/api/Account";
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import DomainSelectionComponent from 'shared/components/DomainSelectionComponent';

export default function AccountConfigurationForm(props) {
    
    const [saving, setSaving] = useState(false);
    const [account,, setAccount] = useAccount();
    const [data,,, setValue] = useForm({
        id: account.id,
        category_label: account.category_label,
        subcategory_label: account.subcategory_label,
        customfield1_label: account.customfield1_label,
        customfield2_label: account.customfield2_label,
        default_review_date: account.default_review_date,
        active_domains: account.active_domains.map(c => {return c.id ?? c;}),
    });
    const [togglers, toggle] = useToggler({'configuration': true, 'domains-configuration': true});

    denyIfCantPerform(props, "account:write", {account: account});

    function save(event) {
        event.preventDefault();
        setSaving(true);

        AccountApi
            .saveConfiguration(data)
            .then(d => {
                setAccount(d);
                setSaving(false);
                toast.success("Enregistrement effectué.");
            })
            .catch(error => {
                setSaving(false);
                toast.error(error.response.data.message);
            });
            setSaving(false);

    }

    return (
        <DefaultLayout screen="E55" title={`Configuration du compte ${account.name}`}>
            <h1>Configuration du compte {account.name}</h1>
            <section>
                <form id="form-account-configuration" className="form" onSubmit={save}>
                    {!account.is_start && <ToggableBlocComponent label={`Configuration ${account.name}`} id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                        {canPerform("account:categories", {account: account}) && <>
                            <FieldComponent
                                className="field small"
                                label="Libellé des catégories" 
                                name="category_label" 
                                value={data.category_label} 
                                onChange={value => setValue("category_label", value)}
                            />
                            <FieldComponent
                                className="field small"
                                label="Libellé des sous catégories" 
                                name="subcategory_label" 
                                value={data.subcategory_label} 
                                onChange={value => setValue("subcategory_label", value)}
                            />
                        </>}
                        {canPerform("account:actionplan", {account: account}) && <>
                            <FieldComponent
                                className="field small"
                                label="Libellé champ personnalisable 1 du plan d'action" 
                                name="customfield1_label" 
                                value={data.customfield1_label} 
                                onChange={value => setValue("customfield1_label", value)}
                            />
                            <FieldComponent
                                className="field small"
                                label="Libellé champ personnalisable 2 du plan d'action" 
                                name="customfield2_label" 
                                value={data.customfield2_label} 
                                onChange={value => setValue("customfield2_label", value)}
                            />
                        </>}
                        {canPerform("account:compliance_management", {account: account}) && <>
                            <FieldComponent
                                className="field xxsmall"
                                label="Date de revue par défaut"
                                name="default_review_date"
                                value={data.default_review_date}
                                onChange={value => setValue("default_review_date", value ? parseInt(value) : null)}
                                wrapper={([rawLabel, input, errors, children, label]) => {
                                    return <div className="flex-label">
                                        {label}
                                        Date d'évaluation +&nbsp;
                                        {input}
                                        &nbsp;mois
                                    </div>
                                }}
                            />
                        </>}
                    </ToggableBlocComponent>}
                    {account.is_start && <>
                        <ToggableBlocComponent label={`Configuration des domaines start`} id="domains-configuration" toggled={togglers["domains-configuration"]} toggle={toggle}>
                            <DomainSelectionComponent
                                label="Configuration des domaines/sous-domaines/thèmes"
                                name="active_domains"
                                value={data.active_domains}
                                onChange={value => setValue("active_domains", value)}
                                accounts={[account.id]}
                            />
                        </ToggableBlocComponent>
                    </>}
                    <section className="row">
                        <div className="col text-right">
                            <LoadButton 
                                loading={saving} 
                                label="Enregistrer" 
                                name="save" 
                                id="save-account-configuration"
                            />
                        </div>                        
                    </section>    
                </form>
            </section>
        </DefaultLayout>
    );
}
