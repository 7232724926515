import React, { useEffect } from 'react';
import _ from 'lodash';
import { useForm } from "shared/hooks/Form";
import { useAccess } from 'hooks/Access';
import FieldComponent from 'shared/components/FieldComponent';
import { getConformities, CONFORMITY_NEW } from 'data/SheetAffected';
import { compileErrors } from "shared/services/Utils";
import { getPriorities } from 'data/Priority';
import { Link } from "react-router-dom";
import { canPerform } from "shared/services/Can";
import { useAccount } from 'hooks/Account';
import { prefixLinkTo } from 'shared/services';

export default function RequirementAffectedItemList(props) {

    const { affectationAmount, requirement, item, selected, onSelect, onChange, pilotsForWatchsites } = props;
    const errors = compileErrors(props.errors);
    const [account] = useAccount();
    const hasComplianceManagementAccess = canPerform("account:compliance_management", {account: account});
    const hasActionAccess = canPerform("account:actionplan", {account: account});
    const pilots = pilotsForWatchsites[item.watchsite_id] ?? [];

    const [data,,, setValue, setData] = useForm({
        id: null,
        evaluation_date: null,
        review_date: null,
        conformity: CONFORMITY_NEW,
        priority: 2,
        pilot: null,
        hidden: false,
    });
    const [canWrite] = useAccess();

    const hasWriteAccess = canWrite(item.watchsite_id);

    useEffect(() => {
        let newData = {...props.item};
        newData.evaluation_date = newData.evaluation_date ? new Date(newData.evaluation_date) : null;
        newData.review_date = newData.review_date ? new Date(newData.review_date) : null;
        newData.nb_actions = newData.nb_actions || 0;
        setData(newData);
    }, [props.item, setData]);

    function preSetValue(field, value) {
        setValue(field, value, true, function(newData) {
            onChange(newData);
        });
    }

    function renderFieldWrap([label, input]) {
        return (<>
            <label style={{fontWeight: 300, fontSize: 12}}>{label}</label>
            {input}
        </>);
    }

    return (
        <tr className={"border-b" + (_.size(errors) ? " border-t border-r border-l" : "")} style={{borderColor: (_.size(errors) ? 'red' : '#e5e2e2')}}>
            {affectationAmount > 1 && (
                <td className="w-34px text-center valign-top">
                    <input
                        checked={selected}
                        onChange={(event) => onSelect(data, requirement, event.target.checked)}
                        type="checkbox"
                        className="select-affectation"
                        id={"select-affectation-" + item.id}
                    />
                    <label htmlFor={"select-affectation-" + item.id}></label>
                </td>
            )}
            <td className="bg-gris-20" colSpan={99}>
                <Link to={prefixLinkTo() + "/requirements-affected/" + data.id} title="Voir l'exigence">
                    <strong title={"Affectation " + item.id}>{item.watchsite || 'N/A'}</strong>
                </Link>
                <div className="d-flex border-gris-25 toggle-group">
                    {hasComplianceManagementAccess && <>
                        <div className="w-335px cell-padding">
                            <FieldComponent
                                type="buttons"
                                name={"conformity-" + item.id}
                                onChange={value => preSetValue("conformity", parseInt(value))}
                                value={data.conformity}
                                label="Conformité"
                                options={getConformities()}
                                wrapper={([label, input]) => <>
                                    <label style={{fontWeight: 300, fontSize: 12}}>{label}</label>
                                    <div className="filters">
                                        <fieldset className="statuts">{input}</fieldset>
                                    </div>
                                </>}
                            />
                        </div>
                        <div className="w-auto cell-padding">
                            <FieldComponent
                                label="Date d'évaluation"
                                type="date"
                                name={"evaluation_date-" + item.id}
                                value={data.evaluation_date}
                                error={errors.evaluation_date}
                                onChange={value => preSetValue("evaluation_date", value)}
                                wrapper={renderFieldWrap}
                            />
                        </div>
                        <div className="w-auto cell-padding">
                            <FieldComponent
                                label="Date de revue"
                                type="date"
                                name={"review_date-" + item.id}
                                value={data.review_date}
                                error={errors.review_date}
                                onChange={value => preSetValue("review_date", value)}
                                wrapper={renderFieldWrap}
                            />
                        </div>
                        <div className="w-auto cell-padding">
                            <FieldComponent 
                                type="buttons"
                                name={"priority-" + item.id}
                                label="Priorité" 
                                value={data.priority} 
                                onChange={value => preSetValue("priority", parseInt(value))} 
                                options={getPriorities()}
                                error={errors.priority}
                                wrapper={([label, input, fielderrors]) => <>
                                    <label style={{fontWeight: 300, fontSize: 12}}>{label}</label>
                                    <fieldset className="statuts">{input}</fieldset>
                                    {fielderrors}
                                </>}
                            />
                        </div>
                        <div className="w-auto cell-padding">
                            <FieldComponent
                                label="Pilote"
                                type="select"
                                name={"pilote-" + item.id}
                                className="field small"
                                wrapper={renderFieldWrap}
                                options={pilots}
                                onChange={value => preSetValue("pilot", value)}
                                value={data.pilot}
                                preview={!hasWriteAccess}
                            />
                        </div>
                    </>}
                    <div className="w-auto cell-padding col-checksingle-field">
                        {hasWriteAccess && <>
                            <FieldComponent
                                label="Masquage"
                                type="checksingle"
                                name={"hidden-" + item.id}
                                className="field"
                                checksingleLabel="Oui"
                                checked={data.hidden}
                                onChange={value => preSetValue("hidden", value)}
                                wrapper={([labelText, input,,, label]) => <>
                                    <label style={{fontWeight: 300, fontSize: 12}}>{labelText}</label>
                                    {input}{label}
                                </>}
                            />
                        </>}
                        {data.hidden === true && !hasWriteAccess && <>Exigence masqué</>}
                    </div>
                </div>
            </td>
            <td className="w-34px no-padding valign-center">
                <ul className="actions">
                    <li>
                        <Link to={prefixLinkTo() + "/requirements-affected/" + data.id} title="Voir l'exigence" target="_blank">
                            <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to={prefixLinkTo() + "/sheets-affected/" + data.sheet_affected_id} title="Voir la fiche" target="_blank">
                            <i className="icon-actions-traitement-mpv" aria-hidden="true"></i>
                        </Link>
                    </li> 
                    {hasActionAccess && <li>
                        <Link to={prefixLinkTo() + `/actions?requirement=${requirement.requirement.id}&watchsite=${item.watchsite_id}`} title={`Voir les actions (${data.nb_actions || 0})`}>
                            <i className="icon-actions-plan-action" aria-hidden="true"></i>
                            <span className="counter">{data.nb_actions || 0}</span>
                        </Link>
                    </li>}
                </ul>
            </td>
        </tr>
    );
}