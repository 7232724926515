import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ExpressProcessing from 'partials/ExpressProcessing';
import { mediumModalCustomStyles } from 'shared/services/ModalStyle';
import { prefixLinkTo } from "shared/services";

export default function WatchsiteAffectedListBatch(props) {

    const { selection, refresh, sheet } = props;
    const [expressProcessingOpened, setExpressProcessingOpened] = useState(false);

    function createAction() {
        if (selection.length === 0) {
            toast.warning("Veuillez sélectionnez les données sur lesquelles créer une action");
        } else {
            const params = {
                sheets: sheet.id,
                watchsites: _.map(selection, 'watchsite').join(','),
            };
            props.history.push(prefixLinkTo() + '/actions/new?' + (new URLSearchParams(params)).toString());
        }
    }

    return (<>
        <button type="button" onClick={() => setExpressProcessingOpened(true)} className="btn btn-primary h25">Traitement express</button>
        <button type="button" onClick={() => createAction()} className="btn btn-primary h25">Créer une action</button>        
        <Modal isOpen={expressProcessingOpened} onRequestClose={() => setExpressProcessingOpened(false)} style={mediumModalCustomStyles}>
            <ExpressProcessing
                selection={selection}
                onRequestClose={() => setExpressProcessingOpened(false)}
                onSuccess={() => {
                    setExpressProcessingOpened(false);
                    refresh();
                }}
            />
        </Modal>
    </>);
}