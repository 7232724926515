import { React, Link, toast, fileDownload, _, DefaultLayout, useState, useEffect, useContext } from 'commons';
import { useAccount } from 'hooks/Account';
import DropdownTreeSelect from "react-dropdown-tree-select";
import { useList, usePosition } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { AccountApi, ExternalUserApi, UserApi, InvitationApi, AccessApi } from 'shared/api';
import { FileUtils, convertCriteriesToSearchQuery, Can, denyIfCantPerform, preventDefault, formatDate, prefixLinkTo } from 'shared/services';
import { ListComponent, FilterComponent, FieldComponent, LoadButton } from 'shared/components';

export default function UserList(props) {

    denyIfCantPerform(props, "user:list");

    const [initializePosition, setPosition] = usePosition("user-list");
    const [account] = useAccount();

    const columns = [
        {id: 'email', title: 'Email'},
        {
            id: 'lastname+firstname', 
            title: 'Nom / Prénom',
            render: (row) => (<>{row.civility === "Mr" ? "Monsieur" : "Madame"} <strong>{row.lastname}</strong> {row.firstname}</>)
        },
        {
            id: 'roles',
            title: 'Profil',
            sortable: false,
            render: (row) => (
                <>
                    {row.roles.map(role => {
                        let output = "";
                        switch (role) {
                            case 'ROLE_ADMIN_CUSTOMER': output = (<p key={role}>Administrateur client</p>); break;
                            case 'ROLE_USER_CUSTOMER': output = (<p key={role}>Utilisateur simple</p>); break;
                            default: output = (<React.Fragment key={role}></React.Fragment>); break;
                        }
                        return output;
                    })}
                </>
            )
        },
        {id: 'last_connexion', title: 'Date de dernière connexion', format: 'datetime'},
        {id: 'invitation', title: 'Invitation', sortable: false, render: (row) => {            
            let validation = formatDate(row.validation_at, true);
            if (validation) {
                return <strong>Validé le {validation}</strong>;
            } else if (row.is_expired) {
                return <>Expiré le {formatDate(row.expire_at, true)}</>;
            } else if (Array.isArray(row.invitations) && row.invitations.length > 0) {
                let invitation = formatDate(row.invitations[row.invitations.length - 1], true);
                return (<>Invitation {row.invitations.length} / 4 du {invitation}</>);
            } else {
                let invitation = formatDate(row.invitation_at, true);
                return (<>Invité le {invitation}</>);
            }
        }},
        {id: 'active', title: 'Actif', className: 'text-center', format: 'checksingle', sortable: false},
        {
            id: 'has_accesses',
            title: 'Affecté à des points de veille',
            className: 'text-center',
            sortable: false,
            render: (row) => { 
                return (row.has_accesses || row.access_write === 1) ? (
                    <><i className="icon-ok-squared color-vert-apave" aria-hidden="true"></i><span className="sr-only">Oui</span></>
                ) : (
                    <><i className="icon-check-empty" aria-hidden="true"></i><span className="sr-only">Non</span></>
                );
            }
        },
        {
            id: 'action',
            title: '',
            sortable: false,
            render: (row) => {
                return (<ul className="actions">
                    <Can perform="user:read" data={row} yes={() => (<li><Link title="Modifier" to={prefixLinkTo() + `/users/${row.id}`}>
                        <i className="icon-actions-modifier" aria-hidden="true"></i>
                    </Link></li>)} />
                    <Can perform="user:write" data={row} yes={() => (<>{row.active === 1 && (<li><Link title="Désactiver" onClick={(e) => deactivateUser(e, row.user_id)} to={prefixLinkTo() + `/users/deactivate/${row.id}`}>
                        <i className="icon-actions-desactiver-utilisateur" aria-hidden="true"></i>
                    </Link></li>)}</>)} />
                    <Can perform="user:write" data={row} yes={() => (<>{_.isNil(row.validation_at) && (<li><Link title="Relancer l'invitation" onClick={(e) => invite(e, row.user_id)} to={prefixLinkTo() + `/users/invite/${row.id}`}>
                        <i className="icon-actions-relancer-utilisateur" aria-hidden="true"></i>
                    </Link></li>)}</>)} />
                </ul>)
            }
        }
    ];

    const filterType = {
        active: {type: "match", fields: ["active"]},
        keyword: {type: "wildcard", fields: ["email", "lastname", "firstname"]},
        roles: {type: "terms", fields: ["roles"]},
        account: {type: "match", fields: ["account_id"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh
    ] = useList("external-user-list", ExternalUserApi, null, "lastname+firstname", {}, "asc", 20, null, {account: account.id});

    const [tree, setTree] = useState([]);
    const [tree_data, setTreeData] = useState({nodes: [], watchsites: []});
    const [current_batch, setCurrentBatch] = useState();
    const [selection, setSelection] = useState([]);
    const [selected_access, setSelectedAccess] = useState("read");
    const [alert_activation, setAlertActivation] = useState("1");    
    const [selected_watchsite, setSelectedWatchsite] = useState("");
    const [selected_referential, setSelectedReferential] = useState("");
    const [referentialContext] = useContext(ReferentialContext);
    
    // referential
    const domainTree = referentialContext["tree"];

    useEffect(() => {
        submitSearch(filterType, () => initializePosition());

        AccountApi
            .getTree(account.id)
            .then(data => setTreeData(data))
            .catch(() => toast.error("Erreur durant la recherche des points de veilles assignables."));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const watchsiteMapper = (watchsite) => {
            return {
                "label": watchsite.label,
                "value": watchsite.id,
                "checked": (watchsite.id === parseInt(selected_watchsite)),
                "disabled": false,
            } 
        };
        const nodeMapper = (node) => {
            let children = node.childs.map(nodeMapper);
            children = children.concat(node.watchsites.map(watchsiteMapper));
            return {
                "label": node.label,
                "value": node.id,
                "children": children,
                "checked": false,
                "disabled": true,
            }
        };
        setTree(tree_data.nodes.map(nodeMapper).concat(tree_data.watchsites.map(watchsiteMapper)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tree_data, selected_watchsite])

    function deactivateUser(event, user) {
        event.preventDefault();

        UserApi
            .bulkUpdate([user], {"active": 0})
            .then(() => {
                toast.success("Utilisateur désactivé");
                refresh();
            })
            .catch(error => console.log(error));
    }

    function invite(event, user) {
        event.preventDefault();

        setLoading(true);

        InvitationApi
            .bulkCreate([user], "FO")
            .then(response => {
                toast.success(response.data.messages[0]);
                refresh();
            })
            .catch(error => { setLoading(false); toast.error(error.response.data.message) });
    }

    function exportUsers(event) {
        setLoading(true);
        event.preventDefault();
        ExternalUserApi
            .export(convertCriteriesToSearchQuery(criterias, filterType))
            .then(responseData => {
                setLoading(false);
                fileDownload(responseData, FileUtils.getFilePrefix() + "-Utilisateurs.xlsx");
            })
            .catch(() => {
                setLoading(false);
                toast.error("Erreur durant la création de l'export.")
            });
    }

    function updateSelection(newSelection) {
        setSelection(newSelection);
    }

    function getUserFromSelection() {
        let usersSelection = [];
        selection.forEach(useraccount => {
            var [user] = useraccount.toString().split('00');
            if (!usersSelection.includes(parseInt(user))) {
                usersSelection.push(parseInt(user));
            }
        });
        return usersSelection;
    }

    function changeBatch(event) {
        setCurrentBatch(event.target.value);
    }

    function executeBatch(event) {
        let users = getUserFromSelection();

        setLoading(true);
        if (current_batch === "assign_hotline_access") {
            UserApi
                .bulkUpdate(users, {"access_write_hotline": 1})
                .then(response => { setLoading(false); setSelection([]); toast.success("Utilisateurs modifiés"); })
                .catch(error => { setLoading(false); toast.error(error.response.data.message) });
        }
        if (current_batch === "assign_write_access") {
            UserApi
                .bulkUpdate(users, {"access_write": 1})
                .then(response => { setLoading(false); setSelection([]); toast.success("Utilisateurs modifiés"); })
                .catch(error => { setLoading(false); toast.error(error.response.data.message) });
        }
        if (current_batch === "assign_user_configuration_access") {
            UserApi
                .bulkUpdate(users, {"access_user_configuration": 1})
                .then(response => { setLoading(false); setSelection([]); toast.success("Utilisateurs modifiés"); })
                .catch(error => { setLoading(false); toast.error(error.response.data.message) });
        }
        if (current_batch === "configure_alert") {
            UserApi
                .bulkUpdate(users, {"alert_activation": parseInt(alert_activation)})
                .then(response => { setLoading(false); setSelection([]); toast.success("Utilisateurs modifiés"); })
                .catch(error => { setLoading(false); toast.error(error.response.data.message) });
        }
        if (current_batch === "configure_alert_domain") {
            UserApi
                .affectDomainAlert(users, selected_referential)
                .then(response => { setLoading(false); setSelection([]); toast.success("Paramétrage effectué"); })
                .catch(error => { setLoading(false); toast.error(error.response.data.message) });
        }
        if (current_batch === "invite") {
            InvitationApi
                .bulkCreate(users, "FO")
                .then(response => {
                    setLoading(false);
                    setSelection([]);
                    toast.success(<ul>{response.data.messages.map(m => <li key={m}>{m}</li>)}</ul>);
                    refresh();
                })
                .catch(error => { setLoading(false); toast.error(error.response.data.message) });
        }
        if (current_batch === "assign_watchsite") {
            AccessApi
                .bulkCreate({
                    account: account.id, 
                    users: users,
                    watchsite: selected_watchsite,
                    writeaccess: (selected_access === "write" ? 1 : 0),
                    readaccess: 1
                })
                .then(response => {
                    setLoading(false);
                    setSelection([]);
                    toast.success("Accès créés.");
                    refresh();
                })
                .catch(error => { 
                    setLoading(false);
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        Object.entries(error.response.data).forEach(([index, e]) => {
                            toast.error(e.message);
                        });                        
                    }
                });
        }
    }

    const batchActions = (
        <>
            <select id="batch-users" onChange={changeBatch}>
                <option></option>
                <option value="assign_hotline_access">Affecter le droit d'accès à la hotline en écriture</option>
                <option value="assign_write_access">Affecter le droit en écriture sur la veille</option>
                <option value="assign_user_configuration_access">Affecter le droit de configurer les utilisateurs</option>
                <option value="assign_watchsite">Affecter un point de veille</option>
                <option value="configure_alert">Configurer la réception des alertes</option>
                <option value="configure_alert_domain">Affecter un domaine / sous domaines / thème d'alerte</option>
                <option value="invite">Relancer l'invitation</option>
            </select>
            {current_batch === "assign_hotline_access" && <><button onClick={executeBatch} className="btn btn-primary h25" type="button">Configurer</button></>}
            {current_batch === "assign_write_access" && <><button onClick={executeBatch} className="btn btn-primary h25" type="button">Configurer</button></>}
            {current_batch === "assign_user_configuration_access" && <><button onClick={executeBatch} className="btn btn-primary h25" type="button">Configurer</button></>}
            {current_batch === "invite" && <><button onClick={executeBatch} className="btn btn-primary h25" type="button">Relancer l'invitation</button></>}
            {current_batch === "configure_alert" && <>
                <select value={alert_activation} onChange={e => setAlertActivation(e.target.value)}>
                    <option value="1">Recevoir les alertes</option>
                    <option value="0">Ne pas recevoir les alertes</option>
                </select>
                <button onClick={executeBatch} className="btn btn-primary h25" type="button">Configurer la réception des alertes</button>
            </>}
            {current_batch === "configure_alert_domain" && <>
                <div className="batch-dropdown-tree">
                    <FieldComponent 
                        type="dropdown-tree-select"
                        data={domainTree}   
                        wrap={false}
                        mode="radioSelect"
                        value={[selected_referential]}
                        onChange={v => setSelectedReferential(v)}                  
                    />
                </div>
                <button onClick={executeBatch} className="btn btn-primary h25" type="button">Affecter</button>
            </>}
            {current_batch === "assign_watchsite" && <>
                <select value={selected_access} onChange={e => setSelectedAccess(e.target.value)}>
                    <option value="read">Lecture</option>
                    <option value="write">Ecriture</option>
                </select>
                <div className="batch-dropdown-tree">
                    <DropdownTreeSelect
                        data={tree}
                        className="field"
                        mode="radioSelect"
                        onChange={option => setSelectedWatchsite(option.value)}
                    />
                </div>
                <button onClick={executeBatch} className="btn btn-primary h25" type="button">Assigner le point de veille</button>
            </>}
        </>
    )
    
    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E09" title="Liste des utilisateurs">
            <h1>Liste des utilisateurs</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    type="select"
                                    label="Utilisateur actif"
                                    name="active"
                                    onChange={value => addCriteria("active", value)}
                                    clearable={true}
                                    value={criterias.active}
                                    options={[
                                        {value: "1", label: "Oui"},
                                        {value: "0", label: "Non"},
                                    ]}
                                />
                                <FilterComponent
                                    label="Recherche libre"
                                    name="keyword"
                                    onChange={value => addCriteria("keyword", value)}
                                    value={criterias.keyword || ""}
                                />
                                <FilterComponent
                                    type="select"
                                    label="Profil"
                                    name="roles"
                                    onChange={value => addCriteria("roles", value)}
                                    clearable={true}
                                    value={criterias.roles}
                                    options={[
                                        {value: "ROLE_ADMIN_CUSTOMER", label: "Administrateur client"},
                                        {value: "ROLE_USER_CUSTOMER", label: "Utilisateur simple"},
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-user" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">                                    
                                    <LoadButton id="search-user" type="submit" label="Rechercher" loading={loading} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="users"
                        loading={loading}
                        selectable={true}
                        selection={selection}
                        onSelect={updateSelection}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        batchActions={batchActions}
                        globalActions={(
                            <>
                                <Link id="new-user" to={prefixLinkTo() + "/users/new"} className="btn btn-primary h25 icon">
                                    <i className="icon-boutons-creer-acces" aria-hidden="true"></i>&nbsp;Créer un utilisateur
                                </Link>
                                <button id="export-users" onClick={exportUsers} type="button" className="btn btn-primary h25 icon">
                                    <i className="icon-file-excel" aria-hidden="true"></i>&nbsp;Export
                                </button>
                            </>
                        )}
                    />
                </form>
            </div>

        </DefaultLayout>
    );
}