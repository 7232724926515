export default function StatusComponent(props) {

    const { value, options, size, color, border } = props;

    return (
        <ul className={["statut", size, "uppercase", (border ? "border" : "")].join(" ")}>
            {options.length === 0 && (
                <li className={"bg-" + color}>
                    {value}
                </li>
            )}
            {options.length > 0 && options.map((option, index) => 
                <li key={index} className={"bg-" + (value === option.value ?  option.color : color)}>
                    {option.title && <span title={option.title}>{option.label}</span>}
                    {!option.title && <>{option.label}</>}
                </li>
            )}
        </ul>
    );
}

StatusComponent.defaultProps = {
    options: [],
    color: "na",
    size: "large",
    border: false,
}