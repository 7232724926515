import React from 'react';
import DefaultLayout from "layouts/DefaultLayout";

export default function Denied() {

    return (
        <DefaultLayout title="Accès refusé">
            <h1>Accès refusé</h1>
            <p>Vous n'avez pas accès à cette ressource.</p>
        </DefaultLayout>
    );
}
