import React from 'react';
import _ from 'lodash';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import HtmlComponent from "shared/components/HtmlComponent";
import { useToggler } from "shared/hooks/Toggler";
import { formatDate } from 'shared/services/Utils';
import { actionLabel, typeLabel, fieldRenderer, fieldLabel } from 'data/Historic';

export default function HistoryDetail(props) {
    
    const { history, onRequestClose } = props;
    const [togglers, toggle] = useToggler({"info": true, "data": true});

    history.object_class = history.object_class.replace("Proxies\\__CG__\\", "");

    function formatValues(field, values) {
        if (field === "watchsite" && history.object_class === "App\\Entity\\SheetAffected") {
            return props.entity.watchsite.label;
        } else if (_.isArray(values)) {
            return values.map(v => formatValue(field, v)).join(", ");
        } else {
            return formatValue(field, values);
        }
    }

    function formatValue(field, value) {
        if (!_.isNil(fieldRenderer[history.object_class][field])) {
            if (_.isFunction(fieldRenderer[history.object_class][field])) {
                return fieldRenderer[history.object_class][field](value);
            } else {
                switch (fieldRenderer[history.object_class][field]) {
                    case "date": return formatDate(value);
                    case "bool": return value ? "Oui" : "Non";
                    default: return <HtmlComponent>{value}</HtmlComponent>;
                }
            }
        }

        return <HtmlComponent>{value}</HtmlComponent>;
    }

    return (
        <section>
            {history.object_class !== "App\\Entity\\SheetAffected" && <h1>Historique - {actionLabel[history.action]} {typeLabel[history.object_class]} {history.object_id}</h1>}
            {history.object_class === "App\\Entity\\SheetAffected" && <h1>Historique - {actionLabel[history.action]} {typeLabel[history.object_class]} {props.entity.sheet.id} - {props.entity.watchsite.label}</h1>}
            <ToggableBlocComponent label="Information" id="info" toggled={togglers["info"]} toggle={toggle} className="border-gris-25 border-lrb bg-gris-20">
                <div className="flex-label">
                    <label>Date :&nbsp;</label>
                    {formatDate(history.logged_at, "datetime")}
                </div>
                <div className="flex-label">
                    <label>Auteur :&nbsp;</label>
                    {history.username}
                </div>
            </ToggableBlocComponent>
            <ToggableBlocComponent label="Données" id="data" toggled={togglers["data"]} toggle={toggle} wrapChildren={false}>                
                <table className="table table-bordered table-striped">
                    <tr>
                        <th>Champs</th>
                        {history.action === "update" && <th>Ancienne valeur</th>}
                        <th>Nouvelle valeur</th>
                    </tr>
                    {Object.keys(history.data).map((field, i) => <>
                        {_.size(history.data[field]) > 0 && 
                            <tr key={i}>
                                <td>{_.isNil(fieldLabel[history.object_class][field]) ? field : fieldLabel[history.object_class][field]}</td>
                                {history.action === "update" && <td>{formatValues(field, history.data[field].old)}</td>}
                                <td>{formatValues(field, history.data[field].new)}</td>
                            </tr>
                        }</>
                    )}
                </table>
            </ToggableBlocComponent>
            <section className="row">
                <div className="col">
                    <button id="close-history-detail" onClick={onRequestClose} type="button" className="btn btn-bleu-4">Fermer</button>
                </div>
            </section>
        </section>
    )
}