import { React } from 'commons';
import { useToggler } from "shared/hooks";
import { ToggableBlocComponent } from "shared/components"
import DocumentarySpaceDetailCommonRows from "views/documentaryspace/Detail/CommonRows";
import DocumentarySpaceDetailRow from "views/documentaryspace/Detail/Row";

export default function DocumentarySpaceDetailCaseLaw(props) {

    const [togglers, toggle] = useToggler({"document": true});

    return <form className="form">
        <section>
            <h1>{props.document.title}</h1>
            <ToggableBlocComponent label="Document" id="document" toggled={togglers["document"]} toggle={toggle} className="border-gris-25 border-lrb">
                <DocumentarySpaceDetailCommonRows document={props.document} />
                {props.document.jurisdiction && <DocumentarySpaceDetailRow label="Juridiction" value={props.document.jurisdiction} type="text" />}
                <DocumentarySpaceDetailRow label="Décision" value={props.document.decision} type="file" />
                <DocumentarySpaceDetailRow label="Date de la décision" value={props.document.decision_date} type="date" />
                <DocumentarySpaceDetailRow label="Numéro de pourvoi" value={props.document.appeal_number} />
                {props.document.synthesis && <DocumentarySpaceDetailRow label="Synthèse" value={props.document.synthesis} type="rte" />}
                {props.document.teaching && <DocumentarySpaceDetailRow label="Enseignement" value={props.document.teaching} type="rte" />}
            </ToggableBlocComponent>
            <section className="row">
                <div className="col text-right">
                    <button id="close-detail" onClick={props.onRequestClose} className="btn btn-bleu-4">Fermer</button>
                </div>
            </section>
        </section>
    </form>;
}