import React from 'react';
import DefaultLayout from "layouts/DefaultLayout";

export default function Cgu() {

    return (
        <DefaultLayout title="Conditions générales d'utilisation">
            <h1>Conditions générales d'utilisation</h1>
            <h2>Article 1</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit amet auctor arcu. Aenean ut sapien ipsum. Duis fringilla eget velit nec consectetur. Fusce diam elit, dignissim nec malesuada nec, consequat eu purus. Curabitur imperdiet erat a maximus iaculis. In lobortis orci vulputate enim vehicula, ut commodo ipsum faucibus. Vestibulum non sagittis dui. Donec porttitor sem et commodo congue. Aliquam malesuada quis ipsum a maximus.</p>
            <p>Vivamus id vestibulum mauris. Suspendisse nulla elit, porttitor ut euismod nec, suscipit quis tortor. Aliquam tristique massa at nisl tincidunt ultricies. Quisque sed vulputate risus, eu molestie erat. Donec sed egestas purus. Donec quis mi rutrum lacus convallis feugiat. Aliquam nisi eros, sollicitudin quis arcu et, elementum faucibus sem. Nulla posuere risus quis quam efficitur posuere. Suspendisse pretium et nisi et feugiat.</p>
            <h2>Article 2</h2>
            <p>Cras at arcu varius, tincidunt erat eget, ornare justo. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse at porttitor magna, non commodo risus. Praesent nec nisi mattis, auctor turpis et, pharetra lacus. Aliquam eleifend magna ut aliquet ornare. Duis iaculis dui at odio condimentum interdum. Nullam quis dolor urna. Praesent ac dignissim dolor. Duis vitae arcu eget nunc volutpat euismod. Proin ut ex nec orci bibendum porttitor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec viverra, neque ut tempor accumsan, mi ligula cursus libero, a condimentum sem urna id arcu.</p>
            <h3>Article 2.1</h3>
            <p>In hac habitasse platea dictumst. Fusce sagittis auctor iaculis. Nunc porta tempor velit ut gravida. Nunc iaculis dolor vitae lacus porttitor, quis vestibulum eros scelerisque. Curabitur dignissim accumsan dolor, ut tempus tellus placerat nec. Ut non felis ut libero consequat sodales. Nunc viverra efficitur urna lacinia ultricies. Quisque aliquam arcu lectus, quis elementum urna laoreet vitae. Curabitur tincidunt sit amet elit eu sodales. In nec dolor vulputate, suscipit sapien ut, fermentum ex. Curabitur vel mattis est. Suspendisse potenti. Phasellus eu laoreet metus. Maecenas nec nisi et sem blandit semper eget sit amet risus. In sollicitudin elit id ligula tempus dapibus.</p>
            <h3>Article 2.2</h3>
            <p>Aliquam rhoncus ligula sit amet velit sagittis tempor. Cras posuere arcu sed placerat blandit. Aliquam erat volutpat. Suspendisse varius laoreet aliquet. Ut ut orci egestas, dictum urna interdum, lobortis lorem. Curabitur ornare condimentum lacus non ultricies. Nam eget mollis arcu. Suspendisse facilisis neque id fermentum tempus. Integer condimentum a dolor nec mollis. Proin varius ex vel efficitur ultrices. </p>
        </DefaultLayout>
    );
}
