import Modal from 'react-modal';
import { mediumModalBorderlessCustomStyles } from 'shared/services/ModalStyle';
import CommentsComponent from 'components/CommentsComponent';

export default function WatchsiteAffectedCommentsModal(props) {

    const { show, onClose, onChange, values, commentActions, onCommentActions, watchsite } = props;

    return (
        <Modal isOpen={show} onRequestClose={onClose} style={mediumModalBorderlessCustomStyles}>
            <section className="bloc" style={{marginBottom: 0}}>
                <header><h2 className="uppercase">Commentaire(s) {watchsite.label}</h2></header>
                <CommentsComponent
                    value={values}
                    onChange={onChange}
                    actions={commentActions}
                    onAction={onCommentActions}
                    watchsite={watchsite}
                    errors={[]}
                />
                <button type="button" className="btn btn-bleu-4" id="close-comments" onClick={onClose}>Fermer</button>
            </section>
        </Modal>
    );
}