import _ from 'lodash';
import StatisticsChartItemBlock from 'views/statistics/list/ChartItemBlock';

export default function ConformityStatisticsChartItem(props) {

    const { row } = props;
    const conformity_new = row.total_by_status["new"];
    const conformity_info = row.total_by_status["info"];
    const conformity_na = row.total_by_status["na"];
    const conformity_tospecified = row.total_by_status["tospecified"];
    const conformity_nok = row.total_by_status["nok"];
    const conformity_ok = row.total_by_status["ok"];
    const conformity_new_percentage = row.percentage_by_status["new"];
    const conformity_info_percentage = row.percentage_by_status["info"];
    const conformity_na_percentage = row.percentage_by_status["na"];
    const conformity_tospecified_percentage = row.percentage_by_status["tospecified"];
    const conformity_nok_percentage = row.percentage_by_status["nok"];
    const conformity_ok_percentage = row.percentage_by_status["ok"];

    return (
        <div className="chart-item row">
            <div className="chart-label-container col-md-2 text-right">
                <label className="chart-label">
                {!_.isNil(row.group_label) ? (<>
                    {row.group_label}
                </>) : (
                    "Non catégorisé"
                )}
                </label>
            </div>
            <div className="chart-data-container col-md-10">
                <StatisticsChartItemBlock
                    title="Nouveau"
                    value={conformity_new}
                    percentage={conformity_new_percentage}
                    bgClass="bg-conformity-new"
                />
                <StatisticsChartItemBlock
                    title="A préciser"
                    value={conformity_tospecified}
                    percentage={conformity_tospecified_percentage}
                    bgClass="bg-conformity-tospecified"
                />
                <StatisticsChartItemBlock
                    title="En écart"
                    value={conformity_nok}
                    percentage={conformity_nok_percentage}
                    bgClass="bg-conformity-nok"
                />
                <StatisticsChartItemBlock
                    title="Conforme"
                    value={conformity_ok}
                    percentage={conformity_ok_percentage}
                    bgClass="bg-conformity-ok"
                />
                <StatisticsChartItemBlock
                    title="Pour information"
                    value={conformity_info}
                    percentage={conformity_info_percentage}
                    bgClass="bg-conformity-info"
                />
                <StatisticsChartItemBlock
                    title="Non applicable"
                    value={conformity_na}
                    percentage={conformity_na_percentage}
                    bgClass="bg-conformity-na"
                />
            </div>
        </div>
    );
}