import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PieChart } from 'react-minimal-pie-chart';
import ReactTooltip from 'react-tooltip';
import BarLoader from "react-spinners/BarLoader";
import SheetAffectedApi from 'shared/api/SheetAffected';
import { prefixLinkTo } from 'shared/services';

export default function ConformityChart() {

    const [loading, setLoading] = useState(true);
    const [indicator, setIndicator] = useState({});

    function load() {
        SheetAffectedApi
            .searchIndicators(
                {
                    "bool": {"must":[
                        {"match": {"hidden": false}}
                    ]}
                }
            )
            .then(data => {
                setLoading(false);
                setIndicator(data.conformity)
            })
            .catch(() => setLoading(false));
    }

    useEffect(load, []);

    return (
        <div className="contenu padding conformitychart">
			<h2><i className="icon-accueil-conformite" aria-hidden="true"></i>Statistique</h2>
            <BarLoader loading={loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
            {!loading && <>
                <p className="text-center">
                    <div data-tip data-for="chart-conformity">
                        <PieChart
                            data={[
                                { title: 'Nouveau', value: indicator.new, color: '#45BFBF' },
                                { title: 'A préciser', value: indicator.tospecified, color: '#FFB607' },
                                { title: 'En écart', value: indicator.nok, color: '#F52824' },
                                { title: 'Conforme', value: indicator.ok, color: '#A9D009' },
                            ]}
                            labelPosition={120}
                            viewBoxSize={[120, 120]}
                            center={[60, 60]}
                            animate={true}
                            style={{ height: '231px', width: '325px' }}
                        />
                    </div>
                </p>
                <ReactTooltip id="chart-conformity" place="left" type="dark" effect="float">
                    <strong>Détail conformité des points de veilles</strong>
                    <ul>
                        {indicator.new > 0 && <li>Nouveau <span className="color-conformity-new">{indicator.new} %</span></li>}
                        {indicator.tospecified > 0 && <li>A préciser <span className="color-conformity-tospecified">{indicator.tospecified} %</span></li>}
                        {indicator.nok > 0 && <li>En écart <span className="color-conformity-nok">{indicator.nok} %</span></li>}
                        {indicator.ok > 0 && <li>Conforme <span className="color-conformity-ok">{indicator.ok} %</span></li>}
                    </ul>
                </ReactTooltip>
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <ul className="progress-legend">
                            {indicator.new > 0 && <li><span className="bg-conformity-new"></span>Nouveau - {indicator.new} %</li>}
                            {indicator.tospecified > 0 && <li><span className="bg-conformity-tospecified"></span>A préciser - {indicator.tospecified} %</li>}
                            {indicator.nok > 0 && <li><span className="bg-conformity-nok"></span>En écart - {indicator.nok} %</li>}
                            {indicator.ok > 0 && <li><span className="bg-conformity-ok"></span>Conforme - {indicator.ok} %</li>}
                        </ul>
                    </div>
                </div>
            </>}
			<div className="row">
				<div className="col-md-12 text-center">
                    <Link to={prefixLinkTo() + "/sheets-affected"} className="btn btn-primary icon h35"><i className="icon-boutons-lien-vers" aria-hidden="true"></i>&nbsp;Gérer ma conformité</Link>
				</div>
			</div>
		</div>
    );
}
