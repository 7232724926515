import _ from 'lodash';

export function compileErrors(errorsList) {
    let errors = {};
    Object.entries(errorsList).forEach(([index, error]) => {
        errors = Object.assign({}, errors, {[error.property_path]: error.message});
    });
    return errors;
}

export function compileErrorsFromResponse(response) {
    return compileErrors(response.data);
}

function compileLabel(object, field, separator = ' ') {
    if (!_.isArray(field)) {
        field = [field];
    }
    const fields = field.map(element => {
        let field = element;
        let label = "";
        const isObject = _.isObject(element);
        if (isObject) {
            field = element.field;  
        }
        if (field.includes('.')) {
            const parts = field.split('.');
            let result = object;
            for (var p in parts) {
                if (!result[parts[p]]) {
                    break;
                }
                result = result[parts[p]];
            }
            label = result;
        } else {
            label = object[field];
        }
        if (isObject) {
            label = element.render(label);  
        }
        return label;
    });
    return stripTags(fields.join(separator));
}

export function compileDataToSelectOption(data, valueField = 'id', labelField = 'name', separator = ' ') {
    return {
        "value": deepFind(data, valueField), 
        "label": compileLabel(data, labelField, separator),
    };
}

export function compileDataToSelectOptions(data, valueField = 'id', labelField = 'name', separator = ' ') {
    let dataArray = [];
    data.forEach(function(item) {
        dataArray.push(
            compileDataToSelectOption(item, valueField, labelField, separator)
        );
    });
    return dataArray;
}

export function boolToInt(bool) {
    return (bool) ? 1 : 0;
}

export function nl2br(text) {
    if(text.length > 0) {
        return text.split('\n').map(function(item, key) {
            return (
                <span key={key}>
                    {item}
                    <br/>
                </span>
            )
        });
    }
    return '';
}

export function formatDate(dateTime, showTime, locale = "fr-fr") {
    let date = new Date(dateTime);
    if (date.toString() !== "Invalid Date") {
        const dateFormatted = date.toLocaleDateString(locale);
        if (!showTime) {
            return dateFormatted;
        }
        return dateFormatted + " " + date.toLocaleTimeString(locale);
    } else {
        return null;
    }
}

export function formatUser(user) {
    if (!user) {
        return null;
    }
    return _.capitalize(user.firstname) + " " + _.toUpper(user.lastname);
}

export function isExpired(date) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
}

export function validateUrl(string) {
    let url;
    
    try {
        url = new URL(string);
    } catch (e) {
        return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function uniqid(a = "", b = false) {
    const c = Date.now()/1000;
    let d = c.toString(16).split(".").join("");
    while (d.length < 14) d += "0";
    let e = "";
    if (b) {
        e = ".";
        e += Math.round(Math.random()*100000000);
    }
    return a + d + e;
}

export function deepFind(obj, path) {
    var paths = path.split('.')
      , current = obj
      , i;
  
    for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined) {
            return undefined;
        } else {
            current = current[paths[i]];
        }
    }
    return current;
}

export function padNumber(number, length = 6, padChar = '0') {
    if (!number) {
        return null;
    }
    return (number + '').padStart(length, padChar);
}

export function stripTags(string) {
    return string.replace(/<\/?[^>]+(>|$)/g, "");
}

export function crop(string, size) {
    string = string.split("\n").join("|");
    if (string.length <= size) {
        return string.split("|").join("\n");
    }
    return string.substr(0, Math.min(string.substr(0, size).length, string.substr(0, size).lastIndexOf(" "))).split("|").join("\n");
}

export function stripTagsAndCrop(string, size) {
    return crop(stripTags(string), size);
}

export function toggleArrayValue(array, value) {
    array = [...array];
    if (array.includes(value)) {
        array.splice(array.indexOf(value), 1);
    } else {
        array.push(value);
    }
    return array;
}

export function preventDefault(event, callback) {
    event.preventDefault();
    if (callback) callback();
}

export function updateFormFilterRangeValues(criterias, rangeCriteriasKeys) {
    rangeCriteriasKeys.forEach((key) => {
        if (key in criterias) {
            if (!_.isNil(criterias[key]['start'])) {
                let dateStart = new Date(criterias[key]['start']);
                dateStart.setHours(0, 0, 0);
                criterias[key]['start'] = dateStart.toISOString();
            }
            if (!_.isNil(criterias[key]['end'])) {
                let dateEnd = new Date(criterias[key]['end']);
                dateEnd.setHours(23, 59, 59);
                criterias[key]['end'] = dateEnd.toISOString();
            }
        }
    });
    return criterias;
}