import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DefaultLayout from "layouts/DefaultLayout";
import { denyIfCantPerform } from 'shared/services/Can';
import { prefixLinkTo } from 'shared/services';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import { useToggler } from "shared/hooks/Toggler";
import WatchsiteApi from 'shared/api/Watchsite';
import WatchsiteUsersList from 'views/tree/watchsite/WatchsiteUsersList';

export default function Watchsite(props) {

    denyIfCantPerform(props, "tree:read");

    const [togglers, toggle] = useToggler({'watchsite-info': true, 'watchsite-users': true});

    const [watchsite, setWatchsite] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.match.params.id) {
            WatchsiteApi
                .get(props.match.params.id)
                .then(watchsite => {
                    setWatchsite(watchsite);
                    setLoading(false);                    
                })
                .catch(error => console.log(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (loading) {
        return <PageLoadingComponent label="Configuration des utilisateurs du point de veille" />
    } else {
        return (
            <DefaultLayout title="Configuration des utilisateurs du point de veille">
                <section>
                    <h1>Configuration des utilisateurs du point de veille</h1>
                    <ToggableBlocComponent label="Informations" id="watchsite-info" toggled={togglers["watchsite-info"]} toggle={toggle}>
                        <form className="form">
                            <div className="flex-label">
                                <label>Point de veille</label>
                                {watchsite.label}
                            </div>
                        </form>
                    </ToggableBlocComponent>
                    <ToggableBlocComponent label="Configuration des utilisateurs" id="watchsite-users" toggled={togglers["watchsite-users"]} toggle={toggle} wrapChildren={false}>
                        <WatchsiteUsersList watchsite={watchsite.id} parent={watchsite.node} />
                    </ToggableBlocComponent>
                    <div className="row">
                        <div className="col">
                            <Link id="back-tree" to={prefixLinkTo() + "/tree"} className="btn btn-bleu-4">Retour à l'arborescence</Link>
                        </div>
                    </div>
                </section>
            </DefaultLayout>
        );
    }
    
}