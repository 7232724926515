import React, { useState } from 'react';
import { toast } from 'react-toastify';
import LoadButton from 'shared/components/LoadButton';

import { useForm } from 'shared/hooks/Form';
import { useAccount } from 'hooks/Account';

import { compileErrorsFromResponse } from 'shared/services/Utils';
import ExternalUserAutocomplete from 'shared/services/autocomplete/ExternalUser';
import AccessApi from 'shared/api/Access';

import FieldComponent from 'shared/components/FieldComponent';

export default function WatchsiteUserAssignment(props) {

    const [loadingAssignment, setLoadingAssignment] = useState(false);
    const [account] = useAccount();
    const [data, errors, setErrors, setValue] = useForm({
        user: null,
        access: "write"
    });

    function assignUser() {
        setLoadingAssignment(true);
        AccessApi
            .create({
                user: data.user,
                account: account.id,
                watchsite: props.watchsite,
                writeaccess: (data.access === "write") ? 1 : 0,
                readaccess: 1
            })
            .then(response => {
                setLoadingAssignment(false);
                toast.success("Utilisateur affecté");
                props.onAssigment();
            })
            .catch(error => {
                setLoadingAssignment(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            });
    }

    return (
        <form className="form">
            <section>
                <header className="uppercase bg-gris-45">
                    Affectation d'un utilisateur
                </header>
                <div className="border-gris-25 border-lrb">                    
                    <div className="row">
                        <div className="col-md-8">
                            <FieldComponent
                                prefixId="watchsite-assignuser-"
                                type="async-select"
                                name="user"
                                label="Utilisateur"
                                error={errors.user}
                                onChange={value => setValue("user", value)}
                                loadOptions={(value, callback) => ExternalUserAutocomplete({keyword: value, account:account.id}, callback)}
                            />
                            <FieldComponent
                                prefixId="watchsite-assignuser-"
                                type="radio"
                                name="access"
                                label="Accès"
                                error={errors.access}
                                value={data.access}
                                onChange={value => setValue("access", value)}
                                options={[
                                    {"value": "write", "label": "Ecriture"},
                                    {"value": "read", "label": "Lecture"}
                                ]}
                            />
                        </div>
                        <div className="col-md-4">
                            <LoadButton 
                                loading={loadingAssignment}
                                label="Affecter l'utilisateur"
                                iconClass="icon-boutons-transferer"
                                onClick={assignUser}
                                id="watchsite-assignuser"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </form>
    );
}