import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import LoadButton from 'shared/components/LoadButton';
import ActionApi from 'api/Action';
import { ACTION_STATUS_CLOSED, ACTION_STATUS_COMPLETED, getActionsStatus } from 'data/Action';
import { CONFORMITY_MODE_GLOBAL, CONFORMITY_OK } from 'data/SheetAffected';
import confirm from 'shared/services/Confirmation';

export default function ActionListBatch(props) {

    const [running, setRunning] = useState(false);
    const [batch, setBatch] = useState();
    const [status, setStatus] = useState();
    const [achievement_date, setAchievementDate] = useState();
    const [pilot, setPilot] = useState();

    function change(event) {
        setBatch(event.target.value);  
    }

    function hasSelection() {
        if (props.selection.length > 0) {
            return true;
        } else {
            toast.error("Aucune sélection");
            return false;
        }
    }

    function hide() {
        if (hasSelection()) {
            setRunning(true);
            ActionApi
                .bulkUpdate(props.selection, {hidden: 1})
                .then((response) => batchSuccess());
        }
    }

    function unhide() {
        if (hasSelection()) {
            setRunning(true);
            ActionApi
                .bulkUpdate(props.selection, {hidden: 0})
                .then((response) => batchSuccess());
        }
    }

    function updateStatus() {
        if (status) {
            if (hasSelection()) {
                setRunning(true);

                let conformityValidation = false;
                let message = "";
                let checkedRequirements = [];
                let checkedSheets = [];
                if (parseInt(status) === ACTION_STATUS_CLOSED || parseInt(status) === ACTION_STATUS_COMPLETED) {
                    props.rows.forEach((row) => {
                        if (props.selection.includes(row.id)) {
                            if (row.requirements_affected
                                && row.requirements_affected.length > 0                                 
                            ) {
                                conformityValidation = true;
                                row.requirements_affected.forEach(ra => {
                                    if (!checkedRequirements.includes(ra.id)) {
                                        message = message + "<li>Exigence " + ra.requirement.id + " pour " + row.watchsite.label + "</li>";
                                        checkedRequirements.push(ra.id);
                                    }
                                });
                            } else if (row.sheet_affected 
                                && row.sheet_affected.conformity_mode === CONFORMITY_MODE_GLOBAL
                                && row.sheet_affected.equivalent_conformity !== CONFORMITY_OK
                                && !checkedSheets.includes(row.sheet_affected.id)
                            ) {
                                conformityValidation = true;
                                message = message + "<li>Fiche " + row.sheet_affected.sheet.id + " pour " + row.watchsite.label + "</li>";
                                checkedSheets.push(row.sheet_affected.id);
                            }
                        }                            
                    });
                }
                
                if (conformityValidation) {
                    confirm(
                        "Plus aucune action n'est en cours pour la ou les fiche(s) et exigence(s) suivante(s) : <ul>" + message + "</ul> Souhaitez-vous passer cette ou ces fiches au statut \"Conforme\" ?",
                        () => {
                            ActionApi.bulkUpdate(props.selection, {status: parseInt(status), update_conformity: true}).then((response) => batchSuccess());
                        },
                        () => { 
                            ActionApi.bulkUpdate(props.selection, {status: parseInt(status), update_conformity: false}).then((response) => batchSuccess());
                        }
                    );
                } else {
                    ActionApi.bulkUpdate(props.selection, {status: parseInt(status), update_conformity: false}).then((response) => batchSuccess());
                }

            }
        } else {
            toast.error("Veuillez indiquer un status");
        }
        
    }

    function affectPilot() {
        if (pilot) {
            if (hasSelection()) {
                setRunning(true);
                ActionApi
                    .bulkUpdate(props.selection, {pilot: pilot})
                    .then((response) => batchSuccess());
            }
        } else {
            toast.error("Veuillez indiquer un pilote");
        }
    }

    function affectAchievementDate() {
        if (achievement_date) {
            if (hasSelection()) {
                setRunning(true);
                ActionApi
                    .bulkUpdate(props.selection, {achievement_date: format(achievement_date, 'yyyy-MM-dd')})
                    .then((response) => batchSuccess());
            }
        } else {
            toast.error("Veuillez indiquer une date");
        }
    }

    function batchSuccess() {
        setRunning(false);        
        toast.success("Mise à jour effectué");
        props.cleanSelection();
        props.afterUpdate();
    }

    return (
        <>
            <select id="batch-actions" onChange={change}>
                <option></option>
                <option value="unhide">Démasquer</option>
                <option value="hide">Masquer</option>
                <option value="update_status">Changer le statut</option>
                <option value="affect_pilot">Affecter à un pilote</option>
                <option value="affect_achievement_date">Affecter une date de réalisation</option>
            </select>
            {batch === "unhide" && <>
                <LoadButton id="submit_batch_action_unhide" loading={running} onClick={unhide} className="btn btn-primary h25" label="Configurer" />
            </>}
            {batch === "hide" && <>
                <LoadButton id="submit_batch_action_hide" loading={running} onClick={hide} className="btn btn-primary h25" label="Configurer" />
            </>}
            {batch === "update_status" && <>
                <select id="batch-actions-status" onChange={(e) => setStatus(e.target.value)}>
                    <option></option>
                    {getActionsStatus().map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
                </select>
                <LoadButton id="submit_batch_action_update_status" loading={running} onClick={updateStatus} className="btn btn-primary h25" label="Configurer" />
            </>}
            {batch === "affect_pilot" && <>
                <select id="batch-actions-pilots" onChange={(e) => setPilot(e.target.value)}>
                    <option></option>
                    {props.pilots.map((pilotOption) => <option key={pilotOption.value} value={pilotOption.value}>{pilotOption.label}</option>)}
                </select>
                <LoadButton id="submit_batch_action_affect_pilot" loading={running} onClick={affectPilot} className="btn btn-primary h25" label="Configurer" />
            </>}
            {batch === "affect_achievement_date" && <>
                <DatePicker
                    id="batch-actions-achievement_date"
                    locale={fr}
                    onChange={v => setAchievementDate(v)}
                    selected={achievement_date}
                    dateFormat={"dd/MM/yyyy"}
                    placeholderText="dd/mm/yyyy"
                />            
                <LoadButton id="submit_batch_action_affect_achievement_date" loading={running} onClick={affectAchievementDate} className="btn btn-primary h25" label="Configurer" />
            </>}
        </>
    );
}