import { React, DefaultLayout, useState, useContext, _ } from 'commons';
import { useAccount } from 'hooks/Account';
import { ReferentialContext } from 'shared/stores/Referential';
import { Can, convertCriteriesToSearchQuery, denyIfCantPerform, canPerform, toggleArrayValue } from 'shared/services';
import { FilterComponent } from 'shared/components';
import DocumentarySpaceResultSection from 'views/documentaryspace/Result/Section';

export default function DocumentarySpaceSearch(props) {

    let  defaultCriterias = {type: [], keyword_fields: ["title", "description"]};
    if (localStorage.getItem("documentaryspace-criterias")) {
        defaultCriterias = JSON.parse(localStorage.getItem("documentaryspace-criterias"));
    }

    const [referentialContext] = useContext(ReferentialContext);
    const [account] = useAccount();
    const [criterias, setCriterias] = useState(defaultCriterias);
    const [enableTypes, setEnableTypes] = useState([]);
    const [query, setQuery] = useState({});
    const [queryNews, setQueryNews] = useState({});
    const domainTree = _.cloneDeep(referentialContext["tree"]);

    denyIfCantPerform(props, "account:documentary_space", {account: account});

    const optionsTypes = [
        {value: 'caselaw', label: 'Jurisprudence'},
        {value: 'drafttext', label: 'Projet de texte'},
        {value: 'form', label: 'Formulaires et déclaration'},
        {value: 'news', label: 'Actualité'},
        {value: 'newsflash', label: 'Flash d\'information'},
        {value: 'newsletter', label: 'Newsletter'},
        {value: 'regulatorycontrol', label: 'Contrôles réglementaires'},
        {value: 'thematicfile', label: 'Dossiers et fiches thématiques'},
    ];
    if (canPerform("account:private_documentary_space", {account: account})) {
        optionsTypes.push(
            {value: 'clientspecificmodule', label: 'Documents spécifiques ' + account.name}
        );        
    }

    const filterType = {
        date: {type: "range", fields: ["date"]},
        domains: {type: "terms", fields: ["domain", "subdomain", "theme"]},
        keyword: {type: "wildcard", fields: criterias.keyword_fields.filter(field => field !== "description")},
    };
    const filterTypeNews = {
        date: {type: "range", fields: ["date"]},
        domains: {type: "terms", fields: ["domain", "subdomain", "theme"]},
        keyword: {type: "wildcard", fields: criterias.keyword_fields},
    }

    function refresh() {
        setCriterias({type: [], keyword_fields: ["title", "description"]});
        submitSearch();
    }

    function submitSearch(event) {
        if (event) {
            event.preventDefault();
        }
        localStorage.setItem("documentaryspace-criterias", JSON.stringify(criterias));
        setQuery(convertCriteriesToSearchQuery(criterias, filterType));
        setQueryNews(convertCriteriesToSearchQuery(criterias, filterTypeNews));
        setEnableTypes(criterias.type);
    }

    function addCriteria(criteria, value) {
        setCriterias(Object.assign({}, criterias, {[criteria]: value}));
    }

    function isEnabled(type) {
        return enableTypes.length === 0 || enableTypes.includes(type);
    }

    return <DefaultLayout screen="E49" title="Espace documentaire">
        <h1>Espace documentaire</h1>
        <div className="bloc">
            <form className="form" onSubmit={submitSearch}>
                <section className="bloc filters">
                    <header>
                        <h2><button className="toggler" type="button">Recherche</button></h2>
                    </header>
                    <div className="bg-gris-25">
                        <div className="row">
                            <FilterComponent
                                label="Date"
                                name="date"
                                type="daterange"
                                value={criterias.date}
                                onChange={value => addCriteria("date", value)}
                            />
                            <FilterComponent
                                type="select"
                                label="Type de document"
                                name="type"
                                value={criterias.type}
                                onChange={value => addCriteria("type", value)}
                                clearable
                                multiple
                                options={optionsTypes}
                            />
                            <FilterComponent
                                label="Domaines / Sous domaines / Thèmes"
                                name="domains"
                                type="dropdown-tree-select"
                                onChange={value => addCriteria("domains", value)}
                                value={criterias.domains}
                                data={domainTree}
                                mode="hierarchical"
                            />
                            <FilterComponent 
                                type="keyword"
                                name="keyword"
                                label="Recherche libre" 
                                fields={[
                                    {value: 'title', label: 'Titre'},
                                    {value: 'description', label: 'Description'},
                                    {value: 'attachments', label: 'PDF joints'},
                                ]}
                                selectedFields={criterias.keyword_fields}
                                onChange={value => addCriteria("keyword", value)}
                                onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(criterias.keyword_fields, value))}
                                value={criterias.keyword || ""} 
                            />
                        </div>
                    </div>
                    <div className="bg-gris-25 border-b border-gris-60">
                        <div className="row">
                            <div className="col-md-9">
                                <button id="clearfilter-documentaryspace" onClick={() => refresh()} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                            </div>
                            <div className="col-md-3 text-right">
                                <button id="search-documentaryspace" type="submit" className="btn btn-primary">Rechercher</button>
                            </div>
                        </div>
                    </div>
                </section>
                {isEnabled('caselaw') &&
                    <DocumentarySpaceResultSection type="caselaw" query={query} />}
                <Can perform="account:private_documentary_space" data={{account: account}} yes={() => (<>
                    {isEnabled('clientspecificmodule') &&
                        <DocumentarySpaceResultSection type="clientspecificmodule" query={query} />}
                </>)} />
                {isEnabled('drafttext') &&
                    <DocumentarySpaceResultSection type="drafttext" query={query} />}
                {isEnabled('form') &&
                    <DocumentarySpaceResultSection type="form" query={query} />}
                {isEnabled('news') &&
                    <DocumentarySpaceResultSection type="news" query={queryNews} />}
                {isEnabled('newsflash') &&
                    <DocumentarySpaceResultSection type="newsflash" query={query} />}
                {isEnabled('newsletter') &&
                    <DocumentarySpaceResultSection type="newsletter" query={query} />}
                {isEnabled('regulatorycontrol') &&
                    <DocumentarySpaceResultSection type="regulatorycontrol" query={query} />}
                {isEnabled('regulatoryflowchart') &&
                    <DocumentarySpaceResultSection type="regulatoryflowchart" query={query} />}
                {isEnabled('thematicfile') &&
                    <DocumentarySpaceResultSection type="thematicfile" query={query} />}
            </form>
        </div>
    </DefaultLayout>;
}