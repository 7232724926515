import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import FieldComponent from 'shared/components/FieldComponent';
import ToggableTextComponent from 'shared/components/ToggableTextComponent';
import { formatDate } from 'shared/services/Utils';
import { useForm } from "shared/hooks/Form";
import { useAccess } from "hooks/Access";
import { getConformityModes, getConformities, CONFORMITY_MODE_REQUIREMENT, CONFORMITY_MODE_GLOBAL, CONFORMITY_NEW } from 'data/SheetAffected';
import { getPriorities } from 'data/Priority';
import WatchsiteAffectedCommentsModal from './CommentsModal';
import { prefixLinkTo } from 'shared/services';

export default function WatchsiteAffectedListItem(props) {

    const { item, drawSelectable, onCommentActions, commentActions, onAffectationChange, columns, columnAmount, hasCustomerCommentAccess } = props;

    const isAffected = item.sheet_affected;
    const sheetAffected = item.sheet_affected;
    const watchsite = item.watchsite;
    const [canWrite] = useAccess();
    const hasWriteAccess = canWrite(watchsite.id);

    const [data,,, setValue, setData] = useForm({
        id: null,
        evaluation_date: null,
        review_date: null,
        conformity_mode: -1,
        conformity: -1,
        priority: 0,
        hidden: false,
        comments: [],
    });

    const [showComments, setShowComments] = useState(false);

    useEffect(() => {
        let itemData = {...item.sheet_affected};

        if (_.size(itemData) > 0) {
            let newData = {};

            newData.id = itemData.id;
            newData.conformity_mode = itemData.conformity_mode || CONFORMITY_MODE_GLOBAL;
            newData.conformity = itemData.conformity || CONFORMITY_NEW;
            newData.priority = itemData.priority || 0;
            newData.evaluation_date = itemData.evaluation_date ? new Date(itemData.evaluation_date) : null;
            newData.review_date = itemData.review_date ? new Date(itemData.review_date) : null;
            newData.hidden = itemData.hidden || false;
            newData.comments = itemData.comments || [];

            setData(newData);
        }
    }, [item, setData]);

    function toggleShowComments(e) {
        if (e) {
            e.preventDefault();
        }
        setShowComments(!showComments);
    }

    function renderFieldWrap([label, input]) {
        return (<>
            <label style={{fontSize: 10, fontWeight: 'bold', color: 'grey'}}>{label}</label>
            {input}
        </>);
    }

    function preSetValue(field, value) {
        setValue(field, value, true, function(newData) {
            let finalData = {...newData};
            delete finalData.comments;
            onAffectationChange(finalData);
        });
    }

    let comment = 'Aucun commentaire.';
    if (data && data.comments && data.comments.length > 0) {
        const comments = _.orderBy(data.comments, ['created_at'], ['asc']);
        let lastComment = comments[comments.length-1];
        comment = lastComment.comment;
    }

    let commentAction = [];
    if (isAffected) {
        if (_.isArray(commentActions[sheetAffected.id])) {
            commentAction = commentActions[sheetAffected.id][1];
        }
    }

    const isActive = isAffected && hasWriteAccess && data.hidden !== true;
    const hiddenCanBeToggled = isAffected && hasWriteAccess;

    return (
        <>
            <WatchsiteAffectedCommentsModal
                show={showComments}
                onClose={() => toggleShowComments()}
                values={data.comments}
                watchsite={watchsite}
                onChange={values => setValue("comments", values)}
                commentActions={commentAction}
                onCommentActions={values => onCommentActions(sheetAffected.id, values, data.comments)}
            />
            <tr key={item.id} style={isAffected ? {position: 'relative'} : {position: 'relative', borderBottom: "2px solid grey"}} >
                {drawSelectable(item, (!isAffected ? {backgroundColor: 'RGBA(0,0,0,0.15)'} : {}), !isAffected)}
                {columns.map((column) => {
                    if (column.is_active) {
                        let style = {};
                        switch(column.id) {
                            case 'sheet_affected_conformity_mode':
                                if (!isActive) {
                                    style.backgroundColor = 'RGBA(0,0,0,0.15)';
                                }
                                return (
                                    <td style={style}>
                                        {isAffected && <FieldComponent
                                            type="buttons"
                                            name={"conformity-mode-" + item.id}
                                            onChange={value => preSetValue("conformity_mode", parseInt(value))}
                                            value={data.conformity_mode}
                                            options={getConformityModes(sheetAffected.sheet.cut_into_requirements, true)}
                                            wrapper={([label, input]) => <>
                                                <fieldset className="statuts">{input}</fieldset>
                                            </>}
                                            disabled={!isActive}
                                        />}
                                        {!isAffected && "-"}
                                    </td>
                                );
                            case 'sheet_affected_conformity':
                                if (!isActive) {
                                    style.backgroundColor = 'RGBA(0,0,0,0.15)';
                                }
                                return (
                                    <td style={style}>
                                        {data.conformity_mode === CONFORMITY_MODE_REQUIREMENT && <>                                        
                                            <div>
                                                <label>Indicateurs de conformités : </label>
                                                <ul className="arborescence no-arrow">
                                                    <li>Conforme : {sheetAffected.indicators.ok}%</li>
                                                    <li>Ecart : {sheetAffected.indicators.nok}%</li>
                                                    <li>A préciser : {sheetAffected.indicators.tospecified}%</li>                                                    
                                                </ul>
                                            </div>
                                        </>}
                                        {data.conformity_mode === CONFORMITY_MODE_GLOBAL && <>                                            
                                            <FieldComponent
                                                type="buttons"
                                                name={"conformity-" + item.id}
                                                onChange={value => preSetValue("conformity", parseInt(value))}
                                                value={data.conformity}
                                                options={getConformities()}
                                                wrapper={([label, input]) => <>
                                                    <div className="filters">
                                                        <fieldset className="statuts">{input}</fieldset>
                                                    </div>
                                                </>}
                                                disabled={!isActive}
                                            />
                                        </>}
                                        {!isAffected && "-"}
                                    </td>
                                );
                            case 'sheet_affected_date':
                                if (!isActive) {
                                    style.backgroundColor = 'RGBA(0,0,0,0.15)';
                                }
                                return (
                                    <td style={style}>
                                        {isAffected && <>
                                            {data.conformity_mode === CONFORMITY_MODE_REQUIREMENT && <>
                                                <div>
                                                    <label>Date d'évaluation</label>
                                                    {formatDate(sheetAffected.equivalent_evaluation_date)}
                                                </div>
                                                <div>
                                                    <label>Date de revue</label>
                                                    {formatDate(sheetAffected.equivalent_review_date)}
                                                </div>
                                            </>}
                                            {data.conformity_mode === CONFORMITY_MODE_GLOBAL && <>
                                                <FieldComponent
                                                    label="Date d'évaluation"
                                                    type="date"
                                                    name={"evaluation-date-" + item.id}
                                                    value={data.evaluation_date}
                                                    onChange={value => preSetValue("evaluation_date", value)}
                                                    wrapper={renderFieldWrap}
                                                    disabled={!isActive}
                                                />
                                                <FieldComponent
                                                    label="Date de revue"
                                                    type="date"
                                                    name={"review-date-" + item.id}
                                                    value={data.review_date}
                                                    onChange={value => preSetValue("review_date", value)}
                                                    wrapper={renderFieldWrap}
                                                    disabled={!isActive}
                                                />
                                            </>}
                                        </>}
                                        {!isAffected && "-"}
                                    </td>
                                );
                            case 'sheet_affected_priority':
                                if (!isActive) {
                                    style.backgroundColor = 'RGBA(0,0,0,0.15)';
                                }
                                return (
                                    <td style={style}>
                                        {data.conformity_mode === CONFORMITY_MODE_REQUIREMENT && <>
                                            <div>
                                                <label>Priorité</label>
                                                P{sheetAffected.equivalent_priority}
                                            </div>
                                        </>}
                                        {data.conformity_mode === CONFORMITY_MODE_GLOBAL && <>
                                            <FieldComponent
                                                type="buttons"
                                                name={"priority-" + item.id}
                                                onChange={value => preSetValue("priority", parseInt(value))}
                                                value={data.priority}
                                                options={getPriorities()}
                                                wrapper={([label, input]) => <>
                                                    <fieldset className="statuts">{input}</fieldset>
                                                </>}
                                                disabled={!isActive}
                                            />
                                        </>}
                                        {!isAffected && "-"}
                                    </td>
                                );
                            case 'sheet_affected_hidden':
                                style = {textAlign: 'center', verticalAlign: 'top'};
                                if (!hiddenCanBeToggled) {
                                    style.backgroundColor = 'RGBA(0,0,0,0.15)';
                                }
                                return (
                                    <td style={style}>
                                        {isAffected && <FieldComponent
                                            type="checksingle"
                                            name={"hidden-" + item.id}
                                            className="field"
                                            checked={data.hidden || false}
                                            onChange={value => preSetValue("hidden", value)}
                                            wrapper={([labelText, input,,, label]) => <>
                                                {input}{label}
                                            </>}
                                            disabled={!hiddenCanBeToggled}
                                        />}
                                        {!isAffected && "-"}
                                    </td>
                                );
                            case 'sheet_affected_nb_actions':
                                if (!isActive) {
                                    style.backgroundColor = 'RGBA(0,0,0,0.15)';
                                }
                                return (
                                    <td style={style}>
                                        {isAffected && <>
                                            <Link to={prefixLinkTo() + `/actions?sheet=${sheetAffected.sheet.id}&watchsite=${sheetAffected.watchsite.id}`} title={`Voir les actions (${sheetAffected.indicators.nb_actions || 0})`}>
                                                <i className="icon-actions-plan-action" aria-hidden="true"></i>
                                                <span className="counter">{sheetAffected.indicators.nb_actions || 0}</span>
                                            </Link>
                                        </>}
                                        {!isAffected && "-"}
                                    </td>
                                );
                            case 'watchsite_label':
                            default:
                                style = {verticalAlign: 'top'};
                                if (!isActive) {
                                    style.backgroundColor = 'RGBA(0,0,0,0.15)';
                                }
                                return (
                                    <td style={style}>
                                        {isAffected && <strong><Link to={prefixLinkTo() + `/sheets-affected/${sheetAffected.id}`}>{watchsite.label}</Link></strong>}
                                        {!isAffected && <strong>{watchsite.label}</strong>}
                                    </td>
                                );
                        }
                    }
                    return <React.Fragment key={column.id}></React.Fragment>;
                })}
            </tr>
            {hasCustomerCommentAccess && isAffected &&
                <tr style={isActive ? {borderBottom: "2px solid grey"} : {borderBottom: "2px solid grey", backgroundColor: 'RGBA(0,0,0,0.15)'}}>
                    <td colSpan={columnAmount}>
                        <ToggableTextComponent limit={255}>
                            <div dangerouslySetInnerHTML={{__html: comment}} />
                        </ToggableTextComponent>
                    </td>
                    <td>
                        <Link onClick={toggleShowComments} to={prefixLinkTo() + "#"} title="Voir/Editer les commentaires">
                            <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>    
                        </Link>
                    </td>
                </tr>
            }
        </>
    );
}