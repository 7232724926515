import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BarLoader from "react-spinners/BarLoader";
import SheetAffectedApi from 'shared/api/SheetAffected';
import HtmlComponent from "shared/components/HtmlComponent";
import { CONFORMITY_NEW } from 'data/SheetAffected';
import { prefixLinkTo } from 'shared/services';

export default function LastSheets() {

    const [sheets, setSheets] = useState([]);
    const [nbSheets, setNbSheets] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reducedDisplay, toggle] = useState(true);

    function load() {
        SheetAffectedApi
            .search(
                {
                    "bool": {
                        "must": [
                            {"match": {"conformity": CONFORMITY_NEW}}
                        ]
                    }
                },
                0,
                15,
                "affectation_date",
                "DESC"
            )
            .then(([results,, total]) => {
                setLoading(false);
                setSheets(results);
                setNbSheets(total);
            })
            .catch(() => setLoading(false));
    }

    useEffect(load, []);

    return (
        <div className="contenu padding lastsheets">
			<h2><i className="icon-accueil-dernieres-fiches"></i>Dernières fiches</h2>
            <BarLoader loading={loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
			{!loading && <ul className="item-list latest striped">
                {sheets.map((sheet, index) => {
                    if (index <= 3 || reducedDisplay === false) {
                        if (typeof sheet.data == "string") {
                            sheet.data = JSON.parse(sheet.data.replaceAll('\\n', ''));
                        }
                        const link = sheet.data.length === 1 ? "/sheets-affected/" + sheet.data[0].id : "/sheets/" + sheet.sheet.id + "/watchsites";
                        return <li key={sheet.sheet.id}>
                            <div className="infos">
                                <Link to={prefixLinkTo() + link} title="Conformité">
                                    <div className="row">
                                        <div className="col-1"><strong>{sheet.sheet.id} :</strong></div>
                                        <div className="col-11"><HtmlComponent>{sheet.sheet.title}</HtmlComponent></div>
                                    </div>
                                </Link>
                            </div>
                            <div className="detail">
                                <Link to={prefixLinkTo() + link} title="Conformité"><i className="icon-boutons-lien-vers" aria-hidden="true"></i></Link>
                            </div>
                        </li>;
                    } else {
                        return <React.Fragment key={sheet.sheet.id}></React.Fragment>;
                    }                    
                })}
			</ul>}
			<div className="row">
				<div className="offset-md-3 col-md-6 text-center">
					<Link to={prefixLinkTo() + "/sheets-affected"} className="btn btn-primary icon h35"><i className="icon-boutons-lien-vers" aria-hidden="true"></i>&nbsp;Ma Veille ({nbSheets})</Link>
				</div>
				{!loading && sheets.length > 4 && <div className="col-md-3 text-right">
                    {reducedDisplay && <button onClick={() => toggle(false)} className="toggler actif after" type="button">Voir plus</button>}
                    {!reducedDisplay && <button onClick={() => toggle(true)} className="toggler after" type="button">Voir moins</button>}
				</div>}
			</div>
		</div>
    );
}
