import { React, useState, Modal, Link, useEffect } from 'commons';
import DocumentarySpaceResultRowCaseLaw from 'views/documentaryspace/Result/Row/CaseLaw';
import DocumentarySpaceResultRowClientSpecificModule from 'views/documentaryspace/Result/Row/ClientSpecificModule';
import DocumentarySpaceResultRowDraftText from 'views/documentaryspace/Result/Row/DraftText';
import DocumentarySpaceResultRowForm from 'views/documentaryspace/Result/Row/Form';
import DocumentarySpaceResultRowNewsFlash from 'views/documentaryspace/Result/Row/NewsFlash';
import DocumentarySpaceResultRowNews from 'views/documentaryspace/Result/Row/News';
import DocumentarySpaceResultRowNewsletter from 'views/documentaryspace/Result/Row/Newsletter';
import DocumentarySpaceResultRowRegulatoryControl from 'views/documentaryspace/Result/Row/RegulatoryControl';
import DocumentarySpaceResultRowRegulatoryFlowchart from 'views/documentaryspace/Result/Row/RegulatoryFlowchart';
import DocumentarySpaceResultRowSanction from 'views/documentaryspace/Result/Row/Sanction';
import DocumentarySpaceResultRowThematicFile from 'views/documentaryspace/Result/Row/ThematicFile';
import PaginationComponent from 'shared/components/list/PaginationComponent';
import { mediumModalCustomStyles } from 'shared/services/ModalStyle';
import { DocumentarySpaceApi } from 'shared/api';
import { typeLabels } from 'shared/data';
import { prefixLinkTo } from 'shared/services';

export default function DocumentarySpaceResultSection(props) {

    const [modalContent, setModalContent] = useState(null);
    const [displayed, setDisplay] = useState(true);
    const [results, setResults] = useState(null);
    const [perpage, setPerpage] = useState(props.perpage);
    const [page, setPage] = useState(1);
    const [totalrows, setTotalrows] = useState(0);

    function toggle(event) {
        event.preventDefault();
        setDisplay(!displayed);
    }

    function submitSearch() {
        DocumentarySpaceApi
            .classifySearch(
                props.query ? props.query : {},
                ((page - 1) * perpage),
                perpage,
                (props.classification ? props.classification : "noclassification"),
                {type: props.type}
            )
            .then(([rows,, total]) => {
                let newResults = null;
                if (rows.length > 0) {
                    switch (props.classification) {
                        case "domain":
                            newResults = classifyByDomain(rows);
                            break;
                        case "date":
                            newResults = classifyByDate(rows);
                            break;
                        default:
                            newResults = {levels: [{rows: rows}]};
                            break;
                    }
                }

                setTotalrows(total);
                setResults(newResults);

                if (props.signalSearchDone) {
                    props.signalSearchDone();
                }
            });            
    }

    function classifyByDomain(rows) {
        const classifiedResults = {levels: []};
        let currentLevelDomain = null;
        let currentLevelSubDomain = null;
        let currentLevelTheme = null;

        rows.forEach(row => {
            if (currentLevelDomain === null || currentLevelDomain.id !== row.domain.id) {
                currentLevelDomain = {
                    levels: [],
                    id: row.domain.id,
                    label: row.domain.value,
                    level: 1,
                };
                classifiedResults.levels.push(currentLevelDomain);
                currentLevelSubDomain = null;
            }

            if (currentLevelSubDomain === null || currentLevelSubDomain.id !== row.subdomain.id) {
                currentLevelSubDomain = {
                    levels: [],
                    id: row.subdomain.id,
                    label: row.subdomain.value,
                    level: 2,
                };
                currentLevelDomain.levels.push(currentLevelSubDomain);
                currentLevelTheme = null;
            }

            if (row.theme) {
                if (currentLevelTheme === null || currentLevelTheme.id !== row.theme.id) {
                    currentLevelTheme = {
                        rows: [],
                        id: row.theme.id,
                        label: row.theme.value,
                        level: 3,
                    };
                    currentLevelSubDomain.levels.push(currentLevelTheme);
                }
            } else {
                if (currentLevelTheme === null || currentLevelTheme.id !== 0) {
                    currentLevelTheme = {
                        rows: [],
                        id: 0,
                        label: "",
                        level: 3,
                    };
                    currentLevelSubDomain.levels.push(currentLevelTheme);
                }
            }

            currentLevelTheme.rows.push(row);
        });

        return classifiedResults;
    }

    function classifyByDate(rows) {
        const classifiedResults = {levels: []};
        let currentLevel = null;

        rows.forEach(row => {
            if (currentLevel === null || currentLevel.id !== (new Date(row.date).getFullYear())) {
                if (currentLevel !== null) {
                    classifiedResults.levels.push(currentLevel);
                }

                currentLevel = {
                    rows: [],
                    id: (new Date(row.date).getFullYear()),
                    label: (new Date(row.date).getFullYear()),
                    level: 1
                };
            }

            currentLevel.rows.push(row);
        });

        classifiedResults.levels.push(currentLevel);

        return classifiedResults;
    }

    function renderLevel(level) {
        return <>
            {level.label && <>
                {level.level === 1 && <header><h2>{level.label}</h2></header>}
                {level.level === 2 && <header className="bg-gris-40"><h2>{level.label}</h2></header>}
                {level.level === 3 && <header className="bg-gris-30"><h2>{level.label}</h2></header>}
            </>}
            {level.levels && level.levels.map((child, key) => <React.Fragment key={key}>{renderLevel(child)}</React.Fragment>)}
            {level.rows && renderRows(level)}
        </>;
    }

    function renderRows(level) {
        return <ul className="item-list">
            {level.rows.map(row => {
                let output = <></>;                    
                switch (props.type) {
                    case 'caselaw':
                        output = <DocumentarySpaceResultRowCaseLaw setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'clientspecificmodule':
                        output = <DocumentarySpaceResultRowClientSpecificModule setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'drafttext':
                        output = <DocumentarySpaceResultRowDraftText setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'form':
                        output = <DocumentarySpaceResultRowForm setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'news':
                        output = <DocumentarySpaceResultRowNews setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'newsflash':
                        output = <DocumentarySpaceResultRowNewsFlash setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'newsletter':
                        output = <DocumentarySpaceResultRowNewsletter setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'regulatorycontrol':
                        output = <DocumentarySpaceResultRowRegulatoryControl setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'regulatoryflowchart':
                        output = <DocumentarySpaceResultRowRegulatoryFlowchart setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'sanction':
                        output = <DocumentarySpaceResultRowSanction setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    case 'thematicfile':
                        output = <DocumentarySpaceResultRowThematicFile setDetail={setModalContent} row={row} classification={props.classification} />;
                        break;
                    default: 
                        // should not be reached
                        break;
                }
                return <React.Fragment key={`${props.type}-${row.id}`}>{output}</React.Fragment>;
            })}
        </ul>;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(submitSearch, [props.classification, page, perpage, props.type, props.query]);

    if (results === null) {
        return <></>;
    }

    return <section className="bloc">
        {props.showHeader && <header>
            <h2><button onClick={toggle} className={displayed ? "toggler actif" : "toggler"}>{typeLabels[props.type]}</button></h2>
        </header>}
        {displayed && <section className="bg-blanc">
            {results.levels.map(level => renderLevel(level))}
            {props.showAllDocumentLink && <Link to={prefixLinkTo() + `/documentary-space/${props.type}`}>Voir tous les documents</Link>}
        </section>}
        {props.showPagination && <div className="row">
            <div className="col col-md-2"></div>
            <div className="col">
                <PaginationComponent
                    total={totalrows}
                    perpage={perpage}
                    currentpage={page}
                    onPerpageChange={value => setPerpage(value)}
                    onPageChange={value => setPage(value)}
                />
            </div>
        </div>}
        <Modal isOpen={modalContent !== null} onRequestClose={() => setModalContent(null)} style={mediumModalCustomStyles}>
            {modalContent}
        </Modal>
    </section>
}

DocumentarySpaceResultSection.defaultProps = {
    showHeader: true,
    showAllDocumentLink: true,
    showPagination: false,
    perpage: 5,
    signalSearchDone: false,
}