import { useState, useEffect } from 'react';
import DefaultLayout from "layouts/DefaultLayout";
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import DomainSelectionComponent from 'shared/components/DomainSelectionComponent';
import Can, { denyIfCantPerform } from "shared/services/Can";
import { compileErrorsFromResponse } from 'shared/services/Utils';
import { useToggler } from "shared/hooks/Toggler";
import { useForm } from 'shared/hooks/Form';
import { useSecurity } from 'shared/hooks/Security';
import { useAccount } from 'hooks/Account';
import { toast } from 'react-toastify';
import ExternalUserApi from 'shared/api/ExternalUser';
import AccountApi from 'shared/api/Account';

export default function MyProfile(props) {

    denyIfCantPerform(props, "user:update-profile");

    const [user,, setUser] = useSecurity();
    const [account] = useAccount();
    const [currentAccountsIds, setCurrentAccountsIds] = useState([]);
    const [data, errors, setErrors, setValue, setData] = useForm({        
        lastname: user.lastname,
        firstname: user.firstname,
        telephone: user.telephone,
        civility: user.civility,
        function: user.function,
        alert_activation: (user.alert_activation === true ? 1 : 0),
        alert_restriction: (user.alert_restriction === true ? 1 : 0),
        alert_frequency: user.alert_frequency,
        alert_format: user.alert_format,
        alert_sheet_status: user.alert_sheet_status,
        alert_domains: user.alert_domains || [],
        alert_review_activation: user.alert_review_activation === true ? 1 : 0,
        alert_deadline_activation: user.alert_deadline_activation === true ? 1 : 0,
        alert_documentary_space_activation: user.alert_documentary_space_activation === true ? 1 : 0,
    });
    const [saving, setSaving] = useState(false);
    const [togglers, toggle] = useToggler({'my-profile': true, 'alerts': true,});   

    function loadUserAccounts() {
        AccountApi
            .useraccounts(user.email)
            .then(([ua]) => {
                setCurrentAccountsIds(ua.map(item => item.id));
            });
    }

    function getAlertFrequencyOptions() {
        let frequencies = [
            {value: 0, label: "Quotidienne"},
            {value: 1, label: "Quotidienne + Récapitulatif mensuel"},
            {value: 2, label: "Hebdomadaire"},
            {value: 3, label: "Hebdomadaire + Récapitulatif mensuel"},
            {value: 4, label: "Bimensuelle"},
            {value: 5, label: "Mensuelle"},
        ];
        return frequencies.filter(v => Object.values(user.available_alert_frequencies).includes(v.value));
    }

    function submit(event) {
        event.preventDefault();
        setSaving(true);

        let newData = {...data};
        const availableFrequency = getAlertFrequencyOptions().map(o => o.value);
        if (!availableFrequency.includes(newData.alert_frequency)) {
            newData.alert_frequency = null;
        }

        ExternalUserApi
            .profileUpdate(newData)
            .then(u => {
                setSaving(false);
                setErrors([]);
                setData({        
                    lastname: u.lastname,
                    firstname: u.firstname,
                    telephone: u.telephone,
                    civility: u.civility,
                    function: u.function,
                    alert_activation: (u.alert_activation === true ? 1 : 0),
                    alert_restriction: (u.alert_restriction === true ? 1 : 0),
                    alert_frequency: u.alert_frequency,
                    alert_format: u.alert_format,
                    alert_sheet_status: u.alert_sheet_status,
                    alert_domains: u.alert_domains || [],
                    alert_review_activation: u.alert_review_activation === true ? 1 : 0,
                    alert_deadline_activation: u.alert_deadline_activation === true ? 1 : 0,
                    alert_documentary_space_activation: u.alert_documentary_space_activation === true ? 1 : 0,
                });
                setUser(u);
                toast.success("Enregistrement effectué.");
            })
            .catch(error => {
                setSaving(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);                    
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Enregistrement impossible, veuillez corriger préalablement les erreurs.");
                }
            });
    }


    useEffect(loadUserAccounts, [user.email]);

    return (
        <DefaultLayout screen="E57" title="Mon profil">
            <h1>Mon profil</h1>
            <section>
                <form onSubmit={submit} id="form-my-profile" className="form">
                    <ToggableBlocComponent
                        label="Mon profil"
                        id="my-profile"
                        toggled={togglers["my-profile"]}
                        toggle={toggle}
                    >
                        <div className="bg-blanc">
                            <FieldComponent 
                                name="email"
                                label="Email"
                                className="field medium"
                                disabled
                                value={user.email}
                            />
                        </div>
                        <hr />
                        <div className="bg-blanc">
                            <FieldComponent
                                type="radio"
                                name="civility"
                                label="Civilité"
                                options={[{value:"Mr", label: "Monsieur"}, {value:"Mrs", label: "Madame"}]}
                                value={data.civility}
                                onChange={value => setValue("civility", value)}
                            />
                            <FieldComponent
                                name="lastname"
                                label="Nom *"
                                className="field small"
                                error={errors.lastname}
                                value={data.lastname} 
                                onChange={value => setValue("lastname", value)}
                            />
                            <FieldComponent
                                name="firstname"
                                label="Prénom *" 
                                className="field small"
                                error={errors.firstname}
                                value={data.firstname}
                                onChange={value => setValue("firstname", value)}
                            />
                            <FieldComponent
                                name="telephone" 
                                label="N° de téléphone"
                                className="field small"
                                value={data.telephone}
                                onChange={value => setValue("telephone", value)}
                            />
                            <FieldComponent
                                name="function"
                                label="Fonction"
                                className="field small"
                                value={data.function}
                                onChange={value => setValue("function", value)}
                            />
                        </div> 
                    </ToggableBlocComponent>
                    <ToggableBlocComponent
                        label="Alertes"
                        id="alerts"
                        toggled={togglers["alerts"]}
                        toggle={toggle}
                    >
                        <div className="bg-blanc">
                            <FieldComponent
                                type="radio"
                                name="alert_activation"
                                label="Activer les alertes"
                                options={[{value:1, label: "Oui"}, {value:0, label: "Non"}]}
                                value={data.alert_activation}
                                onChange={value => setValue("alert_activation", parseInt(value))}
                            />
                            {data.alert_activation === 1 && <>
                                <FieldComponent
                                    type="radio"
                                    name="alert_frequency"
                                    label="Fréquence des alertes"
                                    options={getAlertFrequencyOptions()}
                                    value={data.alert_frequency}
                                    error={errors.alert_frequency}
                                    onChange={value => setValue("alert_frequency", parseInt(value))}
                                />
                                <FieldComponent
                                    type="radio"
                                    name="alert_format"
                                    label="Format des alertes"
                                    options={[
                                        {value:0, label: "Séparé : Une fiche par mail"},
                                        {value:1, label: "Groupé : Fiches groupées dans un mail"}
                                    ]}
                                    value={data.alert_format}
                                    onChange={value => setValue("alert_format", parseInt(value))}
                                />
                                <Can perform="user:update-alert-sheet-status" data={{account :account}} yes={() => (
                                    <FieldComponent
                                        type="radio"
                                        name="alert_sheet_status"
                                        label="Etat d'avancement de la fiche"
                                        options={[
                                            {value:0, label: "Alerte lorsque la fiche est finalisée"},
                                            {value:1, label: "Alerte dès que la fiche est affectée"}
                                        ]}
                                        value={data.alert_sheet_status}
                                        onChange={value => setValue("alert_sheet_status", parseInt(value))}
                                    />
                                )} />
                                <Can perform="user:update-alert-domains" data={{account :account}} yes={() => (    
                                    <>
                                        <FieldComponent
                                            type="radio"
                                            name="alert_restriction"
                                            label="Accès à tous les domaines, sous domaines, thèmes"
                                            options={[{value:0, label: "Oui"}, {value:1, label: "Non"}]}
                                            value={data.alert_restriction}
                                            onChange={value => setValue("alert_restriction", parseInt(value))}
                                        />
                                        {data.alert_restriction === 1 && <>
                                            <DomainSelectionComponent 
                                                label="Restriction des alertes aux domaines/sous-domaines/thèmes"
                                                accounts={currentAccountsIds}
                                                name="alert_domains"
                                                onChange={value => setValue("alert_domains", value)}
                                                value={data.alert_domains}
                                            />
                                        </>}
                                    </>
                                )} />
                            </>}
                        </div>
                        <hr />
                        <div className="bg-blanc">
                            <Can perform="account:compliance_management" data={{account :account}} yes={() => (
                                <FieldComponent
                                    type="radio"
                                    name="alert_review_activation"
                                    label="Activation des alertes sur les dates de revue des fiches"
                                    options={[{value:1, label: "Oui"}, {value:0, label: "Non"}]}
                                    value={data.alert_review_activation}
                                    onChange={value => setValue("alert_review_activation", parseInt(value))}
                                />
                            )} />
                            <Can perform="account:actionplan" data={{account :account}} yes={() => (
                                <FieldComponent
                                    type="radio"
                                    name="alert_deadline_activation"
                                    label="Activation des alertes sur les dates limite de réalisation des actions"
                                    options={[{value:1, label: "Oui"}, {value:0, label: "Non"}]}
                                    value={data.alert_deadline_activation}
                                    onChange={value => setValue("alert_deadline_activation", parseInt(value))}
                                />
                            )} />
                        </div>
                        <Can perform="user:activate-alert-documentary-space" data={{account :account}} yes={() => (<>
                            <hr />
                            <div className="bg-blanc">
                                <FieldComponent
                                    type="radio"
                                    name="alert_documentary_space_activation"
                                    label="Réception de la newsletter de l'espace documentaire"
                                    options={[{value:1, label: "Oui"}, {value:0, label: "Non"}]}
                                    value={data.alert_documentary_space_activation}
                                    onChange={value => setValue("alert_documentary_space_activation", parseInt(value))}
                                />
                            </div>
                        </>)} />
                    </ToggableBlocComponent>
                    <section className="row">
                        <div className="col text-right">
                            <LoadButton 
                                loading={saving} 
                                label="Enregistrer" 
                                name="save" 
                                id="save-my-profile"
                            />
                        </div>                        
                    </section>  
                </form>
            </section>
        </DefaultLayout>
    );
}