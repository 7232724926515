import React from 'react';
import LimitComponent from 'shared/components/list/LimitComponent';

export default function PaginationComponent(props) {

    let nbPages = Math.ceil(props.total / props.perpage);

    let sectionStart = (<React.Fragment></React.Fragment>);
    let sectionCurrent = (<React.Fragment></React.Fragment>);
    let sectionEnd = (<React.Fragment></React.Fragment>);
    
    function updatePage(event, page) {
        event.preventDefault();
        if (props.paginationConfirm && window.confirm(props.paginationConfirm)) {
            props.onPageChange(page);
        } else if (!props.paginationConfirm) {
            props.onPageChange(page);
        }
    }

    function updatePerPage(page) {
        if (props.paginationConfirm && window.confirm(props.paginationConfirm)) {
            props.onPerpageChange(page);
        } else if (!props.paginationConfirm) {
            props.onPerpageChange(page);
        }        
    }

    if (nbPages <= 10) {
        let pages = Array(nbPages).fill().map((_, idx) => 1 + idx);
        sectionStart = (
            <div className="start">
                <ul>
                    {pages.map((number) => {
                        return (<li key={number} className={number === props.currentpage ? "act" : ""}>
                            <a onClick={(event) => { updatePage(event, number); } } href={`?page=${number}`}>{number}</a>
                        </li>)
                    })}
                </ul>
            </div>
        );
    } else {
        let startpages = Array(5).fill().map((_, idx) => 1 + idx);
        sectionStart = (
            <div className="start">
                <ul>
                    {startpages.map((number) => {
                        return (<li key={number} className={number === props.currentpage ? "act" : ""}>
                            <a onClick={(event) => { updatePage(event, number); } } href={`?page=${number}`}>{number}</a>
                        </li>)
                    })}
                </ul>
            </div>
        );

        let endpages = Array(5).fill().map((_, idx) => (nbPages - 4) + idx);
        sectionEnd = (
            <div className="end">
                <ul>
                    {endpages.map((number) => {
                        return (<li key={number} className={number === props.currentpage ? "act" : ""}>
                            <a onClick={(event) => { updatePage(event, number); } } href={`?page=${number}`}>{number}</a>
                        </li>)
                    })}
                </ul>
            </div>
        );

        if (props.currentpage > 5 && props.currentpage <= (nbPages - 5)) {
            let currentpages = Array(5).fill().map((_, idx) => (props.currentpage - 2) + idx);
            sectionCurrent = (
                <div className="current">
                    <ul>
                        {currentpages.map((number) => {
                            return (<li key={number} className={number === props.currentpage ? "act" : ""}>
                                <a onClick={(event) => { updatePage(event, number); } } href={`?page=${number}`}>{number}</a>
                            </li>)
                        })}
                    </ul>
                </div>
            );
        }
    }

    if (nbPages > 1) {
        return (
            <div className="pagination">
                <nav className="pages">
                    {props.currentpage > 1 && <div className="arrows first-prev">
                        <a title="Première page" onClick={(event) => { updatePage(event, 1); } } href="?page=prev" className="first"><i className="icon-left-open"></i><i className="icon-left-open"></i></a>
                        <a title="Page précédente" onClick={(event) => { updatePage(event, (props.currentpage - 1)); } } href="?page=first" className="prev"><i className="icon-left-open"></i></a>
                    </div>}
                    <div className="parts">
                        {sectionStart}
                        {sectionCurrent}
                        {sectionEnd}
                    </div>
                    {props.currentpage < nbPages && <div className="arrows last-next">
                        <a title="Page suivante" onClick={(event) => { updatePage(event, (props.currentpage + 1)); } } href="?page=next" className="next"><i className="icon-right-open"></i></a>
                        <a title="Dernière page" onClick={(event) => { updatePage(event, nbPages); } } href="?page=last" className="last"><i className="icon-right-open"></i><i className="icon-right-open"></i></a>
                    </div>}
                </nav>
                <LimitComponent 
                    value={props.perpage}
                    onChange={updatePerPage}
                />            
                <p className="infos">page {props.currentpage} sur {nbPages}</p>
            </div>

        );
    } else {
        return (
            <div className="pagination">
                <nav className="pages"></nav>
                <LimitComponent 
                    value={props.perpage}
                    onChange={updatePerPage}
                />            
                <p className="infos">page {props.currentpage} sur {nbPages}</p>
            </div>
        );
    }
}
