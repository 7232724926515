import React, { useEffect, useState } from 'react';
import BarLoader from "react-spinners/BarLoader";
import _ from 'lodash';

import ColumnsSelectionComponent from 'shared/components/list/ColumnsSelectionComponent';
import SortingComponent from 'shared/components/list/SortingComponent';
import PaginationComponent from 'shared/components/list/PaginationComponent';

import { deepFind, formatDate, formatUser, crop } from 'shared/services/Utils';
import ChecksingleReadOnlyComponent from './ChecksingleReadOnlyComponent';

export default function ListComponent(props) {

    let columnsState = localStorage.getItem(props.id + "-column") ? JSON.parse(localStorage.getItem(props.id + "-column")) : {};

    const { rows, sortOptions } = props;
    const [columns, setColumns] = useState([]);
    const [sortableColumns, setSortableColumns] = useState([]);
    const [options, setOptions] = useState([]);
    const selection = props.selection;

    function updateColumns() {
        const newColumns = props.columns.map(c => {
            if (_.has(columnsState, c.id)) {
                c.is_active = _.get(columnsState, c.id);
            }
            if (!_.has(c, "is_active")) {
                c.is_active = true;
            }
            if (!_.has(c, "selectable")) {
                c.selectable = true;
            }
            return c;
        });
        const newOptions = newColumns.map(c => ({
            id: c.id,
            value: c.id,
            label: c.title,
            checked: c.is_active,
            selectable: c.selectable
        }))
        const newSortableColumns = {};
        _.forEach(newColumns, c => {
            if (c.sortable === undefined || c.sortable === true) {
                newSortableColumns[c.id] = {value: c.id, label: c.title};
            }
        });
        _.forEach(sortOptions, o => {
            newSortableColumns[o.value] = o;
        });
        setColumns(newColumns);
        setOptions(newOptions);
        setSortableColumns(_.values(newSortableColumns));
    }

    useEffect(() => {
        updateColumns();
        // eslint-disable-next-line
    }, [props.columns]);

    function selectAll(event) {
        const checked = event.target.checked;
        var newSelection = [];

        if (checked) {
            rows.forEach(function(row) {
                let rowId = deepFind(row, props.selectField);
                if (rowId) {
                    newSelection.push(rowId);
                }
            });
        }

        props.onSelect(newSelection);
    }

    function select(event) {     
        let newSelection = [...selection];
        const elem = event.target;
        const checked = elem.checked;
        let rowId = parseInt(elem.getAttribute('data-rowid'));

        if (checked) {
            if (rowId) {
                newSelection.push(rowId);
            }
        } else {
            newSelection = newSelection.filter((s) => s !== rowId);
        }

        document.getElementById("all-items").checked = false;

        props.onSelect(newSelection);
    }

    function sort(event) {
        if(event.target.name === "sortoptions") {
            props.onSortingChange(event.target.value, "asc");
        } else {
            if (props.direction === "asc") {
                props.onSortingChange(props.sorting, "desc");
            } else {
                props.onSortingChange(props.sorting, "asc");
            }
        }
    }

    function toggleColumn(event) {
        let newcolumns = [];
        Object.entries(columns).forEach(([index, column]) => {
            if (event.target.id === "column-" + column.id) {
                column.is_active = event.target.checked;
                _.set(columnsState, column.id, event.target.checked);
            }
            newcolumns.push(column);
        });
        localStorage.setItem(props.id + "-column", JSON.stringify(columnsState));

        setColumns(newcolumns);
        setOptions(newcolumns.map(c => ({
            id: c.id,
            value: c.id,
            label: c.title,
            checked: c.is_active,
            selectable: c.selectable
        })));
    }

    function formatValue(format, value, column) {
        switch(format) {
            case "date":
            case "datetime":
                return formatDate(value, format === "datetime");

            case "user":
                return formatUser(value);

            case "checksingle":
                return <ChecksingleReadOnlyComponent value={value} />;

            case "image":
                return <>{value && <img width="50" alt="Logo de l'entreprise" src={`data:image/jpeg;base64,${value.image}`} />}</>;

            case "crop":
                return value ? crop(value, column.crop) : "";

            default:
                return null;
        }
    }

    function displayRow(row, column) {     
        const isActive = column.is_active;
        const columnId = column.id; 
        const className = column.className;
        const format = column.format;
        const render = column.render;

        if (isActive) {
            let value = row[columnId];
            let formattedValue = formatValue(format, value, column);

            if (format) {
                return <td className={className}>{formattedValue ? formattedValue : "-"}</td>;
            } else {
                return <td className={className}>{render ? render(row, props.columnsRenderParams) : deepFind(row, columnId)}</td>;
            }
        } else {
            return null;
        }
    }

    function drawSelectable(row, style = {}, disabled = false, rowSpan = 1) {
        let rowId = deepFind(row, props.selectField);

        return (props.selectable && (
            <td className="w-34px text-center valign-top" style={style} rowSpan={rowSpan}>
                <input disabled={disabled} checked={selection.includes(rowId)} onChange={select} data-rowid={rowId} type="checkbox" className="select-row" id={`select-row-${rowId}`} />
                <label htmlFor={`select-row-${rowId}`} className={props.selectorClassName || null}></label>
            </td>
        ));
    }

    function renderRows(rows) {
        if (props.renderRow) {
            return rows.map((row, index) => <React.Fragment key={row.id}>{props.renderRow(row, columns, index, displayRow, drawSelectable)}</React.Fragment>);
        }
        return rows.map((row) => (
            <tr key={row.id} id={row.id} className={row.className || null}>
                {drawSelectable(row)}
                {columns.map((column) => 
                    <React.Fragment key={column.id}>{
                        displayRow(
                            row,
                            column
                        )}
                    </React.Fragment>
                )}
            </tr>
        ));
    }

    function renderHeader() {
        if (props.renderHeader) {
            return props.renderHeader(columns);
        }
        return (
            <tr>
                {props.selectable &&
                    <th className="w-34px text-center valign-top"></th>
                }
                {columns.map((column) => {
                    if (column.is_active) {
                        return (
                            <th key={column.id} className={column.className ? column.className : ""}>
                                {column.title}
                            </th>
                        );
                    }
                    return <React.Fragment key={column.id}></React.Fragment>;
                })}
            </tr>
        );
    }

    let list = (<div></div>);
    if (!props.loading && props.rows.length === 0) {
        list = (<div>Aucun résultat</div>);
    } else if (props.rows.length > 0) {
        list = (
            <section className={props.border}>
                <table className={props.className} id="list">
                    {props.displayHeader && <thead>
                        {renderHeader()}
                    </thead>}
                    <tbody>
                        {renderRows(props.rows)}
                    </tbody>
                </table>
                {props.paginable && <div className="row">
                    <div className="col col-md-2"></div>
                    <div className="col">
                        <PaginationComponent
                            total={props.totalrows}
                            perpage={props.perpage}
                            currentpage={props.page}
                            onPerpageChange={props.onPerpageChange}
                            onPageChange={props.onPageChange}
                            paginationConfirm={props.paginationConfirm}
                        />
                    </div>
                </div>}
            </section>
        );
    }

    return (
        <section>
            <header className="bg-gris-40">
                <div className="row">
                    <div className={props.batchActionsClassName}>
                        {props.selectable && (<>
                            <input onChange={selectAll} type="checkbox" name="all-items" id="all-items" value="" />
							<label title={`Sélectionner tous les éléments de la page courante (${props.page})`} htmlFor="all-items">&nbsp;</label>
                        </>)}
                        {props.batchActions}
                    </div>
                    <div className={props.otherActionsClassName}>
                        {props.otherActions}
                        <strong className="uppercase color-gris-texte">{props.totalrows} résultat(s)</strong>
                        <SortingComponent 
                            label="Trier par"
                            columns={sortableColumns}
                            onChange={sort}
                            value={props.sorting}
                            direction={props.direction}
                        />
                        {options.length > 0 &&
                            <ColumnsSelectionComponent 
                                label="Choisir données à afficher"
                                options={options}
                                onChange={toggleColumn}
                            />
                        }
                    </div>
                </div>
            </header>                            
            <section>
                <div className={props.toolbarClassName}>
                    {props.globalActions}
                    {props.children}
                </div>
            </section>
            <BarLoader loading={props.loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
            {list}
        </section>
    );
}

ListComponent.defaultProps = {
    paginable: true,
    border: "",
    className: "table table-bordered table-striped",
    displayHeader: true,
    columns: [],
    selectField: "id",
    otherActions: "",
    batchActionsClassName: "col-md-9",
    otherActionsClassName: "col-md-3 text-right",
    paginationConfirm: null,
    toolbarClassName: "bg-gris-25 text-right"
};