import React, { useEffect, useState, useContext } from "react";
import { toast } from 'react-toastify';
import { Link, Prompt, useHistory } from "react-router-dom";
import { HOTLINE_STATUS_DRAFT, HOTLINE_STATUS_OPEN, HOTLINE_STATUS_ANSWERED, getHotlineStatusColor, getHotlineStatusLabel } from 'shared/data/Hotline';
import { compileErrorsFromResponse, formatDate, formatUser } from "shared/services/Utils";
import DefaultLayout from "layouts/DefaultLayout";
import FieldComponent from 'shared/components/FieldComponent';
import FileComponent from 'shared/components/FileComponent';
import FileListComponent from 'shared/components/FileListComponent';
import LoadButton from 'shared/components/LoadButton';
import PageLoadingComponent from "shared/components/PageLoadingComponent";
import PreviewFieldComponent from "shared/components/PreviewFieldComponent";
import StatusComponent from 'shared/components/StatusComponent';
import TreeReferentialComponent from "shared/components/tree/TreeReferentialComponent";
import HotlineApi from 'shared/api/Hotline';
import { denyIfCantPerform, canPerform } from "shared/services/Can";
import { useAccount } from 'hooks/Account';
import { useForm } from "shared/hooks/Form";
import { ReferentialContext } from 'shared/stores/Referential';
import { prefixLinkTo } from 'shared/services';

export default function HotlineForm(props) {

    const history = useHistory();
    const [account] = useAccount();
    const [isLoading, setIsLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        title: '',
        question: '',
        attachments: [],
        confidential: false,
        complement: '',
        categories: [],
    });
    const [hotline, setHotline] = useState({
        status: HOTLINE_STATUS_DRAFT,
    });
    const [referentialContext] = useContext(ReferentialContext);
    const tree = referentialContext["tree"];

    useEffect(() => {
        if (props.match.params.id) {
            HotlineApi
                .get(props.match.params.id)
                .then(freshHotline => {
                    setHotline(freshHotline);
                    setData({
                        id: freshHotline.id,
                        title: freshHotline.title,
                        question: freshHotline.question,
                        attachments: freshHotline.attachments,
                        confidential: freshHotline.confidential,
                        complement: freshHotline.complement,
                        categories: freshHotline.categories ? freshHotline.categories.map(elem => elem.id) : [],
                    });
                    setIsLoading(false);
                })
                .catch((error) => {
                    toast.error("Une erreur est survenue lors de la récupération de la question");
                    history.goBack();
                });
        } else {           
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function submit(e) {
        e.preventDefault();
        setSaving(true);

        const submitButton = e.nativeEvent.submitter.name;

        let requestData = {};
        requestData = {...data};
        requestData.attachments = data.attachments.map(file => file.hash) || [];
        requestData.send = (submitButton === "save-and-send");
        
        HotlineApi
            .save(requestData)
            .then(newData => {
                setErrors({});
                setSaving(false);
                setValue("complement", "");
                setHotline(newData);
                toast.success("Enregistrement effectué.");
                reloadOrClose(e, props, "hotlines", newData.id);
            })
            .catch(error => {
                setSaving(false);
                if (error.response.data.message) {
                    error.response.data.message.split('\n').forEach(message => toast.error(message));
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            }); 
    }

    let isPreview = true;
    if (isLoading === true) {
        return <PageLoadingComponent label="Chargement de la question" />
    } else {
        if (props.match.params.id) {
            denyIfCantPerform(props, "hotline:read", {account: account, hotline: hotline});
            isPreview = hotline.status !== HOTLINE_STATUS_DRAFT || !canPerform("hotline:write", {account: account, hotline: hotline});
        } else {
            denyIfCantPerform(props, "hotline:create", {account: account});
            isPreview = false;
        }        
    }

    const validationsButtons = <div className="col-md-9 text-right">
        {hotline.status === HOTLINE_STATUS_DRAFT && <LoadButton 
            loading={saving} 
            label="Enregistrer"
            name="save"
            id="save"
        />}
        {hotline.status !== HOTLINE_STATUS_ANSWERED && <LoadButton 
            loading={saving} 
            label="Enregistrer et envoyer"
            name="save-and-send"
            id="save-and-send"
        />}
    </div>;

    return (
        <DefaultLayout screen="E43" title="Hotline">
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>Hotline</h1>
            <section className="bloc">
                <form onSubmit={submit} id="form-hotline" className="form">
                    <section id="configuration" className="bloc">
                        <header className="uppercase">Hotline</header>

                        <section className="border-gris-25 border-lrb bg-blanc">
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Titre"
                                    required
                                    name="title"
                                    value={data.title}
                                    onChange={value => setValue("title", value)}
                                    error={errors.title}
                                    preview={isPreview}
                                />
                                <FieldComponent
                                    label="Confidentiel"
                                    type="checksingle"
                                    name="confidential"
                                    checksingleLabel="Oui"
                                    checked={data.confidential}
                                    onChange={value => setValue("confidential", value)}
                                    preview={isPreview}
                                />
                            </div>
                            {isPreview && <>
                                <div className="bg-gris-10">
                                    <PreviewFieldComponent label="Statut">
                                        <StatusComponent 
                                            value={getHotlineStatusLabel(hotline.status)}
                                            color={getHotlineStatusColor(hotline.status)}
                                        />
                                    </PreviewFieldComponent>
                                </div>
                                <div className="bg-gris-10">
                                    <PreviewFieldComponent label="Date et heure de la question">
                                        {formatDate(hotline.created_at, true)}                                    
                                    </PreviewFieldComponent>
                                </div>
                            </>}
                            <div className="bg-blanc">
                                {isPreview && <PreviewFieldComponent labelClassName="label at-top" label="Question" fromRte={true}>
                                    {hotline.question}
                                </PreviewFieldComponent>}
                                {!isPreview && <FieldComponent
                                    label="Question"
                                    required
                                    type="textarea"
                                    rte
                                    name="question"
                                    value={data.question}
                                    onChange={value => setValue("question", value)}
                                    error={errors.question}                                    
                                />}
                                {hotline.id && hotline.status === HOTLINE_STATUS_OPEN && canPerform("hotline:write", {account: account, hotline: hotline}) &&
                                    <FieldComponent
                                        label="Complément question"
                                        type="textarea"
                                        rte
                                        name="complement"
                                        value={data.complement}
                                        onChange={value => setValue("complement", value)}
                                        error={errors.complement}
                                    />
                                }
                            </div>
                            <div className="bg-blanc">
                                {!isPreview &&
                                    <FileComponent
                                        label="Pièces-jointes"
                                        value={data.attachments}
                                        name="attachments"
                                        multiple
                                        onChange={value => setValue('attachments', value)}
                                        upload={HotlineApi.uploadAttachments}
                                        error={errors.attachments}
                                        extensions={['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.jpg', '.jpeg', '.png']}
                                        showSize
                                    />
                                }
                                {isPreview && 
                                    <PreviewFieldComponent label="Pièces-jointes">
                                        <FileListComponent values={hotline.attachments} style={{padding: 0}} />                                 
                                    </PreviewFieldComponent>
                                }
                            </div>
                            {hotline.status === HOTLINE_STATUS_ANSWERED && <>
                                <div className="bg-gris-25">
                                    <PreviewFieldComponent label="Domaines">
                                        {tree.length > 0 && hotline.categories.length > 0 &&
                                            <TreeReferentialComponent 
                                                readOnly 
                                                className="arborescence" 
                                                value={hotline.categories.map(elem => elem.id)}
                                                items={tree} 
                                            />
                                        }
                                    </PreviewFieldComponent>
                                </div>
                                <div className="bg-blanc">
                                    <PreviewFieldComponent labelClassName="label at-top" label="Réponse" fromRte={true}>
                                        {hotline.answer}
                                    </PreviewFieldComponent>
                                </div>
                                <div className="bg-gris-25">
                                    <PreviewFieldComponent label="Auteur de la réponse">
                                        {formatUser(hotline.answered_by)}
                                    </PreviewFieldComponent>
                                    <PreviewFieldComponent label="Date et heure de la réponse">
                                        {formatDate(hotline.answered_at)}
                                    </PreviewFieldComponent>
                                </div>
                            </>}
                        </section>
                    </section>

                    <section className="row">
                        <div className="col-md-3">
                            <Link id="back-hotlines" to={prefixLinkTo() + "/hotlines"} className="btn btn-bleu-4">Retour à la liste</Link>
                        </div>
                        {props.match.params.id && canPerform("hotline:write", {account: account, hotline: hotline}) && <>{validationsButtons}</>}
                        {!props.match.params.id && canPerform("hotline:create", {account: account}) && <>{validationsButtons}</>}
                    </section>
                </form>
            </section>
        </DefaultLayout>
    )
}
