import React from 'react';
import { useAccount } from 'hooks/Account';

export default function StartRestrictionMessageComponent() {

    const [account] = useAccount();

    return <>
        {account.is_start === true && <div className="message-aboved-blurred-area">
            <div>
                <p>Cet écran et ses fonctionnalités sont accessibles dans la version Premium de Pilot Veille.</p>
                <p>Vous souhaitez bénéficier des fonctionnalités avancées de notre solution de Veille réglementaire ? </p>
                <p><a rel="noreferrer" target="_blank" href="https://france.apave.com/Contact-Products?ptitle=HVER0010%20-%20Veille%20R%C3%A9glementaire">Je souhaite avoir des renseignements ou bénéficier d’une démo</a></p>
            </div>
        </div>}
    </>;
}


