import React, { useEffect, useState, useContext } from 'react';
import BarLoader from "react-spinners/BarLoader";
import { toast } from 'react-toastify';
import axios from 'axios';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import { useAutomaticRedirect } from 'shared/hooks/AutomaticRedirect';
import { useSecurity } from 'shared/hooks/Security';
import { useAccount } from 'hooks/Account';
import { useAccess } from 'hooks/Access';
import AccountApi from 'shared/api/Account';
import AccessApi from 'shared/api/Access';
import ReferentialApi from 'shared/api/Referential';
import SecurityApi from 'shared/api/Security';
import { ReferentialContext } from 'shared/stores/Referential';
import { REFERENTIALS } from 'shared/data/ReferentialType';

export default function Loader(props) {

    const [referentialLoaded, setReferentialLoaded] = useState(false);
    const [accessLoaded, setAccessLoaded] = useState(false);
    const [, referentialDispatcher] = useContext(ReferentialContext);
    const [,, setUser] = useSecurity();
    const [,,,,, tempAccount] = useAccount();
    const [, getLastVisitPath, shouldRedirect] = useAutomaticRedirect();
    const [,, setAccount] = useAccount();
    const [,,,, setWritableWatchsites, setReadableWatchsites] = useAccess();

    useEffect(() => {
        axios
            .all([
                SecurityApi.getCurrentUser(),
                AccountApi.get(tempAccount)
            ])
            .then(axios.spread((user, account) => {
                setUser(user.data);
                setAccount(account);

                axios
                    .all([
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_TYPE),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_IMPACT),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_SOURCE),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_TRANSMITTER),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_DESCRIPTORS),
                        ReferentialApi.tree(REFERENTIALS.REFERENTIAL_CATEGORY),
                        ReferentialApi.tree(),
                        ReferentialApi.userDomainTree()
                    ])
                    .then(axios.spread((
                        [type],
                        [impact],
                        [source],
                        [transmitter],
                        [involvedActor],
                        [involvedFunction],
                        [descriptor],
                        category,
                        tree,
                        userDomainTree
                    ) => {
                        referentialDispatcher({action: "populate", data: type, type: REFERENTIALS.REFERENTIAL_TEXT_TYPE});
                        referentialDispatcher({action: "populate", data: impact, type: REFERENTIALS.REFERENTIAL_TEXT_IMPACT});
                        referentialDispatcher({action: "populate", data: source, type: REFERENTIALS.REFERENTIAL_SOURCE});
                        referentialDispatcher({action: "populate", data: transmitter, type: REFERENTIALS.REFERENTIAL_TRANSMITTER});
                        referentialDispatcher({action: "populate", data: involvedActor, type: REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR});
                        referentialDispatcher({action: "populate", data: involvedFunction, type: REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION});
                        referentialDispatcher({action: "populate", data: descriptor, type: REFERENTIALS.REFERENTIAL_DESCRIPTORS});
                        referentialDispatcher({action: "populate", data: category, type: REFERENTIALS.REFERENTIAL_CATEGORY});
                        referentialDispatcher({action: "populate", data: tree, type: 'tree'});
                        referentialDispatcher({action: "populate", data: userDomainTree, type: 'userDomainTree'});
    
                        referentialDispatcher({action: "status", data: "load"});
                        setReferentialLoaded(true);
                    }))
                    .catch(() => toast.error("Impossible de charger les données"));

                axios
                    .all([
                        AccessApi.listWritableWatchsites(),
                        AccessApi.listReadableWatchsites()
                    ])
                    .then(axios.spread(([writableWatchsites], [readableWatchsites]) => {
                        setWritableWatchsites(writableWatchsites.map(w => w.id));
                        setReadableWatchsites(readableWatchsites.map(w => w.id));
        
                        setAccessLoaded(true);
                    }))
                    .catch(() => toast.error("Impossible de charger les accès"));
            }))
            .catch(() => toast.error("Impossible de charger les accès"));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (referentialLoaded && accessLoaded) {
            if (shouldRedirect()) {
                props.history.push(getLastVisitPath());
            } else {
                props.history.push("/");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referentialLoaded, accessLoaded]);

    return (
        <section className="login">
            <form className="form">
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Chargement des données et accès</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            {(!referentialLoaded || !accessLoaded) && <>
                                <p className="text-center" id="load-data">Chargement</p>
                                <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
                            </>}
                        </div>
                    </section>
                </div>
            </form>
        </section>
    );
}