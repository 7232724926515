import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { useAccount } from 'hooks/Account';
import SheetAffectedApi from 'shared/api/SheetAffected';
import ActionApi from 'api/Action';
import Can from "shared/services/Can";
import { prefixLinkTo } from 'shared/services';
import { CONFORMITY_NEW, CONFORMITY_NOK, CONFORMITY_TOSPECIFIED } from 'data/SheetAffected';
import { ACTION_STATUS_PLANNED, ACTION_STATUS_COMPLETED, ACTION_STATUS_EVALUATED } from 'data/Action';

export default function ConformityManagement() {

    const [account] = useAccount();
    const [loading, setLoading] = useState(true);
    const [newSheets, setNewSheets] = useState(0);
    const [tospecifiedSheets, setTospecifiedSheets] = useState(0);
    const [nokSheets, setNokSheets] = useState(0);
    const [tobeProcessedSheets, setTobeProcessedSheets] = useState(0);
    const [toReview, setToReview] = useState(0);
    const [actions, setAction] = useState(0);
    const datelimit = new Date();

    datelimit.setDate(datelimit.getDate() + 30);

    function load() {
        axios
            .all([
                SheetAffectedApi
                    .count({"bool": {"must": [
                        {"match": {"conformity": CONFORMITY_NEW}},
                        {"match": {"hidden": 0}}
                    ]}})
                    .then((total) => setNewSheets(total)),
                SheetAffectedApi
                    .count({"bool": {"must": [
                        {"match": {"conformity": CONFORMITY_TOSPECIFIED}},
                        {"match": {"hidden": 0}}
                    ]}})
                    .then((total) => setTospecifiedSheets(total)),
                SheetAffectedApi
                    .count({"bool": {"must": [
                        {"match": {"conformity": CONFORMITY_NOK}},
                        {"match": {"hidden": 0}}
                    ]}})
                    .then((total) => setNokSheets(total)),
                SheetAffectedApi
                    .count({"bool": {"must": [
                        {"terms": {"conformity": [CONFORMITY_NEW, CONFORMITY_TOSPECIFIED, CONFORMITY_NOK]}},
                        {"match": {"hidden": 0}}
                    ]}})
                    .then((total) => setTobeProcessedSheets(total)),
                SheetAffectedApi
                    .count({"bool": {"must": [
                        {"range": {"review_date": {"lte": datelimit}}},
                        {"match": {"hidden": 0}}
                    ]}})
                    .then((total) => setToReview(total)),
                ActionApi
                    .count({"bool": {"must": [
                        {"range": {"deadline_date": {"lte": datelimit}}},
                        {"terms": {"status": [ACTION_STATUS_PLANNED, ACTION_STATUS_COMPLETED, ACTION_STATUS_EVALUATED]}},
                        {"match": {"hidden": 0}}
                    ]}})
                    .then((total) => setAction(total)),
            ])
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <div className="contenu padding conformitymanagement">
			<h2><i className="icon-accueil-gestion-conformite" aria-hidden="true"></i>Gestion de conformité</h2>
            <BarLoader loading={loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
            {!loading && <>
                <p className="text-center">Fiche(s) restante(s) <strong>({tobeProcessedSheets})</strong></p>
                <div className="row">
                    <div className="col-md-4 text-center">
                        <Link to={prefixLinkTo() + `/sheets-affected?conformity=${CONFORMITY_NEW}`} className="statut border big bg-new h-auto infobulle bottom" data-info="Voir dans Ma Veille">
                            <span>Nouveau</span>
                            <strong>{newSheets}</strong>
                            <i className="icon-boutons-lien-vers" aria-hidden="true"></i>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to={prefixLinkTo() + `/sheets-affected?conformity=${CONFORMITY_TOSPECIFIED}`} className="statut border big bg-alert h-auto infobulle bottom" data-info="Voir dans Ma Veille">
                            <span>A préciser</span>
                            <strong>{tospecifiedSheets}</strong>
                            <i className="icon-boutons-lien-vers" aria-hidden="true"></i>
                        </Link>
                    </div>
                    <div className="col-md-4">
                        <Link to={prefixLinkTo() + `/sheets-affected?conformity=${CONFORMITY_NOK}`} className="statut border big bg-error h-auto infobulle bottom" data-info="Voir dans Ma Veille">
                            <span>En écart</span>
                            <strong>{nokSheets}</strong>
                            <i className="icon-boutons-lien-vers" aria-hidden="true"></i>
                        </Link>
                    </div>
                </div>
                <p className="text-center">Fiche(s) à échéance</p>
                <div className="row">
                    <div className="col-md-12">
                        <Link to={prefixLinkTo() + `/sheets-affected?review_date=${datelimit.toISOString().split('T')[0]}`} className="statut border flex big bg-na h-auto infobulle bottom" data-info="Voir dans Ma Veille">
                            <span className="small">Date revue inférieure à 30 jours</span>
                            <strong>{toReview}</strong>
                            <i className="icon-boutons-lien-vers" aria-hidden="true"></i>
                        </Link>
                    </div>
                </div>
                <Can perform="account:actionplan" data={{account: account}} yes={() => <>
                    <p className="text-center">Action(s) à échéance</p>
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={prefixLinkTo() + `/actions?deadline_date=${datelimit.toISOString().split('T')[0]}`} className="statut border flex big bg-na h-auto infobulle bottom" data-info="Voir dans Ma Veille">
                                <span className="small">Date limite de réalisation inférieure à 30 jours</span>
                                <strong>{actions}</strong>
                                <i className="icon-boutons-lien-vers" aria-hidden="true"></i>
                            </Link>
                        </div>
                    </div>
                </>} />
            </>}
            <div className="row">
                <div className="col-md-12 text-center">
                    <Link to={prefixLinkTo() + "/sheets-affected"} className="btn btn-primary icon h35"><i className="icon-boutons-lien-vers" aria-hidden="true"></i>&nbsp;Ma veille</Link>
                </div>
            </div>
		</div>
    );
}
