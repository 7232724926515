import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BarLoader from "react-spinners/BarLoader";
import HotlineApi from 'shared/api/Hotline';
import { prefixLinkTo } from 'shared/services';

export default function LastHotlines() {

    const [hotlines, setHotlines] = useState([]);
    const [nbHotlines, setNbHotlines] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reducedDisplay, toggle] = useState(true);

    function load() {
        HotlineApi
            .search(
                {},
                0,
                10,
                "created_at",
                "DESC"
            )
            .then(([results,, total]) => {
                setLoading(false);
                setHotlines(results);
                setNbHotlines(total);
            })
            .catch(() => setLoading(false));
    }

    useEffect(load, []);

    return (
        <div className="contenu padding lasthotlines">
			<h2><i className="icon-accueil-dernieres-questions-hotline"></i>Dernières questions hotline</h2>
            <BarLoader loading={loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
			{!loading && <ul className="item-list latest striped">
                {hotlines.map((hotline, index) => {
                    if (index <= 1 || reducedDisplay === false) {
                        return <li key={hotline.id}>
                            <div className="picto color-vert-apave">
                                <i className="icon-accueil-consultants-compte" aria-hidden="true"></i>
                            </div>
                            <div className="infos">
                                <Link to={prefixLinkTo() + `/hotlines/${hotline.id}`} title="Voir la question">
                                    <strong>{hotline.id} : </strong>
                                    {hotline.title}
                                </Link>
                            </div>
                            <div className="detail">
                                <Link to={prefixLinkTo() + `/hotlines/${hotline.id}`} title="Voir la question"><i className="icon-boutons-lien-vers" aria-hidden="true"></i></Link>
                            </div>
                        </li>;
                    } else {
                        return <React.Fragment key={hotline.id}></React.Fragment>;
                    }                    
                })}
			</ul>}
			<div className="row">
				<div className="offset-md-3 col-md-6 text-center">
					<Link to={prefixLinkTo() + "/hotlines"} className="btn btn-primary icon h35"><i className="icon-boutons-lien-vers" aria-hidden="true"></i>  Voir toutes mes questions ({nbHotlines})</Link>
				</div>
				{!loading && hotlines.length > 2 && <div className="col-md-3 text-right">
                    {reducedDisplay && <button onClick={() => toggle(false)} className="toggler actif after" type="button">Voir plus</button>}
                    {!reducedDisplay && <button onClick={() => toggle(true)} className="toggler after" type="button">Voir moins</button>}
				</div>}
			</div>
		</div>
    );
}
