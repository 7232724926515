import { React, useState, useEffect, _ } from "commons";
import { ReferentialApi } from 'shared/api';
import DomainSelectionItemComponent from "shared/components/domainSelection/DomainSelectionItemComponent";

export default function DomainSelectionComponent(props) {

    const [selection, setSelection] = useState([])
    const [tree, setTree] = useState([]);
    const [unfold, toggle] = useState(false);
    const id = props.name;

    function loadTree() {
        const newSelection = [];
        const mapper = (item, parent = null, parentSelected = false) => {
            if (parent) {
                item.ancestors = [parseInt(parent.value)];
                if (parent.ancestors && parent.ancestors.length > 0) {
                    item.ancestors = [...item.ancestors, ...parent.ancestors];
                }
            }

            if (parentSelected || (Array.isArray(props.value) && props.value.includes(parseInt(item.value)))) {
                newSelection.push(parseInt(item.value));
            }

            if (item.children && item.children.length > 0) {
                item.children.map(subitem => mapper(subitem, item, newSelection.includes(parseInt(item.value))));
            }

            return item;
        };

        ReferentialApi.accountsDomainTree(props.accounts).then(t => {
            setTree(t.map(subitem => mapper(subitem)))
            setSelection(newSelection);
        });
    }

    function onChange(item, checked) {
        let newSelection = [...selection];
        if (checked) {
            if (item.children && item.children.length > 0) {
                item.children.forEach(subitem => {
                    newSelection.push(parseInt(subitem.value));
                    if (subitem.children && subitem.children.length > 0) {
                        subitem.children.forEach(subsubitem=> {
                            newSelection.push(parseInt(subsubitem.value));
                        });
                    }                                        
                });
            }

            newSelection.push(parseInt(item.value));
        } else {
            if (item.ancestors && item.ancestors.length > 0) {
                newSelection = newSelection.filter(id => !item.ancestors.includes(parseInt(id)));
            }

            if (item.children && item.children.length > 0) {
                item.children.forEach(subitem => {
                    newSelection = newSelection.filter(id => id !== parseInt(subitem.value));
                    subitem.children.forEach(subsubitem => {
                        newSelection = newSelection.filter(id => id !== parseInt(subsubitem.value));                            
                    });
                });
            }

            newSelection = newSelection.filter(id => id !== parseInt(item.value));
        }

        setSelection(_.uniq(newSelection));
        notifyParent(newSelection);
    }

    function toggleTree(event) {
        event.preventDefault();

        toggle(!unfold);
    }

    function selectAll(event) {
        event.preventDefault();
        let newSelection = [];

        tree.forEach(item => {
            if (item.children && item.children.length > 0) {
                item.children.forEach(subitem => {
                    newSelection.push(parseInt(subitem.value));
                    if (subitem.children && subitem.children.length > 0) {
                        subitem.children.forEach(subsubitem=> {
                            newSelection.push(parseInt(subsubitem.value));
                        });
                    }                                        
                });
            }
            newSelection.push(parseInt(item.value));
        });

        setSelection(_.uniq(newSelection));
        notifyParent(newSelection);
    }

    function unselectAll(event) {
        event.preventDefault();

        setSelection([]);
        notifyParent([]);
    }

    function notifyParent(newSelection) {
        if (props.onChange) {
            const realValues = [];
            const mapper = (referential) => {
                if (newSelection.includes(parseInt(referential.value))) {
                    realValues.push(parseInt(referential.value));
                } else if (referential.children && referential.children.length > 0) {
                    referential.children.map(mapper);
                }
            };

            tree.map(mapper);

            props.onChange(realValues);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadTree, [props.accounts]);

    return <div className="flex-label">
        <label id={`${id}-label`} className="at-top">{props.label} : </label>
        <div>
            <a href="/" onClick={toggleTree}>Déplier / replier</a>&nbsp;-&nbsp;
            <a href="/" onClick={selectAll}>Tout sélectionner</a>&nbsp;/&nbsp;
            <a href="/" onClick={unselectAll}>Tout désélectionner</a>
            <ul className="arborescence domaintree">
            {tree.map(domain => <DomainSelectionItemComponent
                item={domain}
                fieldname={props.name}
                onChange={onChange}
                selection={selection}
                treeUnfold={unfold}
            />)}
            </ul>
        </div>
    </div>
}