import { React, Modal, Link, toast, Prompt, _, useEffect, useState } from 'commons';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import AutoaffectationForm from 'partials/AutoaffectationForm';
import { useAccount } from 'hooks/Account';
import CommentUtils from 'services/CommentUtils';
import WatchsiteAffectedApi from 'api/WatchsiteAffected';
import WatchsiteAffectedListItem from 'views/sheetaffected/watchsite/ListItem';
import WatchsiteAffectedHeader from 'views/sheetaffected/watchsite/Header';
import WatchsiteAffectedListBatch from 'views/sheetaffected/watchsite/ListBatch';
import { getConformities, getConformityModes } from 'data/SheetAffected';
import { getPriorities } from 'data/Priority';
import { SheetCommonDetail } from 'shared/partials';
import { useList, useToggler } from 'shared/hooks';
import { TreeUtils, smallModalCustomStyles, canPerform, prefixLinkTo } from 'shared/services';
import confirm from 'shared/services/Confirmation';
import { SheetApi, SheetAffectedApi, AccountApi } from 'shared/api';
import { ToggableBlocComponent, PageLoadingComponent, FilterComponent, HtmlComponent, ListComponent, LoadButton } from 'shared/components';
import StartRestrictionMessageComponent from 'components/StartRestrictionMessageComponent';

export default function WatchsiteList(props) {

    const location = useLocation();
    const [checkScroll, setCheckScroll] = useState(false);
    const [autoaffecting, setAutoaffecting] = useState(false);
    const [account] = useAccount();
    const [sheet, setSheet] = useState(null);
    const [headquarterComment, setHeadquarterComment] = useState(null);
    const [selection, setSelection] = useState([]);
    const [loading, setLoading] = useState(true);
    const [affectationChanges, setAffectationChanges] = useState({});
    const [hasChanges, setHasChanges] = useState(false);
    const [commentActions, setCommentActions] = useState({});
    const [saving, setSaving] = useState(false);
    const [tree, setTree] = useState([]);
    const [accountTree, setAccountTree] = useState({'nodes': [], 'watchsites': []});

    // account access
    const hasTechnicalLevelAccess = canPerform("account:technical_level", {account: account});
    const hasCustomerCommentAccess = canPerform("account:customer_comment", {account: account});
    const hasActionPlanAccess = canPerform("account:actionplan", {account: account});
    const hasComplianceAccess = canPerform("account:compliance_management", {account: account});

    const [, toggle,,,,,getToggler] = useToggler({
        'watchsite-affected-search': true,
        'comment_headquarters': true
    });

    const filterType = {
        sheet: {type: "match", fields: ["sheet"]},
        keyword: {type: "match", fields: ["keyword"]},

        has_sheet_affected: {type: "match", fields: ["has_sheet_affected"], cast: "int"},
        sheet_affected_conformity_mode: {type: "match", fields: ["sheet_affected.conformity_mode"]},
        sheet_affected_conformity: {type: "terms", fields: ["sheet_affected.equivalent_conformity"]},
        sheet_affected_priority: {type: "match", fields: ["sheet_affected.equivalent_priority"]},
        sheet_affected_tree: {type: "terms", fields: ["sheet_affected.tree"]},
        sheet_affected_review_date: {type: "range", fields: ["sheet_affected.equivalent_review_date"]},
        sheet_affected_evaluation_date: {type: "range", fields: ["sheet_affected.equivalent_evaluation_date"]},
        sheet_affected_hidden: {type: "match", fields: ["sheet_affected.hidden"], cast: "int"},
    };
    
    const columns = [
        {
            id: 'watchsite_label',
            title: 'Nom',
            field: ["watchsite.label"],
        },
        {
            id: 'sheet_affected_conformity_mode',
            title: 'Mode de gestion',
        },
        {
            id: 'sheet_affected_conformity',
            title: 'Statut de la conformité',
        },
        {
            id: 'sheet_affected_date',
            title: 'Date',
        },
        {
            id: 'sheet_affected_priority',
            title: 'Priorité',
        },
        {
            id: 'sheet_affected_hidden',
            title: 'Fiche masquée',
        },        
    ];

    if (hasActionPlanAccess) {
        columns.push(
            {
                id: 'sheet_affected_nb_actions',
                title: 'Nombre d\'actions',
            }
        );
    }


    const sheetId = props.match.params.id;

    const [
        rows,
        totalrows,
        criterias,,
        direction,
        limit,
        page,,,
        addCriteria,,
        updateLimit,
        updatePage,
        submitSearch,
        listLoading,,
        refresh,,
    ] = useList("watchsite-affected-list", WatchsiteAffectedApi, null, "id", {}, "asc", 20, null, {
        sheet: sheetId,
    });
    
    useEffect(() => {
        setTree(TreeUtils.accountTreeFilterMapper(accountTree, true, criterias.sheet_affected_tree))
    }, [criterias.sheet_affected_tree, accountTree]);

    useEffect(() => {
        submitSearch(filterType);

        let requests = [];
        requests.push(
            AccountApi.getUserTree(true).then(t => setAccountTree(t))
        );
        requests.push(
            SheetApi.get(sheetId).then(newSheet => setSheet(newSheet))
        );
        requests.push(
            SheetAffectedApi
                .search({bool: {must: [{"match": {"sheet.id": sheetId}}]}}, 0, 1, "sheet.id", "ASC")
                .then(([results, total]) => {
                    if (total === 1 && !_.isNil(results[0]["data"][0]["comment_headquarters"])) {
                        setHeadquarterComment(results[0]["data"][0]["comment_headquarters"]);
                    }
                })
        );
        axios.all(requests)
            .then(() => { setLoading(false); setCheckScroll(true) })
            .catch(() => setLoading(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setHasChanges(false);
    }, [rows])

    function onAffectationChange(data)  {
        let newAffectationChanges = {...affectationChanges};
        newAffectationChanges[data.id] = data;
        setAffectationChanges(newAffectationChanges);
        setHasChanges(true);
    }

    function onCommentActions(sheetAffectedId, actions, comments) {
        let newCommentActions = {...commentActions};
        newCommentActions[sheetAffectedId] = [sheetAffectedId, actions, comments];
        setCommentActions(newCommentActions);
        setHasChanges(true);
    }

    function changesSuccess(message = null) {
        setAffectationChanges({});
        setHasChanges(false);
        setSaving(false);
        setCommentActions({});
        refresh();
        if (message) {
            toast.success(message);
        }
    }

    function saveAffectationChanges() {
        setSaving(true);
        CommentUtils
            .doMultipleActions(SheetAffectedApi, commentActions)
            .then(() => {
                if (_.size(affectationChanges) > 0) {
                    const affectations = Object.values(affectationChanges);
                    SheetAffectedApi
                        .inlineUpdate(affectations)
                        .then(changesSuccess)
                        .catch(error => {
                            setSaving(false);
                            const data = error.response.data;
        
                            if (data.message) {
                                toast.error(data.message);
                            }
                        });
                } else {
                    changesSuccess('Commentaires sauvegardés.');
                }
            })
            .catch((error) => console.error(error));
    }

    const exitChangesMessage = "Vous avez des modifications non enregistrées, "; 

    function resetFilters(event) {
        event.preventDefault();
        if (!hasChanges) {
            refresh(true);
        } else {
            confirm(
                exitChangesMessage + "êtes-vous sur de vouloir réinitialiser la recherche ?",
                () => refresh(true)
            );
        }
    }

    function sendSearch(event) {
        event.preventDefault();
        if (hasChanges) {
            confirm(
                exitChangesMessage + "êtes-vous sur de vouloir de rafraichir la liste ?",
                () => submitSearch(filterType)
            );
        } else {
            submitSearch(filterType);
        }
    }

    function getSelection(sheetsaffectedSelected) {
        let fullSelection = [];
        sheetsaffectedSelected.forEach((s) => {
            let row = _.find(rows, (r) => r.sheet_affected && r.sheet_affected.id === s);
            if (row) {
                fullSelection.push({
                    watchsite: row.watchsite.id,
                    sheetaffected: row.sheet_affected.id
                })
            }
        });
        return fullSelection;
    }

    if (loading === true) {
        return <PageLoadingComponent label="Traitement de fiche en multi points de veille" />
    }

    const sidebar = [];
    if (canPerform("account:headquarter_comment", {account: account}) && headquarterComment) {
        sidebar.push({anchor: "#comment_headquarters", label: "Commentaire", active: true});
    }
    sidebar.push({anchor: "#watchsite-affected-search", label: "Conformité", active: true});

    if (checkScroll && location.hash === "#watchsite-affected-search" && document.querySelector("#watchsite-affected-search")) {
        document.querySelector("#main-content").scrollTo(
            0,
            document.querySelector("#watchsite-affected-search").offsetTop
        );
        setCheckScroll(false);
    }

    return (
        <SheetCommonDetail 
            title="Traitement de fiche en multi points de veille"
            displayTechnicalLevel={hasTechnicalLevelAccess}
            sheet={sheet}
            sidebar={sidebar}
            screen="E34"
        >
            <Prompt
                when={hasChanges}
                message={exitChangesMessage + "voulez-vous vraiment quitter ?"}
            />
            {canPerform("account:headquarter_comment", {account: account}) && headquarterComment &&
                <ToggableBlocComponent
                    label="Commentaire siège"
                    id="comment_headquarters"
                    toggled={getToggler("comment_headquarters")}
                    toggle={toggle}
                    className="toggle-group border border-gris-40"
                >
                    <div className="bg-blanc">
                        <HtmlComponent>{headquarterComment}</HtmlComponent>
                    </div>
                </ToggableBlocComponent>
            }
            {hasComplianceAccess && <section>
                <div className="bloc">
                    <form className="form" onSubmit={sendSearch}>
                        <ToggableBlocComponent
                            label="Conformité"
                            id="watchsite-affected-search"
                            toggled={getToggler("watchsite-affected-search")}
                            toggle={toggle}
                            className="filters toggle-group"
                            margin={0}
                        >
                            <div className="bg-gris-25">
                                <div className="row">
                                    <FilterComponent 
                                        type="text"
                                        name="keyword"
                                        label="Recherche libre" 
                                        onChange={value => addCriteria("keyword", value)}
                                        value={criterias.keyword || ""} 
                                    />
                                    <FilterComponent type="select" name="sheet_affected_conformity_mode" onChange={value => addCriteria("sheet_affected_conformity_mode", value)} value={criterias.sheet_affected_conformity_mode} label="Mode gestion" options={getConformityModes()} clearable />
                                    <FilterComponent type="buttons" name="sheet_affected_conformity" onChange={value => addCriteria("sheet_affected_conformity", value)} value={criterias.sheet_affected_conformity} label="Conformité de la fiche" clearable options={getConformities()} multiple />
                                    <FilterComponent type="select" name="sheet_affected_priority" onChange={value => addCriteria("sheet_affected_priority", value)} value={criterias.sheet_affected_priority} label="Priorité de la fiche" clearable options={getPriorities()} />
                                    <FilterComponent type="dropdown-tree-select" name="sheet_affected_tree" onChange={value => addCriteria("sheet_affected_tree", value)} useMapper={false} value={criterias.sheet_affected_tree} label="Noeud/Point de veille" data={tree} mode="hierarchical" />
                                    <FilterComponent type="daterange" name="sheet_affected_evaluation_date" onChange={value => addCriteria("sheet_affected_evaluation_date", value)} value={criterias.sheet_affected_evaluation_date} label="Date d'évaluation" />
                                    <FilterComponent type="daterange" name="sheet_affected_review_date" onChange={value => addCriteria("sheet_affected_review_date", value)} value={criterias.sheet_affected_review_date} label="Date de revue" />
                                    <FilterComponent type="radio" name="sheet_affected_hidden" onChange={value => addCriteria("sheet_affected_hidden", value)} value={criterias.sheet_affected_hidden} label="Masquée" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                    <FilterComponent type="radio" name="has_sheet_affected" onChange={value => addCriteria("has_sheet_affected", value)} value={criterias.has_sheet_affected} label="Affecté" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                                </div>
                            </div>

                            <div className="bg-gris-25">
                                <div className="row">
                                    <div className="col-md-9">
                                        <button id="clearfilter-requirement-affected" onClick={(e) => resetFilters(e)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button id="search-requirement-affected" type="submit" className="btn btn-primary">Rechercher</button>
                                    </div>
                                </div>
                            </div>
                        </ToggableBlocComponent>

                        <ListComponent 
                            id="watchsite-affected"
                            className="table table-bordered"
                            selectable
                            rows={rows}
                            loading={listLoading}
                            columns={columns}
                            selection={selection}
                            onSelect={(s) => setSelection(s)}
                            renderHeader={(cols) =>
                                <WatchsiteAffectedHeader
                                    columns={cols}
                                />
                            }
                            selectField="sheet_affected.id"
                            renderRow={(row, cols, index, displayRow, drawSelectable) => 
                                <WatchsiteAffectedListItem
                                    item={row}
                                    columns={cols}
                                    columnAmount={_.size(_.filter(cols, (col) => col.is_active === true))}
                                    drawSelectable={drawSelectable}
                                    onCommentActions={onCommentActions}
                                    onAffectationChange={onAffectationChange}
                                    commentActions={commentActions}
                                    hasCustomerCommentAccess={hasCustomerCommentAccess}
                                />
                            }
                            batchActions={
                                <WatchsiteAffectedListBatch
                                    selection={getSelection(selection)}
                                    history={props.history}
                                    refresh={() => {
                                        setSelection([]);
                                        refresh();
                                    }}
                                    sheet={sheet}
                                />
                            }     
                            otherActions={
                                <>
                                    <button type="button"
                                        id="sheet-affected-auto-affection"
                                        className="h25 btn btn-primary icon"
                                        name="auto-affectation"
                                        onClick={() => setAutoaffecting(true)}
                                        title="Affecter la fiche à d'autres points de veille."
                                    >
                                        <i className="icon-boutons-plus" aria-hidden="true"></i> Affecter la fiche
                                    </button>
                                    &nbsp;
                                </>
                            }                   
                            batchActionsClassName="col-md-6"
                            otherActionsClassName="col-md-6 text-right"
                            direction={direction}
                            perpage={limit}
                            onPerpageChange={updateLimit}
                            page={page}
                            onPageChange={updatePage}
                            totalrows={totalrows}
                            selectionField="sheet_affected_id"
                        />
                    </form>

                    <section className="row">
                        <div className="col-md-12 text-right">
                            {sheet.cut_into_requirements && 
                                <Link id="sheet-affected-requirements" className="btn btn-primary" to={prefixLinkTo() + `/requirements-affected?sheet=${sheet.id}`}>
                                    <i className="icon-boutons-lien-vers" aria-hidden="true"></i>&nbsp;Exigences
                                </Link>
                            }
                            <LoadButton
                                loading={saving} 
                                label="Enregistrer les modifications"
                                name="save"
                                id="save"
                                disabled={!hasChanges}
                                onClick={saveAffectationChanges}
                            />
                        </div>
                    </section>

                    {account.is_start === false &&
                        <section>
                            <Modal isOpen={autoaffecting} onRequestClose={() => setAutoaffecting(false)} style={smallModalCustomStyles}>
                                <AutoaffectationForm
                                    sheet={sheet}
                                    userTree={accountTree}
                                    onRequestClose={() => setAutoaffecting(false)}
                                />
                            </Modal>
                        </section>
                    }
                </div>
                <StartRestrictionMessageComponent />
            </section>}
        </SheetCommonDetail>
    );
}