import FieldComponent from 'shared/components/FieldComponent';
import RequirementAffectedApi from 'api/RequirementAffected';
import Modal from 'react-modal';
import { smallModalCustomStyles } from 'shared/services/ModalStyle';
import { useState } from 'react';
import { toast } from 'react-toastify';
import LoadButton from 'shared/components/LoadButton';

export default function ImportModal(props) {

    const { show, onClose } = props;
    const [errors, setErrors] = useState([]);
    const [importing, setImporting] = useState(false);
    const [file, setFile] = useState(null);

    function onImport() {
        setImporting(true);
        setErrors([]);
        RequirementAffectedApi
            .import(file)
            .then(response => {
                setImporting(false);
                setFile(null);
                toast.success(response.message);
                onClose(response.count > 0);
            })
            .catch(error => {
                setImporting(false);
                setFile(null);
                toast.error(error.response.data.message);
                setErrors(error.response.data.errors);
            });
    }

    return (
        <Modal isOpen={show} onRequestClose={() => onClose(false)} style={smallModalCustomStyles}>
            <section>
                <div className="alert alert-warning" role="alert">
                    Le fichier doit avoir le même format et la même forme que le fichier de sortie d'un export.
                </div>
                <hr />
                <FieldComponent
                    type="file"
                    label="Fichier à importer"
                    name="requirements"
                    accept=".xlsx"
                    onChange={value => setFile(value)}
                />
                <hr />
                {errors.length > 0 && <>
                    <div className="color-rouge">
                        <strong>Des erreurs sont survenus durant l'import :</strong>
                        <br />
                            {errors.join(', ')}
                        <hr />
                    </div>
                </>}
                <button type="button" className="btn btn-bleu-4" id="cancel-import-requirement-affected" onClick={onClose}>Annuler</button>
                <LoadButton
                    loading={importing} 
                    label="Importer"
                    id="import-requirement-affected"
                    onClick={onImport}
                    disabled={file === null}
                />
            </section>
        </Modal>
    );
}