import { compileErrorsFromResponse } from "shared/services/Utils";
import { useState } from 'react';
import { toast } from 'react-toastify';
import CommentUtils from "services/CommentUtils";

export function useComments(commentApi) {
    const [actions, setActions] = useState([]);
    const [errors, setErrors] = useState({});

    function doActions(objectId, comments) {
        return CommentUtils.doActions(commentApi, actions, objectId, comments)
            .then(response => {
                setActions([]);
                return response;
            });
    }

    function resetErrors() {
        setErrors({});
    }

    function onError(error) {
        if (error.error) {
            let newErrors = {};
            newErrors[error.id ? error.id : -1] = compileErrorsFromResponse(error.error.response);
            setErrors(newErrors);
            toast.error("Des erreurs sont survenues");
        }
    }

    return [actions, errors, onError, resetErrors, doActions, setActions];
}