import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import BarLoader from "react-spinners/BarLoader";

import { useAccount } from 'hooks/Account';
import { useSecurity } from 'shared/hooks/Security';
import { useAutomaticRedirect } from 'shared/hooks/AutomaticRedirect';
import SecurityApi from 'shared/api/Security';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";

export default function Logout() {
    
    const [processing, setProcessing] = useState(true);
    const [,,, removeLastVisit] = useAutomaticRedirect();
    const [,,,, unsetAccount] = useAccount();
    const [user,, setUser] = useSecurity();

    useEffect(() => {
        if (user.id) {
            SecurityApi
                .logout()
                .then(() => {
                    if (!_.isEmpty(user)) {
                        setUser({});
                    }
                    removeLastVisit();
                    unsetAccount();                    
                })
                .finally(() => setProcessing(false));
        } else {
            setProcessing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (processing) {
        return <div><BarLoader width={100} color="#5bad27" css="display:block;margin:10px auto;" /></div>;
    } else {
        return (
            <section className="login">
                <form className="form">
                    <h1>
                        <div className="main-logo">
                            <img src={logoapave} width="96" alt="Logo Apave" />
                        </div>
                        <div className="main-name">
                            <img src={logopilotveille} alt="Logo Pilot Veille" />
                        </div>
                    </h1>
                    <div className="bloc">
                        <section>
                            <header>
                                <h2>Déconnexion</h2>
                            </header>
                            <div className="bg-blanc border-rlb border-gris-40">
                                <div className="row">
                                    <div className="col-md-12 text-center order-1">
                                        <p>Vous avez correctement été déconnecté.</p>
                                        <a id="relogin" href="/login" className="btn btn-primary">Se re-connecter</a>                                  
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </form>
            </section>    
        )
    }
}