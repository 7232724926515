import React, { useState } from 'react';
import { toast } from 'react-toastify';
import SheetAffectedListItemSheet from 'views/sheetaffected/list/ListItemSheet';
import SheetAffectedListItemWatchsite from 'views/sheetaffected/list/ListItemWatchsite';

export default function SheetAffectedListItem(props) {
    const { hasPrivateSheetWriteAccess, hasActionAccess, hasCustomerCommentAccess, hasComplianceManagementAccess, row, accountTree, columns, openAutoaffectation, index } = props;

    try {
        if (typeof row.data == "string") {
            row.data = JSON.parse(row.data.replaceAll('\\\\n', '').replaceAll('\\n', ''));
        }
    } catch (e) {
        row.data = null;
    }

    const [isHidden, toggle] = useState(row.data === null || row.data.length > 1);
    const className = index % 2 === 0 ? "" : "bg-gris-25";
    const activeAutoaffectation = (accountTree.nodes.length + accountTree.watchsites.length) > 1;

    if (row.data === null) {
        toast.error("Problème de récupération des données pour la fiche " + row.sheet.id);
        return <></>;
    }

    return (
        <>
            <SheetAffectedListItemSheet 
                selection={props.selection}
                updateSelection={props.updateSelection}
                row={row}
                columns={columns}
                className={className}
                openAutoaffectation={openAutoaffectation}
                activeAutoaffectation={activeAutoaffectation}
                hasComplianceManagementAccess={hasComplianceManagementAccess}
            />
            <tr id={`row-${row.sheet.id}`} className={className}>
                {row.data.length > 1 && <td className="w-34px bg-vert-apave valign-top">
                    <button id={`toggle-selection-${row.sheet.id}`} className={isHidden ? "toggler color-blanc" : "toggler color-blanc actif"} type="button" onClick={() => toggle(!isHidden)}>
                        &nbsp;{row.data.length}
                    </button>
                </td>}
                <td className="no-padding">
                    <div className="cell-padding">
                        {row.sheet.main_domain && <>Domaine principal : <strong>{row.sheet.main_domain.value}</strong></>}
                        {row.sheet.main_subdomain && <> Sous domaine principal : <strong>{row.sheet.main_subdomain.value}</strong></>}
                        {row.sheet.is_private && <i className="icon-autres-fiche-privative infobulle color-vert-apave" aria-hidden="true" data-info="Fiche privative"></i>}
                        {row.sheet.major_text && <i className="icon-autres-texte-majeur infobulle color-vert-apave" aria-hidden="true" data-info="Texte majeur"></i>}
                    </div>
                    {(!isHidden || row.data.length === 1) && row.data.map(item => <SheetAffectedListItemWatchsite 
                        key={item.id}
                        sheet={row.sheet}
                        item={item}
                        columns={columns}
                        accountTree={accountTree}
                        selection={props.selection}
                        updateSelection={props.updateSelection}
                        displaySelection={row.data.length > 1}
                        hasComplianceManagementAccess={hasComplianceManagementAccess}
                        hasCustomerCommentAccess={hasCustomerCommentAccess}
                        hasActionAccess={hasActionAccess}
                        hasPrivateSheetWriteAccess={hasPrivateSheetWriteAccess}
                    />)}
                </td>
            </tr>
        </>
    );
}